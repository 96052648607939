export class CoverageCone {
    static zoomFactor: any = 1;

    static polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number) {
        const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

        return {
            x: centerX + (radius * Math.cos(angleInRadians)),
            y: centerY + (radius * Math.sin(angleInRadians))
        };
    }

    static describeArc(x: any, y: any, radius: any, startAngle: number, endAngle: number) {
        const start = this.polarToCartesian(x, y, radius, endAngle);
        const end = this.polarToCartesian(x, y, radius, startAngle);
        const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
        const d = [
            "M", start.x, start.y,
            "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
        ].join(" ");
        return d;
    }

    static checkBrowserIsFireFox(): boolean {
        const firefox = !!navigator.userAgent.match(/Firefox\//);

        if (firefox) {
            return true;
        } else {
            return false;
        }
    }


    static getTheta(range: any, arcx: number, arcy: number, x: any, y: any, radius: any, startAngle: any, endAngle: any) {
        const start: any = this.polarToCartesian(x, y, radius, endAngle);
        const end: any = this.polarToCartesian(x, y, radius, startAngle);
        const p1x = parseFloat(start.x);
        const p1y = parseFloat(start.y);
        const p2x = parseFloat(end.x);
        const p2y = parseFloat(end.y);


        // angle of perpendicular to line:
        const theta = Math.atan2(p2y - p1y, p2x - p1x) - Math.PI / 2;



        // location of control point:
        const c1x = arcx + Math.cos(theta);
        const c1y = arcy + Math.sin(theta);
        return { x: c1x, y: c1y }
    }

    static getRangeSlider(value: number) {
        let val;
        if (value)
            if (value === 1) {
                val = 4
            } else if (value === 2) {
                val = 6
            } else if (value === 3) {
                val = 9
            } else if (value === 4) {
                val = 13
            } else if (value === 5) {
                val = 20
            } else if (value === 6) {
                val = 30
            } else if (value === 7) {
                val = 44
            } else if (value === 8) {
                val = 67
            } else if (value === 9) {
                val = 100
            } else {
                val = 20
                // // let logValue = Math.max(value, 4) * 38 / 100;
                // return Math.log(1.5) / Math.log(value);
            }
            return val;
    }
    static getArc(x: any, y: any, radius: any, startAngle: any, endAngle: any) {
        const start = this.polarToCartesian(x, y, radius, endAngle);
        const end = this.polarToCartesian(x, y, radius, startAngle);


        return {
            x1: start.x,
            y1: start.y,
            x2: end.x,
            y2: end.y,
            midx: (start.x + end.x) / 2,
            midy: (start.y + end.y) / 2,
            start: start,
            end: end,
        };
    }

}