export const DeleteHub = {
    dialogId: 'Delete Device',
    header: 'Delete Device',
    content: 'Are you sure you want to delete <strong class="text-break">{{#name}}</strong>? There are currently <strong class="text-break">{{#deleteNewDeviceNetworkLinkCnt}}</strong> {{#deviceText}} associated with <strong class="text-break">{{#name}}</strong>. <strong>{{#secondaryCount}}</strong> secondary Devices will also be deleted. Are you sure you want to delete all of these Devices and remove all Hub Connections/Pathways?',
    btnSuccessText: 'Delete Device',
    btnCancelText: 'Cancel',
    btnSuccessClass: 'btn-danger',
    btnCancelClass: 'btn-secondary',
    btnSuccessStyle: '',
    bodyClass: '',
    bodyStyle: '',
    dialogClass: '',
    dialogStyle: '',
}