<div class="modal-header">
    <div class="row">
        <div class="col-sm-12">
            <h1>Configure Favorites</h1>
        </div>
        <div class="col-sm-12">
            <p class="mb-0">Quickly create Favorites by importing new Favorites or updating existing Favorites. For help
                building an import file, download our <a
                   *ngIf="fromProject" href="{{cdnUrl}}assets/images/site-owl-favorite-import-template-project.csv" download>
                    sample template.</a><a
                    *ngIf="!fromProject" href="{{cdnUrl}}assets/images/site-owl-favorite-import-template.csv" download>
                     sample template.</a></p>
        </div>
    </div>
    <button data-dismiss="modal" (click)="closeModal()" aria-label="Close" class="close" type="button">
        <em class="fas fa-close"></em>
    </button>
</div>
<div class="modal-body fav-configure-body">
    <div class="d-flex mx-auto col-sm-10 p-0 justify-content-center align-items-center fav-configure-col">
        <div [ngClass]="{'col-4': isShowDeleteFav, 'col-6':!isShowDeleteFav}" *ngIf="projFavCount > 0 && fromProject">
            <div class="card image-card">
                <em class="icon fal fa-download"></em>
                <h2 class="mb-4">Export Existing Favorites</h2>
                <p>Modify the exported CSV and reupload to perform a bulk update to the Favorites for this System Owner.
                </p>
                <div class="text-center">
                    <a href="javascript:void(0);" class="btn btn-primary mrg-b-15 me-0  mb-0"
                        (click)="exportFavorite()"> <em class="fas fa-download"></em>Export
                    </a>
                </div>
            </div>
        </div>
        <div [ngClass]="{'col-4': isShowDeleteFav, 'col-6':!isShowDeleteFav}" *ngIf="!fromProject && siteFavCount > 0">
            <div class="card image-card">
                <em class="icon fal fa-download"></em>
                <h2 class="mb-4">Export Existing Favorites</h2>
                <p>Modify the exported CSV and reupload to perform a bulk update to the Favorites for this System Owner.
                </p>
                <div class="text-center">
                    <a href="javascript:void(0);" class="btn btn-primary mrg-b-15 me-0  mb-0"
                        (click)="exportFavorite()"> <em class="fas fa-download"></em>Export
                    </a>
                </div>
            </div>
        </div>
        <div [ngClass]="{'col-4': isShowDeleteFav, 'col-6':!isShowDeleteFav}">
            <div class="card image-card">
                <em class="icon fal fa-upload"></em>
                <h2 class="mb-4">Import Favorites</h2>
                <p>Already have a CSV for Favorites? Import the file below to update existing Favorites or create new
                    ones.
                </p>
                <div class="text-center">
                    <a href="javascript:void(0);" class="btn btn-primary mrg-b-15 me-0 mb-0" (click)="importFavorite()">
                        <em class="fas fa-upload"></em>Import
                    </a>
                    <input type="file" class="fileUpload" #fileUpload style="display:none" (change)="onchange($event)"
                        accept=".csv" />
                </div>
            </div>
        </div>

        <div class="col-4" *ngIf="isShowDeleteFav">
            <div class="card image-card">
                <em class="icon fal fa-trash-can"></em>
                <h2 class="mb-4">Delete All Favorites</h2>
                <p>Deletes all Favorites in the account. Choosing this option exports a file of the existing Favorites
                    for backup.
                </p>
                <div class="text-center">
                    <a href="javascript:void(0);" class="btn btn-danger mrg-b-15 me-0  mb-0"
                        (click)="deleteFavorites()"> <em class="fas fa-trash-can"></em>Delete
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()"
        aria-label="Close">Cancel</button>
</div>
<div *ngIf="showSpinner">
    <so-loader></so-loader>
</div>