/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'so-print-out-popup',
  templateUrl: './print-out-popup.component.html',
  styleUrls: ['./print-out-popup.component.scss'],
})
export class PrintOutPopupComponent {
  public event: EventEmitter<any> = new EventEmitter();
  public cancel: EventEmitter<any> = new EventEmitter();

  constructor(public bsModalRef: BsModalRef) { }
  _action(event: any) {
    this.event.emit(event);
    this.bsModalRef.hide();
  }
}
