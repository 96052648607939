<div class="modal-header" (click)="$event.stopPropagation()">
    <h1 class="modal-title pull-left">
        Link to Ticket</h1>
    <button data-dismiss="modal" (click)="closeModal()" aria-label="Close" class="close pull-right" type="button">

        <i class="fas fa-close"></i>
    </button>
</div>
<div class="modal-body" (click)="$event.stopPropagation()">
    <h2>Link an Existing Ticket</h2>
    <div class="checkbox-label">
        <so-checkbox [isChecked]="showClosedTicked" (change)="toggleTickets()" [id]="'toggle'"
            [label]="'Hide Closed Tickets'"></so-checkbox>
    </div>
    <div class="table-responsive custom-table link-table fixed-header"
        *ngIf="(opentickets?.length> 0 || !showClosedTicked)">
        <table class="table table-fixed">
            <thead>
                <tr class="clearfix">
                    <th class="col-sm-1">#</th>
                    <th class="col-sm-5">Subject</th>
                    <th class="col-sm-2">Status</th>
                    <th class="col-sm-2"># Devices</th>
                    <th class="col-sm-2">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr class="clearfix" *ngFor="let ticket of unLinkedTickets |
                    ticketStatus:{showClosedTicked:showClosedTicked,equipmentDetails:equipmentDetails} ;let i= index">
                    <td class="col-sm-1">{{ticket?.id}}</td>
                    <td class="col-sm-5">{{ticket?.subject}}</td>
                    <td class="col-sm-2" [ngClass]="{'open-status':ticket?.status ===
                        'Open','inprogress':ticket?.status === 'In Progress','closed':ticket?.status === 'Closed'}">
                        {{ticket?.status}}</td>
                    <td class="col-sm-2">{{ticket?.floorEquipmentIds?.length || 0}}
                    </td>
                    <td class="col-sm-2 action-items">
                        <button [disabled]="btnService.getStatus()" (click)="linkTicket(ticket)" class="btn
                            btn-primary">
                            Link
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="opentickets?.length === 0 && showClosedTicked">
        <p class="message"> All Tickets on this Plan are already closed. Uncheck the box above to view them.</p>
    </div>
    <div class="text-center bottom-links">
        <span>or</span>
        <a href="javascript:void(0);" (click)="createNewTicket()">Create New
            Ticket</a>
    </div>
</div>
<div class="modal-footer border-none" (click)="$event.stopPropagation()">
    <button type="button" (click)="closeModal()" class="btn btn-secondary
        mrg-0">Cancel</button>
</div>