export const ArchiveHub = {
    dialogId: 'Archive Hub',
    header: 'Archive Hub',
    content: 'Are you sure you want to archive <strong class="text-break">{{#name}}</strong>? There are currently <strong>{{#archiveHubDeviceNetworkLinkCnt}}</strong> {{#deviceText}} associated with<strong> {{#name}}</strong>. Are you sure you want to remove all Hub Connections/Pathways?',
    btnSuccessText: 'Archive',
    btnCancelText: 'Cancel',
    btnSuccessClass: 'btn-primary',
    btnCancelClass: 'btn-secondary',
    btnSuccessStyle: '',
    bodyClass: '',
    bodyStyle: '',
    dialogClass: '',
    dialogStyle: '',
}