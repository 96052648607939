<ng-container *ngIf="device.childFloorEquipments && device.childFloorEquipments.length > 0">
    <ng-container
        *ngFor="let child of device.childFloorEquipments | floorCategoryFilter :  {'devices': filterCategoryData, 'isProject' : isProject, 'assignedToFilter': assignedToFilter, 'isChild': true};let k=index;trackBy: childCableTrackByFn">
        <svg *ngIf="child && !child.isArchived && child.isConnectionLineOrCablePath  && child.networkSwitchLinkId > 0 && (showConnectionLine || (!showConnectionLine && selectedDevice && (selectedDevice.id === child.id || child.showConnectionLine || selectedDevice.id === child.networkSwitchLinkId || child.parentId === selectedDevice.id || device.id === selectedDevice.parentId || child.networkSwitchLinkId === selectedDevice.id) ))"
            class="switch-line" id="svg-cable-{{child.id}}"
            [ngClass]="{'svg-zindex': device && selectedDevice && (selectedDevice.id===child.id || selectedDevice.id===device.id || child.networkSwitchLinkId === selectedDevice.id), 'svg-selected-zindex': selectedDevice && selectedDevice.id===device.id}">
            <g [ngClass]="{'outline': device && selectedDevice  && selectedDevice.id===child.id}">
                <title *ngIf="child.connectionLength > 0">{{child.connectionLength | number: '1.1-1'}}
                    {{unitOfMeasurements==='Meters'?'meters':'feet'}}</title>
                <!-- Secondary Connection Line Start-->
                <g id="draw-lines-{{child.id}}" *ngIf="child.cableCoordinates === null"
                    [ngStyle]="{'cursor':isCableEnable && isEdit && selectedDevice && selectedDevice.id===child.id ? 'pointer':''}">
                    <line [attr.x1]="device.imageLeft" [attr.y1]="device.imageTop" [attr.x2]="child.cableLinkImageLeft"
                        [attr.y2]="child.cableLinkImageTop" stroke-linecap="round" [attr.stroke]="child.displayPinColor"
                        [attr.stroke-opacity]="1" [attr.stroke-width]="5"
                        [attr.stroke-dasharray]="isCableEnable && isEdit && selectedDevice && selectedDevice.id===child.id ? '1':'4,8'"
                        id="line-cable-{{child.id}}" (mousedown)="addNewPointToLine($event)">

                    </line>

                    <line *ngIf="child && (child.cableCoordinates === null || selectedDevice.id!==child.id)" [attr.x1]="child.imageLeft"
                        [attr.y1]="child.imageTop" [attr.x2]="child.cableLinkImageLeft"
                        [attr.y2]="child.cableLinkImageTop" stroke-linecap="round"
                        [attr.stroke]="child.displayPinColor" [attr.stroke-opacity]="0" [attr.stroke-width]="10"
                        [attr.stroke-dasharray]="isCableEnable && isEdit && selectedDevice && selectedDevice.id===child.id ? '1':'10,4'"
                        id="line-cable-{{child.id}}-dim" (mousedown)="addNewPointToLine($event)">
                    </line>
                </g>
                <!-- Secondary Connection Line End-->
                <!-- Secondary Cable Path Start-->
                <g *ngIf="child.cableCoordinates !== null && isCableEnable">
                    <title *ngIf="child.connectionLength > 0">{{child.connectionLength | number: '1.1-1'}}
                        {{unitOfMeasurements==='Meters'?'meters':'feet'}}</title>
                    <g [ngClass]="{'outline':selectedDevice && selectedDevice.id === child.id }">
                        <g *ngFor="let childCable of child.cableCoordinates; let i = index" id="draw-lines-{{child.id}}"
                            [ngStyle]="{'cursor':isEdit && selectedDevice && selectedDevice.id===child.id ? 'pointer':''}">
                            <line *ngIf="(child.cableCoordinates.length-1) > i" [attr.x1]="childCable?.x"
                                [attr.y1]="childCable?.y" [attr.x2]="child.cableCoordinates[i+1]?.x"
                                [attr.y2]="child.cableCoordinates[i+1]?.y" stroke-linecap="round"
                                [attr.stroke]="child.displayPinColor" [attr.stroke-width]="5" [attr.stroke-opacity]="1"
                                [attr.stroke-dasharray]="0" id="line-cable-{{child.id}}-{{i+1}}"
                                (mousedown)="addNewPointToLine($event)">

                            </line>
                        </g>
                    </g>
                </g>
                <!-- Secondary Cable Path End-->
            </g>
            <g id="guideContainer-cable-{{child.id}}" class="guideContainer"
                [ngStyle]="{'display':((!isEdit || !selectedDevice)) ?'none':'block', 'pointer-events':(!isEdit || !selectedDevice) ?'none': 'visible'}">
            </g>
        </svg>
    </ng-container>
</ng-container>
<ng-container
    *ngIf="device.isConnectionLineOrCablePath && device.networkSwitchLinkId > 0 && (showConnectionLine || (!showConnectionLine && selectedDevice && (selectedDevice.id === device.id || device.showConnectionLine || device.networkSwitchLinkId === selectedDevice.id || selectedDevice.parentId === device.id) ))">
    <svg class="switch-line" id="svg-cable-{{device.id}}"
        [ngClass]="{'svg-zindex': device && selectedDevice && (selectedDevice.id===device.id || device.networkSwitchLinkId === selectedDevice.id), 'svg-selected-zindex': selectedDevice && selectedDevice.id===device.id}">
        <g [ngClass]="{'outline':device && selectedDevice && selectedDevice.id===device.id}">
            <!-- Primary Connection Line Start-->
            <title *ngIf="device.connectionLength > 0">{{device.connectionLength | number: '1.1-1'}}
                {{unitOfMeasurements==='Meters'?'meters':'feet'}}</title>
            <g id="draw-lines-{{device.id}}" *ngIf="device.cableCoordinates === null"
                [ngStyle]="{'cursor':isCableEnable && isEdit && selectedDevice && selectedDevice.id===device.id ? 'pointer':''}">
                <line [attr.x1]="device.imageLeft" [attr.y1]="device.imageTop" [attr.x2]="device.cableLinkImageLeft"
                    [attr.y2]="device.cableLinkImageTop" stroke-linecap="round" [attr.stroke]="device.displayPinColor"
                    [attr.stroke-opacity]="1" [attr.stroke-width]="5"
                    [attr.stroke-dasharray]="isCableEnable && isEdit && selectedDevice && selectedDevice.id===device.id ? '1':'4,8'"
                    id="line-cable-{{device.id}}" (mousedown)="addNewPointToLine($event)">
                </line>
                <line *ngIf="device && device.cableCoordinates === null || (selectedDevice && selectedDevice.id!==device.id)" [attr.x1]="device.imageLeft"
                    [attr.y1]="device.imageTop" [attr.x2]="device.cableLinkImageLeft"
                    [attr.y2]="device.cableLinkImageTop" stroke-linecap="round" [attr.stroke]="device.displayPinColor"
                    [attr.stroke-opacity]="0" [attr.stroke-width]="10"
                    [attr.stroke-dasharray]="isCableEnable && isEdit && selectedDevice && selectedDevice.id===device.id ? '1':'10,4'"
                    id="line-cable-{{device.id}}-dim" (mousedown)="addNewPointToLine($event)">
                </line>
            </g>
            <!-- Primary Connection Line End-->
            <!-- Primary Cable Path Start-->
            <g *ngIf="device.cableCoordinates !== null && isCableEnable">
                <title *ngIf="device.connectionLength > 0">{{device.connectionLength | number: '1.1-1'}}
                    {{unitOfMeasurements==='Meters'?'meters':'feet'}}</title>
                <g [ngClass]="{'outline':selectedDevice && selectedDevice.id === device.id }">
                    <g *ngFor="let cable of device.cableCoordinates; let i = index" id="draw-lines-{{device.id}}"
                        [ngStyle]="{'cursor':isEdit && selectedDevice && selectedDevice.id===device.id ? 'pointer':''}">
                        <line *ngIf="(device.cableCoordinates.length-1) > i" [attr.x1]="cable?.x" [attr.y1]="cable?.y"
                            [attr.x2]="device.cableCoordinates[i+1]?.x" [attr.y2]="device.cableCoordinates[i+1]?.y"
                            stroke-linecap="round" [attr.stroke]="device.displayPinColor" [attr.stroke-width]="5"
                            [attr.stroke-opacity]="1" [attr.stroke-dasharray]="0" id="line-cable-{{device.id}}-{{i+1}}"
                            (mousedown)="addNewPointToLine($event)">
                        </line>
                    </g>
                </g>
            </g>
            <!-- Primary Cable Path End-->
        </g>
        <g id="guideContainer-cable-{{device.id}}" class="guideContainer"
            [ngStyle]="{'display':((!isEdit || !selectedDevice)) ?'none':'block', 'pointer-events':(!isEdit || !selectedDevice) ?'none': 'visible'}">
        </g>
    </svg>
</ng-container>