import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import moment from 'moment';
@Pipe({
  name: 'imageGroupBy'
})
export class ImageGroupByPipe implements PipeTransform {

  transform(collection: any[], property: string): any {
    // prevents the application from breaking if the array of objects doesn't exist yet
    if (!collection) {
      return null;
    }
    let groupedCollection: any = [];
    if (property === 'createdDateTime') {
      groupedCollection = collection.reduce((previous, current) => {
        let currentCreatedDateTime;
        if (current && current.id) {
          currentCreatedDateTime = moment(new Date(current.createdDateTime)).format('MM/DD/YYYY');
        } else {
          currentCreatedDateTime = moment(new Date()).format('MM/DD/YYYY');
        }
        if (!previous[currentCreatedDateTime]) {
          previous[currentCreatedDateTime] = [current];
        } else {
          previous[currentCreatedDateTime].push(current);
        }
        return previous;
      }, {});
    } else {
      collection.forEach((res) => {
        if (res.stage != null) {
          if (res.stage == 'Live System' || res.stage == 'Live Site') {
            res.stage = 'Live Site';
          }
        } else {
          res.stage = '';// Images without Stage
        }
      })
      groupedCollection = _.orderBy(collection, [item => item.stage], ['desc']).reduce((previous, current) => {
        if (!previous[current[property]]) {
          previous[current[property]] = [current];
        } else {
          previous[current[property]].push(current);
        }
        return previous;
      }, {});
    }

    // this will return an array of objects, each object containing a group of objects
    return Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key], showImageSection: groupedCollection[key].length > 0 ? true : false, showDateSection: groupedCollection[key].length > 0 ? true : false }));
  }

}
