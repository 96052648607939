import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { FloorDetailsComponent } from '../floor-details/floor-details.component';
import { SavePlanPopupComponent } from '../component/save-plan-popup/save-plan-popup.component';
import { AuthService, EventEmitterType, EventService } from '@SiteOwl/core';
import { ModalOptions } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
@Injectable({
  providedIn: 'root'
})
export class FloorDetailsGuard {
  canDeactivateCall = false;
  constructor(private router: Router, private eventService: EventService,private authService: AuthService) { }
  canDeactivate(
    component: FloorDetailsComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot): any {
    if (component !== null && !component.customerChangeAttemptFlag && this.authService.isUserLoggedIn()) {
      this.canDeactivateCall = true;
      if (component.totalDiscardCount > 0 && !component.syncStarted) {

        const initialState: ModalOptions = {
          initialState: { totalDiscardCnt: component.totalDiscardCount },
          class: '', ignoreBackdropClick: true
        };
        component.bsModalRef = component.modalService.show(SavePlanPopupComponent, initialState);
        component.bsModalRef.content.callSyncEvent.subscribe((r: any) => {
          component.bsModalRef?.hide();
          component.saveFloorPlan();
        })
        component.bsModalRef.content.callDiscardEvent.subscribe((r: any) => {
          component.bsModalRef?.hide();
          component.resetFloorPlan();
        })
        this.eventService.subscribe(EventEmitterType.floorDetailRoutChangeCall, res => {
          this.canDeactivateCall = false;
          if (!_.isEmpty(nextState.root.queryParams)) {
            const url = nextState.url.split('?');
            const urlWithoutParam = url[0];
            const queryParams = nextState.root.queryParams;
            this.router.navigate([`${urlWithoutParam}`], { queryParams: queryParams });
          } else {
            this.router.navigate([nextState.url]);
          }
        })
        return false
      } else if (component.syncStarted) {
        return false
      } else {
        this.canDeactivateCall = false;
        return true
      }
    }
  }
}
