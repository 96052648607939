<div class="modal-header">
    <h1 style="cursor: default" class="modal-title pull-left">
        {{showHeader}} History - {{equipmentDetails?.name}}</h1>
    <button data-dismiss="modal" (click)="closeModal()" aria-label="Close" class="close pull-right" type="button">
        <i class="fas fa-close"></i>
    </button>
</div>
<div class="modal-body device-history-body" id="myGroup">

    <div *ngIf="isSystemUpdate" class="checkbox-label">
        <input [checked]="displaySystemData" (change)="toggletatus($event)" class="lbl-check" type="checkbox" [id]="'systemCheck'">
        <label [for]="'systemCheck'">Include Changes Triggered by Monitoring</label>
    </div>

    <div class="row device-history-block">
        <div class="card history-inner-block">
            <div class="device-history-header clearfix" #deviceHistoryHeader>
                <div class="device-head date-time-head">Date / Time</div>
                <div class="device-head updated-head">Updated By</div>
                <div class="device-head field-head">Field Changed</div>
                <div class="device-head old-head">Old Value</div>
                <div class="device-head new-head">New Value</div>
            </div>
            <div class="accordion-group" #accordionGroup style="min-height: 50px;position: relative;">
                <div *ngIf="loaderService.isLoading | async" style="min-height: 50px !important;">
                    <so-loader [smallloader]="true"></so-loader>
                </div>
                <accordion [closeOthers]="false">
                    <accordion-group #element [isDisabled]="checkLabel" (isOpenChange)="changeStatus()" class="device-panel-body" *ngFor="let deviceData of dviceHistory | history : displaySystemData; let index = index" [ngStyle]="{'display':deviceData?.modifiedDateTime ? 'block':'none'}">
                        <div accordion-heading class="device-value-block clearfix" *ngIf="deviceData?.modifiedDateTime && deviceData.keys[0]?.key !== 'imageTop' && deviceData?.updatedBy">
                            <div class="device-val device-val-date"><i (click)="clicked(deviceData,$event,'toggle')" *ngIf="deviceData?.keys[0]?.key !=='imageLeft' && deviceData?.keys?.length > 1; else imagePos" class="fa device-accordian"></i>
                                <ng-template #imagePos>
                                    <i (click)="clicked(deviceData,$event,'toggle')" *ngIf="deviceData?.keys[0]?.key === 'imageLeft' && deviceData?.keys?.length - 1 > 1 ; else singleRecord" class="fa device-accordian"></i>
                                    <ng-template #singleRecord>
                                        <span class="single-record"></span>
                                    </ng-template>
                                </ng-template>
                                <span class="device-dat-val" title="{{deviceData.modifiedDateTime| datex: 'MM/DD/YYYY HH:mm:ss'}}">{{deviceData.modifiedDateTime|
                                    datex: 'MM/DD/YYYY HH:mm'}}</span>
                            </div>
                            <div class="device-val device-val-update">{{deviceData.updatedBy}}</div>
                            <div class="device-val device-val-field">{{setDeviceFieldLabel(deviceData)}}</div>
                            <div (click)="clicked(deviceData,$event,'toggle','value')" [ngClass]="{'cursor-pointer':deviceData?.keys[0]?.key !=='imageLeft' && deviceData?.keys?.length > 1}" *ngIf="deviceData.keys[0]?.key !== 'imageLeft' && deviceData.keys[0]?.key !== 'pinColor';else location"
                                class="device-val device-val-old ">

                                {{setDeviceValue(deviceData,false) !== '' ? setDeviceValue(deviceData,false): ''}}
                            </div>
                            <ng-template #location>
                                <div *ngIf="deviceData.keys[0]?.key !== 'pinColor';else oldPinColor" (click)="clicked(deviceData,$event,'toggle','value')" [ngClass]="{'cursor-pointer':deviceData?.keys?.length - 1 > 1}" class="device-val device-val-old">
                                    {{ setDeviceValueLocation(deviceData,false) !== '' ? setDeviceValueLocation(deviceData,false) :'' }}
                                </div>
                                <ng-template #oldPinColor>
                                    <div class="device-val device-val-old">
                                        <div class="d-flex align-items-center">
                                            <span class="me-2">{{checkLabelForPinColorForOld(deviceData)}}</span>
                                            <span class="pin-color" [ngStyle]="{'background':setDeviceValue(deviceData,false)}">

                                            </span>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-template>
                            <div (click)="clicked(deviceData,$event,'toggle','value')" [ngClass]="{'cursor-pointer':deviceData?.keys[0]?.key !=='imageLeft' && deviceData?.keys?.length > 1}" *ngIf="deviceData.keys[0]?.key !== 'imageLeft' && deviceData.keys[0]?.key !== 'pinColor';else locationNew"
                                class="device-val device-new">
                                {{setDeviceValue(deviceData,true) !== '' ? setDeviceValue(deviceData,true) : ''}}
                            </div>
                            <ng-template #locationNew>
                                <div *ngIf="deviceData.keys[0]?.key !== 'pinColor';else newPinColor" (click)="clicked(deviceData,$event,'toggle','value')" [ngClass]="{'cursor-pointer':deviceData?.keys?.length - 1 > 1}" class="device-val device-new">
                                    {{setDeviceValueLocation(deviceData,true) !== '' ? setDeviceValueLocation(deviceData,true): ''}}
                                </div>
                                <ng-template #newPinColor>
                                    <div class="device-val device-new">
                                        <div class="d-flex align-items-center">
                                            <span class="me-2">{{checkLabelForPinColorForNew(deviceData)}}</span>
                                            <span class="pin-color" [ngStyle]="{'background':setDeviceValue(deviceData,true)}">
                                            </span>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-template>

                        </div>
                        <div>
                            <div *ngFor="let key of deviceData.keys" class=" clearfix">
                                <div *ngIf="key.key !== 'imageTop'" class="device-collapse">
                                    <div class="collapse-val collapse-date">&nbsp;</div>
                                    <div class="collapse-val collapse-update">&nbsp;</div>
                                    <div class="collapse-val collapse-field">{{key.label}}</div>
                                    <div *ngIf="key.key !== 'imageLeft' && key.key !== 'pinColor';else LocationChild" class="collapse-val collapse-old">
                                        {{deviceData?.old !== {} && (deviceData?.old[key?.key] || deviceData?.old[key?.key] === 0) ? (key?.key === 'imagesCount' ? ( deviceData.old[key?.key] !== 1 ? deviceData.old[key?.key] + ' Images' : deviceData.old[key?.key] + ' Image') : deviceData.old[key?.key]
                                        ) :'' }}
                                    </div>
                                    <ng-template #LocationChild>
                                        <div *ngIf="key.key !== 'pinColor'else showOldPinColor" class="collapse-val collapse-old">
                                            {{deviceData?.old !== {} && deviceData?.old['imageLeft'] ? '(' + deviceData?.old['imageLeft'] + ',':'' }} {{deviceData?.old !== {} && deviceData?.old['imageTop'] ? deviceData?.old['imageTop'] + ')': '' }}
                                        </div>
                                        <ng-template #showOldPinColor>
                                            <div class="device-val device-val-old">
                                                <div class="d-flex align-items-center">
                                                    <span class="me-2">{{checkLabelForPinColorForOld(deviceData)}}</span>
                                                    <span class="pin-color" [ngStyle]="{'background':deviceData.old[key?.key]}">

                                                    </span>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ng-template>
                                    <div *ngIf="key.key !== 'pinColor' && key.key !== 'imageLeft';else LocationChildnew" class="collapse-val collapse-new">
                                        {{(deviceData.new[key?.key] || deviceData.new[key?.key] === 0) ? (key?.key === 'imagesCount' ? (deviceData.new[key?.key] !== 1 ? (deviceData.new[key?.key] + ' Images') :(deviceData.new[key?.key] + ' Image') ) : deviceData.new[key?.key] ) :'' }}
                                    </div>
                                    <ng-template #LocationChildnew>
                                        <div *ngIf="key.key !== 'pinColor';else pinColor">
                                            ({{deviceData.new['imageLeft']?deviceData.new['imageLeft']:'' }}, {{deviceData.new['imageTop']?deviceData.new['imageTop']:deviceData?.old['imageTop'] }})
                                        </div>
                                        <ng-template #pinColor>
                                            <div class="collapse-val collapse-new">
                                                <div class="d-flex align-items-center ">
                                                    <span class="me-2"> {{checkLabelForPinColor(deviceData)}}</span>
                                                    <span class="pin-color" [ngStyle]="{'background':deviceData.new[key?.key]}"></span>
                                                </div>
                                            </div>
                                        </ng-template>

                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </accordion-group>
                </accordion>

            </div>


        </div>
    </div>


</div>
<div class="modal-footer border-none">
    <button type="button" (click)="closeModal()" class="btn btn-primary mrg-0">Close</button>
</div>