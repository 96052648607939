<div class="modal-body mt-3">
    <div class="row">
        <div class="col-xl-12 d-flex justify-content-center">
            <h3 class="mrg-b-15">Saving Updates to Server</h3>
        </div>
        <div *ngIf="imageCnt > 0" class="col-xl-10 mrg-b-15 d-flex center mx-auto">
            <div class="sync-progress-val">
                <div class="progress-block">
                    <div class="progress">
                        <progressbar [value]="getSyncImageCal() || 0"></progressbar>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-12 d-flex justify-content-center">
            <p class="mrg-0" *ngIf="totalDiscardCount > 0 && (deviceCnt > 0 || taskCnt > 0)">
                {{totalDiscardCount}} Device<span *ngIf="deviceCnt > 1">s</span> Saving
                <span *ngIf="imageCnt > 0"> |
                    <span class="blue">{{imgUploadedCnt}}</span> of {{imageCnt}} Image<span
                        *ngIf="imgUploadedCnt > 1">s</span> Saved</span>
            </p>
            <p class="mrg-0" *ngIf="deviceCnt === 0 && taskCnt === 0">
                <span class="blue">{{imgUploadedCnt}}</span> of {{imageCnt}} Image<span
                    *ngIf="imgUploadedCnt > 1">s</span> Saved
            </p>
        </div>
    </div>

    <div *ngIf="endSyncCallStarted" class="col-xl-12 d-flex justify-content-center">
        <div class="row">
            <div class="col-sm-12 text-center">
                <p>Please wait, syncing in progress.</p>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="col-sm-12 text-center">
        <so-button [className]="'btn btn-secondary'" [text]="'Cancel'" [ngClass]="{'not-allowed': endSyncCallStarted}"
            [disabled]="endSyncCallStarted" (onClick)="closeModal()"></so-button>
    </div>
</div>