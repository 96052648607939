<div class="modal-header">
    <h1 class="modal-title pull-left">Plan Report Options</h1>
    <button data-dismiss="modal" (click)="_action('cancel')" aria-label="Close" class="close pull-right" type="button">
        <em class="fas fa-close"></em>
    </button>
</div>
<div class="modal-body plan-select-body">
    <div class="d-flex mx-auto col-sm-11 p-0 justify-content-center align-items-center plan-select-col">
        <div class="col">
            <div class="card image-card">
                <em class="icon far fa-list-ol"></em>
                <h2>Numbered Plan Report</h2>
                <p>Uses numbered pins that correspond to a Device legend
                    <br /> &nbsp;
                </p>
                <div class="text-center">
                    <a href="javascript:void(0);" class="btn btn-primary mrg-b-15" (click)="_action('numberedPrintout')">
                        Configure
                    </a>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card image-card">
                <em class="icon far fa-map-marker-alt"></em>
                <h2>Icon Plan Report</h2>
                <p>Uses the system Device icons with optional labels <br /> &nbsp;
                </p>
                <div class="text-center">
                    <a href="javascript:void(0);" class="btn btn-primary mrg-b-15" (click)="_action('iconPrintout')">
                        Configure
                    </a>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card image-card">
                <em class="icon far fa-layer-group"></em>
                <h2>Multi-Plan Report</h2>
                <p>Report of all Plan images and Device lists from the selected Plans
                </p>
                <div class="text-center">
                    <a href="javascript:void(0);" class="btn btn-primary mrg-b-15" (click)="_action('multiPlanPrintout')">
                        Configure
                    </a>
                </div>
            </div>
        </div>

    </div>

</div>
<div class="modal-footer">
    <button type="button" (click)="_action('cancel')" class="btn btn-secondary me-0" data-dismiss="modal">Cancel</button>
</div>