<div class="modal-header">
    <h1 class="modal-title pull-left">Icon Plan Report</h1>
    <button data-dismiss="modal" (click)="closeModal()" aria-label="Close" class="close pull-right" type="button">
        <em class="fas fa-close"></em>
    </button>
</div>
<div class="modal-body ">

    <div class="generate-pdf-body">
        <div class="equipment-counter-pdf">
            <div class="counter-tabs equipment-count">
                <accordion>
                    <accordion-group class="accordin " [isOpen]="true">
                        <div accordion-heading>
                            <div class="slide-toggle">
                                Viewing Preferences
                            </div>
                        </div>
                        <div class="filter-inner">
                            <label class="device-card mb-1">Optimized For</label>
                            <div *ngFor="let item of optimizedReportOptions; let i = index;" class="action-col ms-0">
                                <so-radio [className]="'lbl-check'" [id]="'amt_' + item.name" [name]="'optimizedFor'"
                                    (change)="optimizedChanged(item)" [isChecked]="(i === 0)" [label]="item.name">
                                </so-radio>
                            </div>
                            <ul class="list-unstyled mt-2">
                                <label class="device-card mb-1">Pin Appearance</label>
                                <li class="d-flex pin-slider-wrapper" style="margin-bottom: 10px;">
                                    <div class="pin-slider-card device-card d-flex align-items-center">
                                        <label class="label-width">
                                            Pin Size
                                        </label>
                                        <ngx-slider [(value)]="pinSizeValue" #ngxPinSizeSlider
                                            (valueChange)="pinSizeChange($event)"
                                            [options]="pinSizeOptions"></ngx-slider>
                                    </div>
                                </li>
                                <li class="d-flex pin-slider-wrapper ">
                                    <div class="pin-slider-card device-card">
                                        <label class="label-width">
                                            Pin Color
                                        </label>
                                        <div class="form-group pin-color-filter-group mb-0">
                                            <select class="form-control" [(ngModel)]="pinColor" name="active">
                                                <option value="Device Color"
                                                    [selected]="(pinColor === 'Device Color'?true:false)">Device Color
                                                </option>
                                                <option *ngIf="!isFromSite" value="Install Status"
                                                    [selected]="(pinColor === 'Install Status'?true:false)">Install
                                                    Status
                                                </option>
                                                <option value="Operational Status"
                                                    [selected]="(pinColor === 'Operational Status'?true:false)">
                                                    Operational
                                                    Status</option>
                                            </select>
                                        </div>

                                    </div>
                                </li>
                                <li class="d-flex pin-slider-wrapper" *ngIf="!isFromSite">
                                    <div class="pin-slider-card device-card">

                                        <label class="label-width">
                                            Assigned To
                                        </label>
                                        <div class="form-group pin-color-fiter-group mb-0">
                                            <select class="form-control" [(ngModel)]="assignedTo" name="active">
                                                <option value="null" [selected]="(assignedTo === null?true:false)">
                                                    Anyone
                                                </option>
                                                <option value="{{user?.user?.id}}" [selected]="(assignedTo === user?.user?.id?true:false)">
                                                    Me
                                                </option>
                                                <option *ngFor="let item of installByUserItems"
                                                    [ngStyle]="{'display': item.isRemoved? 'none': 'block'}"
                                                    [ngValue]="item.key">{{item.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </li>

                                <ng-container
                                    *ngFor="let item of filterCategory | equipmentCountFilter:  {type: 'vp'};let index = index">
                                    <li [ngClass]="filterLabel(item.category)">
                                        <p *ngIf="item.category.id===5">
                                            <label class="label-width">
                                                Device List
                                            </label>
                                        </p>
                                        <so-checkbox [isChecked]="item.isChecked"
                                            (onChange)="filterCategoryChanged(item)" [label]="item.category.displayName"
                                            [id]="'category_num_report_' + item.category.id"
                                            [ngClass]="'lbl-check'"></so-checkbox>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </accordion-group>
                    <accordion-group class="accordin">
                        <div accordion-heading>
                            <div class="slide-toggle">
                                System Type
                            </div>
                        </div>
                        <div class="filter-inner">
                            <ul class="list-unstyled">
                                <li *ngFor="let item of filterCategory | equipmentCountFilter:  {type: 'st'}; let i=index"
                                    [ngClass]="filterLabel(item.category)">
                                    <so-checkbox *ngIf="item.category.name !== 'Show Plan Markup'"
                                        [isChecked]="item.isChecked" (onChange)="filterCategoryChanged(item)"
                                        [label]="item.category.name" [id]="'category_num_report_st_' + item.category.id"
                                        [ngClass]="'lbl-check'"></so-checkbox>
                                </li>
                            </ul>
                        </div>
                    </accordion-group>
                    <accordion-group class="accordin ">
                        <div accordion-heading>
                            <div class="slide-toggle">
                                Operational Status
                            </div>
                        </div>
                        <div class="filter-inner">
                            <ul class="list-unstyled">
                                <li *ngFor="let item of filterCategory | equipmentCountFilter:  {type: 'op'}; let i=index"
                                    [ngClass]="filterLabel(item.category)">
                                    <so-checkbox [isChecked]="item.isChecked" (onChange)="filterCategoryChanged(item)"
                                        [label]="item.category.displayName"
                                        [id]="'category_num_report_op_' + item.category.id"
                                        [ngClass]="'lbl-check'"></so-checkbox>
                                </li>
                            </ul>
                        </div>
                    </accordion-group>
                    <accordion-group class="accordin " *ngIf="projectId || !isFromSite">
                        <div accordion-heading>
                            <div class="slide-toggle">
                                Install Status
                            </div>
                        </div>
                        <div class="filter-inner">
                            <ul class="list-unstyled">
                                <li *ngFor="let item of filterCategory | equipmentCountFilter:  {type: 'is'}; let i=index"
                                    [ngClass]="filterLabel(item.category)">
                                    <so-checkbox [isChecked]="item.isChecked" (onChange)="filterCategoryChanged(item)"
                                        [label]="item.category.displayName"
                                        [id]="'category_num_report_is_' + item.category.id"
                                        [ngClass]="'lbl-check'"></so-checkbox>
                                </li>
                            </ul>
                        </div>
                    </accordion-group>
                </accordion>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <span>
        <button type="button" (click)="closeModal()" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
        <button type="button" (click)="downloadIcon()" class="btn btn-primary m-0"> <em class="fa fa-download"></em>
            Download
        </button>
    </span>
</div>