import { DevicePriority, EquipmentIPTypeList, EquipmentLocationList } from '@SiteOwl/core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EquipmentDetailsService {

  constructor() { }
  getDropDownOptions(data:any, rootData:any, fromCount?:any) {
    const equipmentLocation = [], equipmentIPTypeList = [], fieldOfViewList = [], installByUser = [], devicePriority = [];

    equipmentLocation.push({ key: null, value: 'Select Location', isDisabled: false });
    equipmentIPTypeList.push({ key: null, value: 'None', isDisabled: false });
    fieldOfViewList.push({ key: null, value: 'None', isDisabled: false });
    if (fromCount) {
      installByUser.push({ key: 'Unassigned', value: 'Unassigned', isDisabled: false });
    } else {
      installByUser.push({ key: null, value: 'Unassigned', isDisabled: false });
    }


    EquipmentLocationList.forEach(element => {
      equipmentLocation.push(
        { key: element, value: element, isDisabled: false }
      );
    });
    EquipmentIPTypeList.forEach(element => {
      equipmentIPTypeList.push(
        { key: element, value: element, isDisabled: false }
      );
    });
    // FieldOfViewList.forEach(element => {
    //   fieldOfViewList.push(
    //     { key: element.value, value: element.label, isDisabled: false }
    //   );
    // });

    for (const element in DevicePriority) {
        devicePriority.push({ key: DevicePriority[element], value: DevicePriority[element], isDisabled: false })
    }
    if (rootData.length > 0) {
      rootData.forEach((element:any) => {
        installByUser.push(
          { key: element.id, value: element.name || element.email, isDisabled: false, isRemoved: element.isRemoved }
        );
      });
    }


    if (data === 'location') {
      return equipmentLocation;
    } else if (data === 'iptype') {
      return equipmentIPTypeList;
    } else if (data === 'viewlist') {
      return fieldOfViewList;
    } else if (data === 'installbyuser') {
      return installByUser;
    } else if (data === 'devicePriority') {
      return devicePriority;
    }
    return [];
  }
}
