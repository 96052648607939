<div class="modal-header">
    <h1 class="modal-title pull-left">Save Plan Changes</h1>
</div>
<div class="modal-body ">
    <span>
        You have {{totalDiscardCnt}} unsaved changes on this Plan. Do you want to save your changes?
      </span>
</div>

<div class="modal-footer">
    <button type="button" (click)="closeModal()" class="btn btn-danger" data-dismiss="modal">Discard {{totalDiscardCnt}} <ng-container *ngIf="totalDiscardCnt > 1">Changes</ng-container><ng-container *ngIf="totalDiscardCnt < 2">Change</ng-container></button>
    <button type="submit" (click)="saveChanges()" class="btn btn-primary m-0">Save Changes</button>
</div>