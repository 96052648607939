<ng-container *ngIf="!showFirstDragPopup">
    <div *ngIf="isContextVisible" (scroll)="$event.stopPropagation();" (mousedown)="$event.stopPropagation();" id="context-menu" style="pointer-events: all;">
        <div [ngStyle]="position"
            [ngClass]="{'right-content-menu':(isFromFav ? percentPos >= -10 :  percentPos > 79),'top-content-menu': (percentTopPos > 75 && isEdit) || (screenHeight < 769 && percentTopPos > 50 && isEdit),'context-menu-fixed':fromDevice,'context-menu':!fromDevice}">
            <ul class="list-unstyled">
                <li *ngIf="createFavoriteShow" (click)="contextAction($event,'createFavorite')">
                    <em class="fas fa-star"></em>Create Favorite
                </li>
                <li *ngIf="createTicketShow" (click)="contextAction($event,'createTicket')"><em
                        class="fas fa-tag"></em>Create
                    New Ticket
                </li>
                <li *ngIf="linkTicketShow" (click)="contextAction($event,'linkTicket')">
                    <em class="fas fa-link"></em>Link to Ticket
                </li>
                <li *ngIf="monitoredStatus === 'ONLINE' && monitoredLiveFeed && monitoredLiveFeed !== false && viewSnapshotShow" (click)="contextAction($event,'viewSnapshot')">
                    <em class="fas fa-desktop"></em>View Snapshot
                </li>
                <li *ngIf="duplicateShow" (click)="contextAction($event,'duplicate')">
                    <em class="fas fa-copy"></em>Duplicate
                </li>
                <li *ngIf="makeHubShow" (click)="contextAction($event,'createHub')">
                    <em class="fas fa-network-wired "></em>Make Hub
                </li>
                <li *ngIf="removeHubShow" (click)="contextAction($event,'removeHub')">
                    <em class="fas fa-network-wired red-text"></em>Remove Hub
                </li>
                <li *ngIf="addFlagShow" (click)="contextAction($event,'addFlag')">
                    <em class="fas fa-flag red-text"></em>Add Flag
                </li>
                <li *ngIf="removeFlagShow" (click)="contextAction($event,'removeFlag')">
                    <em class="far fa-flag "></em>{{(selectedItem && selectedItem?.flagCreatedBy!==null) ? 'Resolve Flag':'Remove Flag'}}
                </li>
                <li *ngIf="viewHistoryShow" (click)="contextAction($event,'viewHistory')">
                    <em class="fas fa-history"></em>View History
                </li>
                <li *ngIf="archiveShow" (click)="contextAction($event,'archive')">
                    <em class="fas fa-inbox-in red-text"></em>Archive
                </li>
                <li *ngIf="unArchiveShow" (click)="contextAction($event,'unArchive')">
                    <em class="fas fa-inbox-out"></em>Unarchive
                </li>
                <li *ngIf="deleteShow" (click)="contextAction($event,'delete')">
                    <em class="fas fa-trash-alt"></em>Delete
                </li>
                <li *ngIf="deleteFavoriteShow" (click)="contextAction($event,'deleteFavorite')" 
                    >
                    <em class="fas fa-trash-alt"></em>Delete Favorite
                </li>
            </ul>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="showFirstDragPopup">


    <div id="contextDropForm" [ngStyle]="position"
        [ngClass]="{'right-content-menu':percentPos > 69,'top-content-menu': percentTopPos > 70, 'context-menu-fixed-drag':fromDevice,'context-menu-drag':!fromDevice, 'device-drag-top':DeviceData.type !== 'task'}">
        <form [formGroup]="equipmentDetailsForm" style="cursor: default;" novalidate>
            <ul class="list-unstyled">
                <li>
                    <div class="form-group replace-cost-group device-group">
                        <label>
                            <ng-container *ngIf="DeviceData.type === 'task'">Task</ng-container>
                            <ng-container *ngIf="DeviceData.type !== 'task'">Device</ng-container> Name
                            <sup>*</sup>
                        </label>
                        <input maxlength="80" id="deviceName" type="text"
                            class="form-control" placeholder="{{DeviceData.type === 'task' ? 'Task' : 'Device'}} Name"
                            formControlName="name" tabindex="1" (focus)="onFocus($event)"
                            (blur)="saveDetails(true,$event, 'name')">
                        <so-control-messages [name]="'Name'" [control]="equipmentDetailsForm.controls.name"
                            [fromContext]="true">
                        </so-control-messages>
                    </div>
                </li>
                <li>
                    <div class="form-group replace-cost-group device-group">
                        <label>Abbreviated Name</label>
                        <input maxlength="80" type="text" class="form-control"
                            placeholder="Abbreviated Name" formControlName="abbreviatedName"
                            tabindex="2" (focus)="onFocus($event)" (blur)="saveDetails(true,$event, 'abbName')">
                        <ng-container *ngIf="DeviceData.type === 'task'">
                            <input tabIndex=3 style="display: none;"
                                onfocus="document.getElementById('deviceName').focus()" class="form-control">
                        </ng-container>
                        <so-control-messages [name]="'Abbreviated Name'"
                            [control]="equipmentDetailsForm.controls.abbreviatedName" [fromContext]="true">
                        </so-control-messages>
                    </div>
                </li>
                <li *ngIf="DeviceData.type !== 'task'">
                    <div class="form-group">
                        <label>Part Number</label>
                        <input maxlength="80" type="text" class="form-control" placeholder="Part Number"
                            formControlName="partNumber" tabindex="3" (focus)="onFocus($event)"
                            (blur)="saveDetails(true,$event)">
                        <input tabIndex=4 style="display: none;" onfocus="document.getElementById('deviceName').focus()"
                            style="position: relative; left: -100000px">
                        <so-control-messages [name]="'Part Number'" [control]="equipmentDetailsForm.controls.partNumber"
                            [fromContext]="true">
                        </so-control-messages>
                    </div>
                </li>
            </ul>
        </form>
    </div>
</ng-container>