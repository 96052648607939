<ol class="align-self-center breadcrumb d-flex"
    [ngClass]="{'edit-plan-truncate-text':!action.edit && !action.save && totalDiscardCount > 0}">
    <li class="text-truncate flex-fill">
        <a class="back-btn" (click)="backClicked()" title="{{backButtonName}}">
            <em class="far fa-arrow-left"></em> {{backButtonName}}
        </a>
    </li>

    <li   class="text-truncate flex-fill" title="{{buildingName}}">
        <a href="javascript:void(0);" (click)="gotoLastVisitedPage()">{{buildingName}} </a>

    </li>

    <li class="text-truncate flex-fill">
        <ng-container *ngIf="buildingDropdownData && buildingDropdownData.length === 1">{{buildingDropdownData[0]['name']}}</ng-container>
        <select *ngIf="buildingDropdownData && buildingDropdownData.length > 1" [title]="buildingName" class="form-control dropdown-display text-truncate"
            (change)="buildingChange($event)">
            <option [selected]="buildingDP.id === buildingId" [ngValue]="buildingDP.id"
                *ngFor="let buildingDP of buildingDropdownData" title=" {{buildingDP?.name}}"
                [ngStyle]="{'color':buildingDP?.floors?.length === 0 ?'lightgray': ''}"
                [disabled]="buildingDP?.floors?.length === 0 ? true : false">
                {{buildingDP.name}}
            </option>
        </select>
    </li>
    <li class="text-truncate flex-fill">
        <ng-container *ngIf="floorList && floorList.length === 1">{{floorList[0]['name']}}</ng-container>
        <select *ngIf="floorList && floorList.length > 1" (click)="$event.stopPropagation();" [title]="floorName" class="form-control text-truncate" (change)="planChanged($event)">
            <option [selected]="plan.id === floorId" [ngValue]="plan.id" *ngFor="let plan of floorList"
                title=" {{plan?.name}}">
                <span> {{plan.name}}</span>
            </option>
        </select>
    </li>
</ol>