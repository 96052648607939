export const RemoveFlag = {
    dialogId: 'Remove Flag',
    header: 'Remove Flag',
    content: 'Are you sure you want to remove the flag from <strong class="text-break">{{#name}}</strong>? This will also clear the Flag Note.',
    btnSuccessText: 'Remove Flag',
    btnCancelText: 'Cancel',
    btnSuccessClass: 'btn-primary',
    btnCancelClass: 'btn-secondary',
    btnSuccessStyle: '',
    bodyClass: '',
    bodyStyle: '',
    dialogClass: '',
    dialogStyle: '',
}