<div class="equip-list">
    <div class="equip-title clearfix">
        <h4>
            <span>{{isProject ? 'Devices and Tasks' : 'Devices'}}</span>
        </h4>
    </div>
    <aside #asideElement class="equipment-sidebar" [id]="'favorites'" #autoscroll>
        <div class="form-group col devices-search d-flex align-items-center">
            <em class="far fa-search devices-search-icon"></em>
            <input class="form-control" type="text"
                [placeholder]="isProject ? 'Search device/task types' : 'Search device types'"
                (input)="filterCategories(false)" [formControl]="searchText">
            <em class="far fa-close devices-search-close" (click)="filterCategories(true)"></em>

        </div>
        <accordion [closeOthers]="true" id="equipment-device-list">
            <ng-container *ngFor="let list of (equipmentList) ;first as first; index as index">
                <ng-container
                    *ngIf="((favoriteEquipmentData | filterFavDeletedDevice:fromProject) | categoryFavoriteFilter : searchText.value ) as FavResult">
                    <accordion-group class="accordin" *ngIf="list.name === 'Favorites'"
                        (isOpenChange)="isOpenChange($event, -1)" [isOpen]="isOpenFavorite">
                        <div accordion-heading>
                            <div class="slide-toggle" title="Favorites">
                                Favorites <span [hidden]="searchText.value === '' || searchText.value === null">({{FavResult?.length}})
                                </span>
                                <em class="fas fa-star fav-icon ms-1" title="Favorites"></em>
                                <em *ngIf="permissions.canEdit || isCustomerAgent" class="far fa-cog fav-setting-btn" title="Configure Favorites" (click)="favImportExport($event)"></em>
                            </div>
                        </div>
                        <div role="tabpanel" aria-labelledby="headingOne">
                            <div #favEquipmentDetails class="equipment-details clearfix"
                                *ngIf="isFavAvailable; else favData" dragula="FAVORITES"
                                [dragulaModel]="(favoriteEquipmentData | filterFavDeletedDevice:fromProject) | orderBy: 'orderNumber'"
                                (dragulaModelChange)="onDragulaModelChanges($event)">
                                <div *ngFor="let item of FavResult | orderBy: 'orderNumber'" id="{{item.id}}"
                                    [ngStyle]="{'cursor': !(isEdit && (permissions.canEdit || permissions.canManage)) ? 'not-allowed': 'pointer'}"
                                    [ngClass]="{'active': selectedFavorite && selectedFavorite === item.index}"
                                    class="equip-items" (contextmenu)="contextClickFav($event,item)"
                                    soDraggableDevice  [isProject]="fromProject" [zoomFactor]="zoomFactor"
                                    [pinSize]="pinSize"
                                    (deviceUpdate)="updateDeviceLeftTop($event)" [pinColorStatus]="pinColorStatus"
                                    [isFav]="true" [isEdit]="isEdit && (permissions.canEdit || permissions.canManage)" [device]="item">
                                    <div>
                                        <so-context-menu *ngIf="(contextmenu && permissions.canManage && item?.index === selectedItemForRemove?.index)" (contextMenuClicked)="favAction($event,item)"    (optionSelected)="showEquipmentModalForFavorites(selectedItemForRemove);closeContextMenu()" [contextmenuX]="contextmenuX" [contextmenuY]="contextmenuY" [deleteFavoriteShow]="permissions.canManage" [isFromFav]="true">
                                        </so-context-menu>
                                        <div
                                            (click)="selectedFavorite = item.index; openEquipmentDetail(item, item.index)">
                                            <em [ngClass]="item.equipment.iconName"></em>
                                            <span class="text-Ellipsis"
                                                *ngIf="item.partNumber === null || item.partNumber === '';else partNumber"
                                                title="{{item.name || item.typeName}}">{{item.name ||
                                                item.typeName}}
                                            </span>
                                            <ng-template #partNumber>
                                                <span *ngIf="item.name !== null || item.name !== ''"
                                                    class="text-Ellipsis" title="{{item.name}}">{{item.name}}
                                                </span>
                                                <span *ngIf="item.type!=='task'" class="text-Ellipsis"
                                                    title="{{item.partNumber}}">{{item.partNumber}}
                                                </span>
                                            </ng-template>
                                            <span *ngIf="isEdit  && permissions.canManage" class="equipment-thumb-remove"
                                                title="Delete Favorite"
                                                (click)="$event.stopPropagation();showEquipmentModalForFavorites(item)">
                                                <em class="fas fa-trash-alt"></em>
                                            </span>
                                            <em *ngIf="item.equipment.isHub" class="far fa-network-wired icon-hub"
                                            [ngStyle]="{'top':childFavouriteEquipmentsCount(item) > 0 ? '22px':' '}"
                                                title="Connection Hub"></em>
                                            <span class="equipment-thumb-badge"
                                                *ngIf="childFavouriteEquipmentsCount(item) > 0">{{childFavouriteEquipmentsCount(item)}}</span>



                                            <span  *ngIf="isEdit && permissions.canManage"
                                                [hidden]="searchText.value !== '' && searchText.value !== null"
                                                id="dragReOrderFavorite" class="fav-reorder handle" title="Drag Favorite">
                                                <em class="fas fa-arrows handle" id="dragReOrderFavoriteEm"></em>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ng-template #favData>
                                <div class="equipment-details clearfix ">
                                    <p class="favorite-equipment ">Right-click a Device or click the star icon on a
                                        Device type to add to Favorites.</p>
                                </div>
                            </ng-template>
                        </div>
                    </accordion-group>
                </ng-container>
                <accordion-group class="accordin"
                    *ngIf="list.name !== 'Favorites' && (fromProject || (!fromProject && list.name !== 'Tasks'))"
                    (isOpenChange)="isOpenChange($event, index)" [isOpen]="(isOpenOther && selectedAccordin === index)">
                    <div accordion-heading>
                        <div class="slide-toggle " title="{{list.name}} ">
                            {{list.name | wrap:80}}
                        </div>
                        <div *ngIf="pinColorStatus === pinColorStatusType.DEVICECOLOR"
                            class="pin-color system-type-panel" [ngStyle]="{'background-color': list.pinColor}">
                        </div>
                    </div>
                    <div style="height:100%; width:100%">
                        <div role="tabpanel" aria-labelledby="headingOne ">
                            <div class="equipment-details clearfix ">
                                <div class="equip-items "  [ngStyle]="{'cursor': !(isEdit && (permissions.canEdit || permissions.canManage)) ? 'not-allowed': 'pointer'}" soDraggableDevice  [isProject]="fromProject" [zoomFactor]="zoomFactor"
                                    [pinSize]="pinSize" id="{{equipment.id}}"
                                    (deviceUpdate)="updateDeviceLeftTop($event)" [pinColorStatus]="pinColorStatus"
                                    [isNew]="true" [isEdit]="isEdit && (permissions.canEdit || permissions.canManage)" [device]="equipment"
                                    *ngFor="let equipment of list.equipments" (contextmenu)="contextClick($event,{ equipmentId : equipment.id, equipment:equipment, onSelectFrom:'normal' })">
                                    <em [ngClass]="equipment.iconName "></em>
                                    <span *ngIf="isEdit && (permissions.canEdit || permissions.canManage)" class="favorite-btn" title="Create Favorite"><em
                                            class="fas fa-star"
                                            (mousedown)="$event.stopPropagation();optionSelected({ equipmentId : equipment.id, equipment:equipment, onSelectFrom:'normal' },'list')"></em></span>
                                    <em *ngIf="equipment.isHub" class="far fa-network-wired icon-hub"
                                        title="Connection Hub"></em>
                                    <span>{{equipment.name}}</span>
                                    <so-context-menu *ngIf="(contextmenu && permissions.canManage && equipment?.id === selectedItem?.equipmentId)" (contextMenuClicked)="optionSelected({ equipmentId : equipment.id, equipment:equipment, onSelectFrom:'normal' },'list');closeContextMenu()" [contextmenuX]="contextmenuX" [contextmenuY]="contextmenuY" [createFavoriteShow]="permissions.canManage" [isFromFav]="true">
                                    </so-context-menu>
                                </div>
                            </div>
                        </div>
                    </div>
                </accordion-group>
            </ng-container>
        </accordion>
    </aside>
</div>
<so-equipment-details [isFavorite]="true" [categoryData]="defaultEquipmentList" *ngIf="equipmentDetails && equipmentDetails!==null"
    [favoriteEquipmentList]="favoriteEquipmentData" [favoriteEquipmentDetails]="equipmentDetails "
    [fromProject]="fromProject" [permissions]="permissions" [pinColorType]="pinColorStatus"
    (openEquipmentDetail)="openEquipmentDetailFromDetail($event)" (saveEquipmentDetails)="closeEquipmentDetail($event)"
    [isEdit]="isEdit" (showEquipmentModalForFavorites)="showEquipmentModalForFavoritesFromDetail($event)" (inputCalled)="inputCalled()">
</so-equipment-details>
<div *ngIf="showSpinner">
    <so-loader></so-loader>
</div>