import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'orderBy'
})
export class OrderbyPipe implements PipeTransform {

  transform(value: any[], propertyName: string): any[] {
    if (propertyName) {
      value = _.orderBy(value, [propertyName], ['asc']);
      return value;
    }
    else {
      value = _.orderBy(value, ['orderNumber'], ['asc']);
      return value;
    }
  }

}
