<div *ngIf="!resolveFlag" class="creat-new-modal" (click)="$event.stopPropagation()">
    <div class="modal-header">

        <h1 class="modal-title pull-left">Add Flag</h1>
        <button data-dismiss="modal" (click)="closeModal()" aria-label="Close" class="close pull-right" type="button">
            <em class="fas fa-close"></em>
        </button>


    </div>
    <div class="modal-body ">
        <p>Describe the issue with
            <strong class="text-break">{{deviceDetails?.name}}</strong>.
        </p>
        <ng-container *ngIf="addFlagForm">
            <form [formGroup]="addFlagForm" class="edit-new-modal" novalidate>
                <div class="col-sm-12 p-0">
                    <div class="form-group mb-0">
                        <label>Flag Note
                            <sup>*</sup>
                        </label>
                        <textarea maxlength="4000" type="text" class="form-control addflag-textarea mb-0"
                            id="addflagControl" formControlName="flagNote" placeholder="Flag Note"></textarea>
                        <so-control-messages [name]="'Flag Note'" [control]="$any(addFlagForm.controls['flagNote'])">
                        </so-control-messages>
                    </div>
                </div>
            </form>
        </ng-container>
    </div>
    <div class="modal-footer">
        <span>
            <button type="button" (click)="closeModal()" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
            <button type="submit" [disabled]="!addFlagForm.valid" (click)="submitFlag()" class="btn btn-danger m-0">Add
                Flag</button>

        </span>
    </div>
</div>

<div *ngIf="resolveFlag" class="creat-new-modal" (click)="$event.stopPropagation()">
    <div class="modal-header">

        <h1 class="modal-title pull-left">Resolve Flag</h1>
        <button data-dismiss="modal" (click)="closeModal()" aria-label="Close" class="close pull-right" type="button">
            <em class="fas fa-close"></em>
        </button>


    </div>
    <div class="modal-body ">
        <p>Describe the resolution for the flag on
            <strong class="text-break">{{deviceDetails?.name}}</strong>.
        </p>
        <p *ngIf="deviceDetails?.flagCreatedBy?.name!==null && isShowNofiy">
            This will notify <strong class="text-break">{{deviceDetails?.flagCreatedBy?.name}}</strong> of the resolution.
        </p>
        <ng-container *ngIf="addFlagForm">
            <form [formGroup]="addFlagForm" class="edit-new-modal" novalidate>
                <div class="col-sm-12 p-0">
                    <div class="form-group mb-0">
                        <label>Flag Resolution
                            <sup>*</sup>
                        </label>
                        <textarea maxlength="4000" type="text" class="form-control addflag-textarea mb-0"
                            id="addflagControl" formControlName="flagResolution" placeholder="Flag Resolution"></textarea>
                        <so-control-messages [name]="'Flag Resolution'" [control]="$any(addFlagForm.controls['flagResolution'])">
                        </so-control-messages>
                    </div>
                </div>
            </form>
        </ng-container>
    </div>
    <div class="modal-footer">
        <span>
            <button type="button" (click)="closeModal()" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
            <button type="submit" [disabled]="!addFlagForm.valid" (click)="submitFlag()" class="btn btn-primary m-0">Resolve
                Flag</button>

        </span>
    </div>
</div>