/* eslint-disable no-prototype-builtins */
import { AuthService, Environment, EventEmitterType, EventService, MessageService, Roles, Utilities, deviceFields, resetObjForFavoriteDevices } from '@SiteOwl/core';
import { Component, ElementRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { ngxCsv } from 'ngx-csv';
import moment from 'moment';
import { FloorPlanService } from '../../service/floor-plan.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { CommonPopupComponent } from '@SiteOwl/UI';
import { DeleteFavorites } from '../../constant/delete-favorites.constant';
import * as _ from "lodash";

@Component({
  selector: 'so-fav-import-export',
  templateUrl: './fav-import-export.component.html',
  styleUrls: ['./fav-import-export.component.scss'],
})
export class FavImportExportComponent implements OnInit {
  cdnUrl = this.env.cdnUrl;
  deviceFieldsLabel: any[] = [];
  favoriteDownloadData: any;
  fromProject: any;
  deviceFields: any;
  @ViewChild('fileUpload', { static: true }) fileUpload!: ElementRef;
  amtCsvFile: any;
  showSpinner!: boolean;
  private previewTrigger: EventEmitter<any> = new EventEmitter();
  siteFavCount: any = 0;
  projFavCount: any = 0;
  currentSiteFavCount = 0
  currentProjectFavCount = 0
  isCustomerAdmin = false;
  isGlobalAdmin = false;
  isShowDeleteFav = false;
  bsDeleteModalRef?: BsModalRef;
  favoriteEquipmentData: any;
  constructor(private readonly env: Environment,
    private authService: AuthService,
    private floorPlanService: FloorPlanService,
    private bsModalRef: BsModalRef,
    private deleteModalService: BsModalService,
    private eventService: EventService,
    private messageService: MessageService) { }
  ngOnInit(): void {
    this.isGlobalAdmin = this.authService.getUserKeyData().userRole === Roles.Administrator;
    this.isCustomerAdmin = this.authService.getUserKeyData().isAdmin;

    this.setDeviceHeader();
    this.favoriteDownloadData.forEach((item: any) => {
      if (!Utilities.isEmpty(item.id)) {
        if (item.categoryType !== 'task' && !this.fromProject) {
          this.siteFavCount++;
          this.projFavCount++;
        } else {
          this.projFavCount++;
        }
        if (item.childFavouriteEquipments) {
          item.childFavouriteEquipments.forEach((element1: any) => {
            if (element1.categoryType !== 'task' && !this.fromProject) {
              this.siteFavCount++;
              this.projFavCount++;
            } else {
              this.projFavCount++;
            }
          });
        }
      }
    });

    this.favoriteEquipmentData.forEach((item: any) => {
      if (!Utilities.isEmpty(item.id)) {
        if (item.categoryType !== 'task' && !this.fromProject) {
          this.currentSiteFavCount++;
          this.currentProjectFavCount++;
        } else {
          this.currentProjectFavCount++;
        }
        if (item.childFavouriteEquipments) {
          item.childFavouriteEquipments.forEach((element1: any) => {
            if (element1.categoryType !== 'task' && !this.fromProject) {
              this.currentSiteFavCount++;
              this.currentProjectFavCount++;
            } else {
              this.currentProjectFavCount++;
            }
          });
        }
      }
    });
    if (this.isGlobalAdmin || this.isCustomerAdmin) {
      if ((this.siteFavCount > 0 || this.currentSiteFavCount) || ((this.currentProjectFavCount || this.projFavCount > 0) && this.fromProject)) {
        this.isShowDeleteFav = true;
        this.bsModalRef.setClass('modal-xxxl modal-80');
      }
    }
  }
  exportFavorite() {
    const date = new Date();
    const CSVFileData: any = [];
    const userKey = this.authService.getUserKeyData();
    const FileName = "SiteOwl-Favorite-Devices-" + userKey.customerName + "-" + moment(date).format('HHmmss');
    const newFileName = FileName.replace(/[^a-zA-Z0-9-_ \.]/g, '');
    this.favoriteDownloadData.forEach((item: any) => {
      const data: any = {}
      if (!Utilities.isEmpty(item.id)) {
        data['favoriteID'] = item.id;
        this.deviceFields.forEach((element: any) => {

          if (element.key !== 'parentName') {
            data[element.key] = Utilities.isNull(item[element.key]) ? '' : item[element.key];
          } else {
            data['primaryFavoriteID'] = '';
            data['primaryFavoriteName'] = '';
          }
        });
        if (item.categoryType === 'task') {
          if (this.fromProject) {
            CSVFileData.push(data);
          }
        } else {
          CSVFileData.push(data);
        }
        if (item.childFavouriteEquipments) {
          item.childFavouriteEquipments.forEach((element1: any) => {
            const data1: any = {};
            if (!Utilities.isEmpty(element1.id)) {
              data1['favoriteID'] = element1.id;
              this.deviceFields.forEach((element: any) => {
                if (element.key !== 'parentName') {
                  data1[element.key] = Utilities.isNull(element1[element.key]) ? '' : element1[element.key];
                } else {
                  data1['primaryFavoriteID'] = item.id;
                  data1['primaryFavoriteName'] = item.name;
                }
              });

              if (element1.categoryType === 'task') {
                if (this.fromProject) {
                  CSVFileData.push(data1);
                }
              } else {
                CSVFileData.push(data1);
              }
            }
          });
        }
      }
    });
    if (CSVFileData.length > 0) {
      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true,
        showTitle: false,
        headers: this.getLabelList()
      };
      new ngxCsv(CSVFileData, newFileName, options);
    } else {
      this.messageService.errorMessage('', 'No data to export');
    }
  }
  getLabelList() {
    this.deviceFieldsLabel = ['Favorite ID'];
    this.deviceFields.forEach((x: any) => {
      if (x.key === 'parentName') {
        this.deviceFieldsLabel.push("Primary Favorite ID");
        this.deviceFieldsLabel.push("Primary Favorite Name");
      } else {
        this.deviceFieldsLabel.push(x.label);
      }
    })
    return this.deviceFieldsLabel
  }
  importFavorite() {
    this.fileUpload.nativeElement.click();
  }
  onchange(event: any) {
    const target = event.target || event.srcElement;
    const files = target['files'];
    if (files.length > 0) {
      const file = files[0];
      const fileExtension = '.' + file.name.split('.')[file.name.split('.').length - 1].toLowerCase();
      if (fileExtension === '.csv') {
        // this.isPreviewButtonShow = true;
        this.amtCsvFile = file;
        this.uploadCSV();
      } else {
        this.amtCsvFile = null;
        this.messageService.errorMessage('Upload', 'Invalid file');
      }

    }
    target['value'] = ''
  }
  uploadCSV() {
    this.showSpinner = true;
    if (!Utilities.isNull(this.amtCsvFile)) {
      this.floorPlanService.uploadCsvFavorite(this.amtCsvFile, true, this.fromProject).subscribe((previewResponse: any) => {
        if (previewResponse.errorMessage) {
          this.messageService.errorMessage('Error: Invalid File', previewResponse.errorMessage);
          this.showSpinner = false;
        } else {
          this.previewTrigger.emit({ previewResponse: previewResponse, file: this.amtCsvFile });
          this.showSpinner = false;
        }
      }, (error: any) => {
        if (error.status === 422) {
          this.messageService.errorMessage('Upload CSV', error.error.message);
        } else {
          this.messageService.errorMessage('Upload CSV', error.message);
        }
        this.showSpinner = false;
      });
    }
  }
  closeModal() {
    this.bsModalRef.hide();
  }
  setDeviceHeader() {
    const arr: any = [];
    let item: any = null;
    if (this.fromProject) {
      item = this.splitString(resetObjForFavoriteDevices.projectFavValue);
    } else {
      item = this.splitString(resetObjForFavoriteDevices.liveFavValue);
    }
    deviceFields.forEach(x => {
      if (item.hasOwnProperty(x.key)) {
        arr.push(x);
      }
    })
    this.deviceFields = arr;
  }
  private splitString(devicesColumnString: any) {
    const re = /\|/;
    const list = devicesColumnString.split(re);
    const obj: any = {};
    list.forEach((item: any) => {
      obj[item] = item;
    })
    return obj;
  }

  deleteFavorites() {
    this.bsModalRef.setClass('w-1024 d-none');
    const dataReplacer = {
      '{{#customerName}}': _.escape(this.authService.getUserKeyData().customerName)
    }
    const modalData: any = Utilities.contentReplacer(dataReplacer, _.cloneDeep(DeleteFavorites));
    const initialState: ModalOptions = {
      initialState: modalData, class: '', ignoreBackdropClick: true
    };
    this.bsDeleteModalRef = this.deleteModalService.show(CommonPopupComponent, initialState);
    this.bsDeleteModalRef.content.event.subscribe((res: any) => {
      if (res !== 'Cancel') {
        if ((this.currentSiteFavCount > 0 || this.siteFavCount > 0) || ((this.projFavCount > 0 || this.currentProjectFavCount > 0) && this.fromProject)) {
          this.exportAllFavorite();
          this.floorPlanService.deleteAllFavoritesOfCustomer().subscribe((r: any) => {
            this.eventService.broadcast(EventEmitterType.deleteAllFavorites, {})
            this.messageService.successMessage('Favorite Details', r.message);
          }, (e: any) => {
            this.messageService.successMessage('Favorite Details', e.message);
          })
        }
        this.bsModalRef.hide();
      } else {
        this.bsModalRef.hide();
      }
    })
  }

  exportAllFavorite() {
    const date = new Date();
    const CSVFileData: any = [];
    const userKey = this.authService.getUserKeyData();
    const FileName = "SiteOwl-Favorite-Devices-" + userKey.customerName + "-" + moment(date).format('HHmmss');
    const newFileName = FileName.replace(/[^a-zA-Z0-9-_ \.]/g, '');
    this.favoriteDownloadData.forEach((item: any) => {
      const data: any = {}
      if (!Utilities.isEmpty(item.id)) {
        data['favoriteID'] = 'deleted-' + item.id;
        this.deviceFields.forEach((element: any) => {

          if (element.key !== 'parentName') {
            data[element.key] = Utilities.isNull(item[element.key]) ? '' : item[element.key];
          } else {
            data['primaryFavoriteID'] = '';
            data['primaryFavoriteName'] = '';
          }
        });
        CSVFileData.push(data);
        if (item.childFavouriteEquipments) {
          item.childFavouriteEquipments.forEach((element1: any) => {
            const childData: any = {};
            if (!Utilities.isEmpty(element1.id)) {
              childData['favoriteID'] = 'deleted-' + element1.id;
              this.deviceFields.forEach((element: any) => {
                if (element.key !== 'parentName') {
                  childData[element.key] = Utilities.isNull(element1[element.key]) ? '' : element1[element.key];
                } else {
                  childData['primaryFavoriteID'] = 'deleted-' + item.id;
                  childData['primaryFavoriteName'] = item.name;
                }
              });
              CSVFileData.push(childData);
            }
          });
        }
      }
    });
    if (CSVFileData.length > 0) {
      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true,
        showTitle: false,
        headers: this.getLabelList()
      };
      new ngxCsv(CSVFileData, newFileName, options);
    }
  }
}
