export const UnarchiveDevice = {
    dialogId: 'Unarchive Device',
    header: 'Unarchive Device',
    content: 'The Device <strong>{{#name}}</strong> is a secondary Device to <strong>{{#parentName}}</strong>. Do you want to unarchive both <strong>{{#name}}</strong> and <strong>{{#parentName}}</strong>?',
    btnSuccessText: 'Unarchive Device',
    btnCancelText: 'Cancel',
    btnSuccessClass: 'btn-primary',
    btnCancelClass: 'btn-secondary',
    btnSuccessStyle: '',
    bodyClass: '',
    bodyStyle: '',
    dialogClass: '',
    dialogStyle: '',
}