import { ButtonEnableDisableService, MessageService, TicketService } from '@SiteOwl/core';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';

@Component({
  selector: 'so-floor-link-ticket',
  templateUrl: './floor-link-ticket.component.html',
  styleUrls: ['./floor-link-ticket.component.scss'],
})
export class FloorLinkTicketComponent implements OnInit {
  unLinkedTickets: any;
  equipmentId: any;
  equipmentDetails: any;
  buildingDetails: any;
  floorDetails: any;
  categoryData: any;
  opentickets: any;
  showClosedTicked!: boolean;

  public event: EventEmitter<any> = new EventEmitter();

  constructor(private messageService: MessageService, public btnService: ButtonEnableDisableService,
    private ticketService: TicketService,
    public bsModalRef: BsModalRef,) { }

  ngOnInit(): void {
    this.unLinkedTickets = _.sortBy(this.unLinkedTickets, [function (a) {
      return a.subject.toLowerCase();
    }]);
    this.btnService.enable();
    try {
      this.opentickets = this.unLinkedTickets.filter((x: any) => x.status !== 'Closed')
    } catch (e) { }
  }
  linkTicket(ticket: any) {
    this.btnService.disable();
    const ticketInfo = {
      serviceTicketId: ticket.id,
      floorEquipmentId: this.equipmentId,
    }
    if (!ticket.floorEquipmentIds) {
      ticket.floorEquipmentIds = [];
    }
    this.ticketService.linkTicket(ticketInfo).subscribe({
      next: (result: any) => {
        ticket.floorEquipmentIds.push(this.equipmentId)
        this.messageService.successMessage(`Service Ticket: ${ticket.subject}`, 'Linked SuccessFully');

        this.event.emit({ ticket: result.serviceTicket });
        this.bsModalRef.hide();
        // this.dialog.close({ ticket: result.serviceTicket, newTicket: false });
      }, error: (error: any) => {
        if (error.status === 409 || error.status === 422) {
          this.messageService.errorMessage(`Notification`, error.error.message);
        }
      }
    });
  }
  createNewTicket() {
    this.event.emit({ ticket: null, newTicket: true });
    this.bsModalRef.hide();
  }
  toggleTickets() {
    this.showClosedTicked = !this.showClosedTicked;
  }
  closeModal() {
    this.bsModalRef.hide();
  }
}
