import { Utilities } from '@SiteOwl/core';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'so-equipment-details-label',
  templateUrl: './equipment-details-label.component.html',
  styleUrls: ['./equipment-details-label.component.scss'],
})
export class EquipmentDetailsLabelComponent implements OnInit {

  @Input() fromProject: any;
  @Input() equipmentDetails: any;
  @Input() isLiveSystemAccessForCustomer: any;
  @Input() buildingDetails: any;
  @Input() publishedTooltipMessageText: any;

  constructor(private router: Router,
              private route: ActivatedRoute) {}
  ngOnInit(): void {
    if (this.equipmentDetails && this.equipmentDetails.archivedStatus === 'Plan Archived') {
      this.publishedTooltipMessageText = 'Device is Plan Archived'
    }
  }
  goToRefrenceDevice() {
    let isSiteArchived = false
    this.route.queryParams.subscribe((params: any) => {
      isSiteArchived = params['isArchived'];
    })
    if (!isSiteArchived) {
      if (!this.fromProject &&
        (!Utilities.isNull(this.equipmentDetails.referenceFloorEquipmentId && this.equipmentDetails.referenceProjectId)) && this.isLiveSystemAccessForCustomer) {
        this.router.navigate([`/secure/project/${this.equipmentDetails.referenceProjectId}/building/${this.equipmentDetails.buildingId}/floor/${this.equipmentDetails.floorId}/details`], {
          queryParams: {
            floorid: this.equipmentDetails.floorId,
            equipmentid: this.equipmentDetails.referenceFloorEquipmentId,
            site: this.equipmentDetails.siteId,
            comp: 'project'
          }
        });
      } else if (!Utilities.isNull(this.equipmentDetails.referenceFloorEquipmentId) && this.equipmentDetails.canAccessReference && this.isLiveSystemAccessForCustomer) {
        this.redirectToLiveSystem();
      } else if (this.fromProject && !Utilities.isNull(this.equipmentDetails.referenceFloorEquipmentId) && this.equipmentDetails.referenceFloorEquipmentId && this.isLiveSystemAccessForCustomer) {
        this.redirectToLiveSystem();
      }
    }
  }
  redirectToLiveSystem() {
    this.router.navigate([`/secure/site/${this.equipmentDetails.siteId}/building/${this.equipmentDetails.buildingId}/floor/${this.equipmentDetails.floorId}/details`], {
      queryParams: {
        floorid: this.equipmentDetails.floorId,
        equipmentid: this.equipmentDetails.referenceFloorEquipmentId,
        project: this.equipmentDetails.projectId,
        comp: 'site'
      }
    });
  }
}
