/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthService, EventEmitterType, EventService, Utilities } from '@SiteOwl/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FloorPlanService } from '../../service/floor-plan.service';

@Component({
  selector: 'so-redirection',
  templateUrl: './redirection.component.html',
  styleUrls: ['./redirection.component.scss'],
})
export class RedirectionComponent implements OnInit {
  showLoader = true;

  constructor(private route: ActivatedRoute, private router: Router, private activatedRoute: ActivatedRoute
    , private authService: AuthService, private floorPlanService: FloorPlanService,
    private eventService: EventService) { }
  ngOnInit(): void {
    let siteId: any = 0;
    let projectId: any = 0;
    let buildingId: any = 0;
    let floorId: any = 0;
    if (localStorage.getItem("isSaveChangeRemains") && localStorage.getItem("isSaveChangeRemains") == "true") {
      return;
    }
    if (this.activatedRoute.snapshot.params) {
      if (this.activatedRoute.snapshot.params['siteId']) {
        siteId = parseInt(this.activatedRoute.snapshot.params['siteId']);
      }
      if (this.activatedRoute.snapshot.params['projectId']) {
        projectId = parseInt(this.activatedRoute.snapshot.params['projectId']);
      }
      buildingId = parseInt(this.activatedRoute.snapshot.params['buildingId']);
      floorId = parseInt(this.activatedRoute.snapshot.params['floorId']);
    }
    const urlParams: any = Utilities.getActiveURLQueryParams();
    const newCustomerId: any = parseInt(urlParams.get('customerId'));
    const deviceId: any = parseInt(urlParams.get('equipmentid'));
    if (newCustomerId == undefined || newCustomerId == "" || newCustomerId === null || isNaN(newCustomerId)) {
      this.showLoader = false;
      this.router.navigate(['/secure/invalid-page'], { queryParams: { selection: 'noCustomerFound' } });
    } else if (projectId < 0 && (siteId === 0 || siteId === undefined || siteId == "" || siteId === null || isNaN(siteId))) {
      this.showLoader = false;
      this.router.navigate(['/secure/invalid-page'], { queryParams: { selection: 'inValidFloorPlan' } });
    } else if (siteId < 0 && (projectId === 0 || projectId == undefined || projectId == "" || projectId === null || isNaN(projectId))) {
      this.showLoader = false;
      this.router.navigate(['/secure/invalid-page'], { queryParams: { selection: 'inValidProjectLink' } });
    } else if (buildingId === 0 || buildingId == "" || buildingId === null || buildingId === undefined || isNaN(buildingId) ||
      floorId === 0 || floorId == "" || floorId === null || floorId === undefined || isNaN(floorId)) {
      this.showLoader = false;
      this.router.navigate(['/secure/invalid-page'], { queryParams: { selection: 'inValidFloorPlan' } });
    } else if (deviceId === 0 || deviceId == "" || deviceId === null || deviceId === undefined || isNaN(deviceId)) {
      this.showLoader = false;
      this.router.navigate(['/secure/invalid-page'], { queryParams: { selection: 'inValidDeviceLink' } });
    } else {

      const customer: any = {
        id: newCustomerId,
        buildingId: buildingId,
        floorId: floorId,
        projectId: projectId,
        notRedirect: true,
      }
      if (siteId > 0) {
        this.floorPlanService.getAccessForSite(siteId, buildingId, floorId, deviceId, newCustomerId).subscribe((r: any) => {
          if (r && !r.isCustomerActive && r.isCustomerActive !== undefined) {
            this.showLoader = false;
            this.router.navigate(['/secure/invalid-page'], { queryParams: { selection: 'noCustomerFound' } });
          } else if (r && !r.isCustomerFound && r.isCustomerFound !== undefined) {
            this.showLoader = false;
            this.router.navigate(['/secure/invalid-page'], { queryParams: { selection: 'noAccessCustomer' } });
          } else if (r && !r.isFloorExist && r.isFloorExist !== undefined) {
            this.showLoader = false;
            this.router.navigate(['/secure/invalid-page'], { queryParams: { selection: 'inValidFloorPlan' } });
          } else if (r && !r.isFloorEquipmentExist && r.isFloorEquipmentExist !== undefined) {
            this.showLoader = false;
            this.router.navigate(['/secure/invalid-page'], { queryParams: { selection: 'inValidDeviceLink' } });
          } else if (r && !r.isFloorEquipmentAccess && r.isFloorEquipmentAccess !== undefined) {
            this.showLoader = false;
            this.router.navigate(['/secure/invalid-page'], { queryParams: { selection: 'noAccessToDevice' } });
          } else {
            this.authService.setCustomerId({ customerId: newCustomerId, user: this.authService.getUserKeyData().user.email });
            this.eventService.broadcast(EventEmitterType.SwitchCustomer, customer)
            const timeOut = setTimeout(() => {
              this.showLoader = false;
              this.siteRedirect(siteId, buildingId, floorId, deviceId);
              clearTimeout(timeOut);
            }, 0);
          }
        })
      } else if (projectId > 0) {
        this.floorPlanService.getAccessForProject(projectId, buildingId, floorId, deviceId, newCustomerId).subscribe((r: any) => {
          if (r && !r.isCustomerActive && r.isCustomerActive !== undefined) {
            this.showLoader = false;
            this.router.navigate(['/secure/invalid-page'], { queryParams: { selection: 'noCustomerFound' } });
          } else if (r && !r.isCustomerFound && r.isCustomerFound !== undefined) {
            this.showLoader = false;
            this.router.navigate(['/secure/invalid-page'], { queryParams: { selection: 'noAccessCustomer' } });
          } else if (r && !r.isProjectExist && r.isProjectExist !== undefined) {
            this.showLoader = false;
            this.router.navigate(['/secure/invalid-page'], { queryParams: { selection: 'inValidProjectLink' } });
          } else if (r && !r.isFloorExist && r.isFloorExist !== undefined) {
            this.showLoader = false;
            this.router.navigate(['/secure/invalid-page'], { queryParams: { selection: 'inValidFloorPlan' } });
          } else if (r && !r.isFloorEquipmentExist && r.isFloorEquipmentExist !== undefined) {
            this.showLoader = false;
            this.router.navigate(['/secure/invalid-page'], { queryParams: { selection: 'inValidDeviceLink' } });
          } else if (r && !r.isFloorEquipmentAccess && r.isFloorEquipmentAccess !== undefined) {
            this.showLoader = false;
            this.router.navigate(['/secure/invalid-page'], { queryParams: { selection: 'noAccessToProject' } });
          } else {
            // this.authService.setCustomerId({ customerId: newCustomerId, user: this.authService.getUserKeyData().user.email });
            this.eventService.broadcast(EventEmitterType.SwitchCustomer, customer)
            const timeOut = setTimeout(() => {
              this.showLoader = false;
              this.projectRedirect(projectId, buildingId, floorId, deviceId);
              clearTimeout(timeOut)
            }, 0);
          }
        }, e => {
          console.log('error====', e)
        });
      }
    }
  }

  siteRedirect(siteId: any, buildingId: any, floorId: any, deviceId: any) {
    this.router.navigate(['/secure/site/' + siteId + '/building/' + buildingId + '/floor/' + floorId + '/details'], {
      queryParams: {
        equipmentid: parseInt(deviceId)
      }, relativeTo: this.route
    });
  }

  projectRedirect(projectId: any, buildingId: any, floorId: any, deviceId: any) {
    this.router.navigate(['/secure/project/' + projectId + '/building/' + buildingId + '/floor/' + floorId + '/details'], {
      queryParams: {
        equipmentid: parseInt(deviceId),
      }, relativeTo: this.route
    });
  }
}
