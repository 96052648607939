export const DeleteFav = {
    dialogId: 'Delete-Favorite',
    header: 'Delete Favorite',
    content: 'Are you sure you want to delete <strong> {{#equipmentName}}? </strong>',
    btnSuccessText: 'Delete Favorite',
    btnCancelText: 'Cancel',
    btnSuccessClass: 'btn-danger',
    btnCancelClass: 'btn-secondary',
    btnSuccessStyle: '',
    bodyClass: '',
    bodyStyle: '',
    dialogClass: '',
    dialogStyle: '',
}