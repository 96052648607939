/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'history',
  pure: false
})
export class HistoryPipe implements PipeTransform {
  transform(items: any[], filter: any): any[] {
    if (!filter) {
      items = items.filter(x => x.updatedBy !== 'System');
      return items;
    }
    return items;
  }
}
