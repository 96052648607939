import { Category, DeviceTypes, EquipmentStatusForDevice, EquipmentType, FloorEquipment, InstallStatusFilter, OperationalStatusFilter, PinColorStatus, PinColorStatusType, PrintOutType, ProviderRole, StaticDeviceColor, TicketStatuses, Utilities, ViewingPreferences, ViewingReportPreferences, liveSystemRoles, projectRoles } from "@SiteOwl/core";
import * as _ from 'lodash';
import { CoverageCone } from "../utils/coverage-cone";

/* eslint-disable @typescript-eslint/no-explicit-any */
export class FloorUtils {

    static PLAN_ARCHIVED = "Plan Archived";


    static setClassForPulseAnimationStatus(item: any, isProject: any, dragHoldData: any): any {
        let archive: any;
        if (isProject) {
            archive = item && !item.isPublishedToAMT && (item.isArchived || item.archivedStatus === EquipmentStatusForDevice.PLANARCHIVED);
        } else {
            archive = item && (item.isArchived || item.archivedStatus === EquipmentStatusForDevice.PLANARCHIVED);
        }
        return {
            'pulse-archive': archive,
            'pulse-operational': ((!isProject && item.status === EquipmentStatusForDevice.OPERATIONAL) ||
                (isProject && item.installStatus === EquipmentStatusForDevice.INSTALLED)) && (!dragHoldData || (dragHoldData && !dragHoldData.all.isSelectedDevice)),
            'pulse-operationalwith': ((!isProject && item.status === EquipmentStatusForDevice.OPERATIONALWITHISSUE) ||
                (isProject && item.installStatus === EquipmentStatusForDevice.INPROGRESS)) && (!dragHoldData || (dragHoldData && !dragHoldData.all.isSelectedDevice)),
            'pulse-notworking': ((!isProject && item.status === EquipmentStatusForDevice.NOTWORKING) ||
                (isProject && item.installStatus === EquipmentStatusForDevice.PLANNED)) && (!dragHoldData || (dragHoldData && !dragHoldData.all.isSelectedDevice)),
            'pulse-hold': dragHoldData && dragHoldData.all.isSelectedDevice,
            'pulse-site-device': isProject && item.projectId === null
        }
    }

    static displayBorderColor(obj: any, item: any, showPinColor: any, isProject: boolean) {
        if (showPinColor === PinColorStatus.DEVICECOLOR && item && !item.isDeleted && !item.isArchived) {
            obj.displayBorderColor = item.pinColor
        } else if (item && !item.isNew && showPinColor === PinColorStatus.OPERATIONALSTATUS && !item.isDeleted && !item.isArchived) {
            if (item.type === EquipmentType.DEVICE) {
                obj.displayBorderColor = StaticDeviceColor.filter(x => x.text === item.status)[0].colorCode;
            } else {
                obj.displayBorderColor = StaticDeviceColor.filter(x => (x.text === item.installStatus || x.display === item.installStatus))[0].colorCode;
            }
        } else if (item && isProject && !item.isNew && showPinColor === PinColorStatus.INSTALLSTATUS && !item.isDeleted && !item.isArchived) {
            obj.displayBorderColor = StaticDeviceColor.filter(x => (x.text === item.installStatus || x.display === item.installStatus))[0].colorCode;
        } else if (item && item.isNew && (showPinColor === PinColorStatus.OPERATIONALSTATUS || showPinColor === PinColorStatus.INSTALLSTATUS)) {
            if (isProject) {
                if (item.type === EquipmentType.DEVICE) {
                    if (showPinColor === PinColorStatus.OPERATIONALSTATUS) {
                        obj.displayBorderColor = StaticDeviceColor.filter(x => x.text === item.status)[0].colorCode;
                    } else {
                        obj.displayBorderColor = StaticDeviceColor.filter(x => (x.text === item.installStatus || x.display === item.installStatus))[0].colorCode;
                    }
                } else {
                    obj.displayBorderColor = StaticDeviceColor.filter(x => (x.text === item.installStatus || x.display === item.installStatus))[0].colorCode;
                }
            } else {
                obj.displayBorderColor = StaticDeviceColor.filter(x => x.text === item.status)[0].colorCode;
            }
        }
        return obj.displayBorderColor;
    }

    static displayPinColor(obj: any, item: any, showPinColor: any, isProject: boolean, isProjectArchived = false, isSiteArchived = false) {
        if ((isProjectArchived && !item.isPublishedToAMT) || isSiteArchived) {
            obj.displayPinColor = 'rgba(68, 68, 68)';
        } else if (item && showPinColor === PinColorStatus.DEVICECOLOR && !item.isDeleted && !(item.isArchived || (item.archivedStatus === EquipmentStatusForDevice.PLANARCHIVED && item.type === EquipmentType.TASK))) {
            obj.displayPinColor = 'rgba(' + this.hexToRgbA(item.pinColor) + ', var(--alpha))'
        } else if (item.type === EquipmentType.DEVICE && showPinColor === PinColorStatus.OPERATIONALSTATUS && !item.isNew && !item.isDeleted && !item.isArchived) {
            obj.displayPinColor = 'rgba(' + this.hexToRgbA(StaticDeviceColor.filter(x => x.text === item.status)[0].colorCode) + ', var(--alpha))';
        } else if (isProject && item.type === EquipmentType.TASK && showPinColor === PinColorStatus.INSTALLSTATUS && !item.isNew && !item.isDeleted && !(item.isArchived || item.archivedStatus === EquipmentStatusForDevice.PLANARCHIVED)) {
            obj.displayPinColor = 'rgba(' + this.hexToRgbA(StaticDeviceColor.filter(x => (x.text === item.installStatus || x.display === item.installStatus))[0].colorCode) + ', var(--alpha))';
        } else if (isProject && item.type === EquipmentType.TASK && !item.isDeleted && (item.isArchived || item.archivedStatus === EquipmentStatusForDevice.PLANARCHIVED)) {
            obj.displayPinColor = 'rgba(68, 68, 68, 0.8)';
        } else if (isProject && item.type === EquipmentType.DEVICE && showPinColor === PinColorStatus.INSTALLSTATUS && !item.isNew && !item.isDeleted && !item.isArchived) {
            obj.displayPinColor = 'rgba(' + this.hexToRgbA(StaticDeviceColor.filter(x => (x.text === item.installStatus || x.display === item.installStatus))[0].colorCode) + ', var(--alpha))';
        } else if (item.isNew && (showPinColor === PinColorStatus.INSTALLSTATUS || showPinColor === PinColorStatus.OPERATIONALSTATUS)) {
            if (isProject) {
                if (showPinColor === PinColorStatus.INSTALLSTATUS) {
                    obj.displayPinColor = 'rgba(' + this.hexToRgbA(StaticDeviceColor.filter(x => (x.text === item.installStatus || x.display === item.installStatus))[0].colorCode) + ', var(--alpha))';
                } else if (showPinColor === PinColorStatus.OPERATIONALSTATUS) {
                    if (item.type === EquipmentType.DEVICE) {
                        obj.displayPinColor = 'rgba(' + this.hexToRgbA(StaticDeviceColor.filter(x => x.text === item.status)[0].colorCode) + ', var(--alpha))';
                    } else {
                        obj.displayPinColor = 'rgba(' + this.hexToRgbA(StaticDeviceColor.filter(x => (x.text === item.installStatus || x.display === item.installStatus))[0].colorCode) + ', var(--alpha))';
                    }
                }
            } else {
                obj.displayPinColor = 'rgba(' + this.hexToRgbA(StaticDeviceColor.filter(x => x.text === item.status)[0].colorCode) + ', var(--alpha))';
            }
        }
        return obj.displayPinColor;
    }

    static displayCoverageConeColor(obj: any, item: any, showPinColor: any, isProject: boolean, isProjectArchived = false, isSiteArchived = false) {
        if ((isProjectArchived && !item.isPublishedToAMT) || isSiteArchived) {
            obj.displayCoverageConeColor = 'rgba(68, 68, 68)'
        } else if (isProject && !item.isPublishedToAMT && !item.isArchived && item.projectId === null) {
            obj.displayCoverageConeColor = 'rgba(0, 54, 74)'
        } else if (showPinColor === PinColorStatus.DEVICECOLOR && !item.isArchived) {
            if (item && !item.isDeleted && !((item.isArchived && !item.isPublishedToAMT) || (item.archivedStatus === EquipmentStatusForDevice.PLANARCHIVED && !item.isPublishedToAMT))) {
                obj.displayCoverageConeColor = item.pinColor
            } else {
                obj.displayCoverageConeColor = 'rgba(68, 68, 68)'
            }
        } else if (item.type === EquipmentType.DEVICE && showPinColor === PinColorStatus.OPERATIONALSTATUS && !item.isNew && !item.isDeleted && !(item.isArchived && !item.isPublishedToAMT)) {
            obj.displayCoverageConeColor = StaticDeviceColor.filter(x => x.text === item.status)[0].colorCode;
        } else if (isProject && item.type === EquipmentType.DEVICE && showPinColor === PinColorStatus.INSTALLSTATUS && !item.isNew && !item.isDeleted && !(item.isArchived && !item.isPublishedToAMT)) {
            obj.displayCoverageConeColor = StaticDeviceColor.filter(x => (x.text === item.installStatus || x.display === item.installStatus))[0].colorCode;
        } else if (item.isNew && (showPinColor === PinColorStatus.OPERATIONALSTATUS || showPinColor === PinColorStatus.INSTALLSTATUS)) {
            if (isProject) {
                obj.displayCoverageConeColor = StaticDeviceColor.filter(x => (x.text === item.installStatus || x.display === item.installStatus))[0].colorCode;
                if (item.type === EquipmentType.DEVICE && showPinColor === PinColorStatus.OPERATIONALSTATUS) {
                    obj.displayCoverageConeColor = StaticDeviceColor.filter(x => x.text === item.status)[0].colorCode;
                }
            } else {
                obj.displayCoverageConeColor = StaticDeviceColor.filter(x => x.text === item.status)[0].colorCode;
            }
        } else if (item.isArchived && !item.isPublishedToAMT){
            obj.displayCoverageConeColor = 'rgba(68, 68, 68)'
        }
        return obj.displayCoverageConeColor;
    }

    static displayPulse(obj: any, item: any, showPinColor: any, isProject: boolean) {
        if (isProject && !item.isPublishedToAMT && !item.isArchived && item.projectId === null) {
            obj.displayPulse = '0 0 1px 4px #003F77'
        }
        if (showPinColor === PinColorStatus.DEVICECOLOR && !item.isDeleted && !(item.isArchived || (item.archivedStatus === EquipmentStatusForDevice.PLANARCHIVED && item.type === EquipmentType.TASK))) {
            obj.displayPulse = '0 0 1px 4px' + item.pinColor
        } else if (item.type === EquipmentType.DEVICE && showPinColor === PinColorStatus.OPERATIONALSTATUS && !item.isDeleted && !item.isArchived) {
            if (item.status === EquipmentStatusForDevice.OPERATIONAL) { //Operational
                obj.displayPulse = '0 0 1px 4px #00B2F8'
            } else if (item.status === EquipmentStatusForDevice.OPERATIONALWITHISSUE) { //OperationalWithIssue
                obj.displayPulse = '0 0 1px 4px #E8BC0A'
            } else if (item.status === EquipmentStatusForDevice.NOTWORKING) { //Not Working
                obj.displayPulse = '0 0 1px 4px #DA2955'
            }
        } else if (isProject && showPinColor === PinColorStatus.INSTALLSTATUS && !item.isDeleted && !(item.isArchived || (item.archivedStatus === EquipmentStatusForDevice.PLANARCHIVED && item.type === EquipmentType.TASK))) {
            if (item.installStatus === EquipmentStatusForDevice.INSTALLED) { //Installed
                obj.displayPulse = '0 0 1px 4px #00B2F8'
            } else if (item.installStatus === EquipmentStatusForDevice.INPROGRESS) { //In Progress
                obj.displayPulse = '0 0 1px 4px #E8BC0A'
            } else if (item.installStatus === EquipmentStatusForDevice.PLANNED) { //Planned
                obj.displayPulse = '0 0 1px 4px #DA2955'
            }
        }
        return obj.displayPulse;
    }

    static equipmentDisplayObjects(item: any, showPinColor: any, isProject: any, showArchived = false, filter: any, assignedToFilter: any, isSelectedDevice = false, isProjectArchived = false, isSiteArchived = false) {
        const obj: any = {
            displayBorderColor: '',
            displayPinColor: '',
            displayCoverageConeColor: '',
            displayPulse: '',
            isImage: false,
            secondaryDeviceCount: 0,
            badgeColor: '',
            applyClass: '',
            ticketCustomClass: '',
            classForSecondary: {},
            tooltipClass: {},
            showTicketIcon: false,
            deviceOpacity: ''
        }

        // displayBorderColor
        obj.displayBorderColor = this.displayBorderColor(obj, item, showPinColor, isProject);

        // displayPinColor
        obj.displayPinColor = this.displayPinColor(obj, item, showPinColor, isProject, isProjectArchived, isSiteArchived);

        // displayCoverageConeColor
        obj.displayCoverageConeColor = this.displayCoverageConeColor(obj, item, showPinColor, isProject, isProjectArchived, isSiteArchived);

        // displayPulse
        obj.displayPulse = this.displayPulse(obj, item, showPinColor, isProject);

        //check image is present in device or not
        obj.isImage = this.checkImages(item);

        obj.applyClass = this.applyClass(item, isProject, showPinColor, assignedToFilter, filter);
        obj.deviceOpacity = this.getOpacityForDevice(item, isProject, showPinColor, assignedToFilter, filter)

        obj.ticketCustomClass = item.serviceTickets && item.serviceTickets.length > 0 ? this.setTicketCustomClass(item) : '';

        obj.tooltipClass = this.setClassForToolTipStatusForFloorSecondary(item, isProject, isSelectedDevice, showPinColor, assignedToFilter, filter);
        if (!isProject) {
            obj.showTicketIcon = this.checkTicketsStatus(item);
        }
        if (item.childFloorEquipments && item.childFloorEquipments.length > 0) {
            const filterSecDevice: any = this.filterDevices(item.childFloorEquipments, { devices: filter });
            //secondary count
            obj.secondaryDeviceCount = filterSecDevice.length;

            const filterPrimary: any = _.cloneDeep(item);
            filterPrimary.childFloorEquipments = [];
            filterPrimary.childFloorEquipments = _.cloneDeep(filterSecDevice);
            //badge color
            obj.badgeColor = this.setClassForBadge(filterPrimary, isProject, showPinColor, isProjectArchived, isSiteArchived)
            // 
            obj.classForSecondary = this.setClassForToolTipStatusForFloorSecondary(filterPrimary, isProject, false, showPinColor, assignedToFilter, filter);

        }

        return obj;
    }

    static hexToRgbA(hex: any) {
        if (hex === null) {
            return null;
        }
        let c: any;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',');
        }
        throw new Error('Bad Hex');
    }

    static checkImages(item: any): any {
        if (!Utilities.isNull(item.images) && (item.images.length > 0 && item.images.some((image: any) => !image.isDeleted))) {
            return true;
        }
    }

    static setClassForBadge(item: FloorEquipment, isProject: any, pinColor: any, isProjectArchived: any, isSiteArchived: any): any {
        let color = '';
        if (isProject && item.projectId === null) {
            color = 'site-device-project';
            return color;
        }
        if (item.childFloorEquipments.every((x: any) => (x.isArchived || (x.archivedStatus === this.PLAN_ARCHIVED && x.type === DeviceTypes.Task)) && !item.isPublishedToAMT)) {
            if (item.isNew) {
                color = item.status === EquipmentStatusForDevice.OPERATIONAL ? EquipmentStatusForDevice.OPERATIONAL : (item.status === EquipmentStatusForDevice.OPERATIONALWITHISSUE ? EquipmentStatusForDevice.OPERATIONALWITHISSUE : EquipmentStatusForDevice.NOTWORKING)
            } else {
                color = EquipmentStatusForDevice.ARCHIVED
            }
        } else if (!item.isDeleted && !item.isArchived) {
            if (!isProject || pinColor === PinColorStatusType.OPERATIONALSTATUS) {
                if (item.childFloorEquipments.some((x: any) => (!x.isArchived && x.type === 'device' && x.status === EquipmentStatusForDevice.OPERATIONAL) || (x.type === 'task' && x.installStatus === EquipmentStatusForDevice.INSTALLED))) {
                    color = EquipmentStatusForDevice.OPERATIONAL;
                }
                if (item.childFloorEquipments.some((x: any) => (!x.isArchived && x.type === 'device' && x.status === EquipmentStatusForDevice.OPERATIONALWITHISSUE) || (x.type === 'task' && x.installStatus === EquipmentStatusForDevice.INPROGRESS))) {
                    color = EquipmentStatusForDevice.OPERATIONALWITHISSUE;
                }
                if (item.childFloorEquipments.some((x: any) => (!x.isArchived && x.type === 'device' && x.status === EquipmentStatusForDevice.NOTWORKING) || (x.type === 'task' && x.installStatus === EquipmentStatusForDevice.PLANNED))) {
                    color = EquipmentStatusForDevice.NOTWORKING;
                }
            } else {
                if (item.childFloorEquipments.some((x: any) => !x.isArchived && x.installStatus === EquipmentStatusForDevice.INSTALLED)) {
                    color = EquipmentStatusForDevice.OPERATIONAL;
                }
                if (item.childFloorEquipments.some((x: any) => !x.isArchived && x.installStatus === EquipmentStatusForDevice.INPROGRESS)) {
                    color = EquipmentStatusForDevice.OPERATIONALWITHISSUE;
                }
                if (item.childFloorEquipments.some((x: any) => !x.isArchived && x.installStatus === EquipmentStatusForDevice.PLANNED)) {
                    color = EquipmentStatusForDevice.NOTWORKING;
                }
            }
        } else if (item.isArchived  && !item.isPublishedToAMT) {
            color = EquipmentStatusForDevice.ARCHIVED
        }
        if ((isProjectArchived && !item.isPublishedToAMT) || isSiteArchived) {
            color = EquipmentStatusForDevice.ARCHIVED
        }
        const customColor = {
            grey: color === EquipmentStatusForDevice.ARCHIVED ? true : false,
            blue: color === EquipmentStatusForDevice.OPERATIONAL ? true : false,
            yellow: color === EquipmentStatusForDevice.OPERATIONALWITHISSUE ? true : false,
            red: color === EquipmentStatusForDevice.NOTWORKING ? true : false,
        }
        return customColor;
    }



    static filterDevices(items: FloorEquipment[], filter: any) {
        if (!filter) {
            return items;
        }
        items = items.filter((item: any) => {
            if (!item.isDeleted) {
                return item;
            }
        })
        if (!filter.devices) {
            return items
        }
        if (filter && filter.devices && filter.devices.some((x: any) => x.category.id === 5 && x.isChecked)) {
            items = items.filter(item => {
                return !item.isArchived;
            })
        }
        items = items.filter(item => {
            return filter.devices.filter((x: any) => x.isChecked).some((y: any) => (y.category.name === item.categoryName || this.filterDevices(item.childFloorEquipments, filter).length > 0))
        });
        items = items.filter(item => {
            return filter.devices.filter((x: any) => x.isChecked).some((y: any) => y.category.name === item.status || (item.type === EquipmentType.TASK && filter.isProject) || this.filterDevices(item.childFloorEquipments, filter).length > 0)
        });
        if (filter.isProject) {
            items = items.filter(item => {
                return filter.devices.filter((x: any) => x.isChecked).some((y: any) => y.category.name === item.installStatus || item.projectId === null || this.filterDevices(item.childFloorEquipments, filter).length > 0)
            })
            if (filter.assignedToFilter != null) {
                if (filter.assignedToFilter == 'Unassigned') {
                    items = items.filter(item => {
                        return item.assignedToUser == null || (item.childFloorEquipments && this.filterDevices(item.childFloorEquipments, filter).length > 0);
                    })
                } else {
                    items = items.filter(item => {
                        return (item.assignedToUser && item.assignedToUser == filter.assignedToFilter) || (item.childFloorEquipments && this.filterDevices(item.childFloorEquipments, filter).length > 0);
                    })
                }
            }
        }
        if (filter.isChild) {
            items = items.sort((item1, item2) => item1.orderWithinParent - item2.orderWithinParent);
        } else {
            if (!filter.hideLiveDevice) {
                items = _.orderBy(items, ['projectId', 'type', 'id'], ['desc', 'asc', 'asc']);
            } else {
                items = _.orderBy(items, ['type', 'id'], ['asc']);
            }
            items = items.sort((a, b) => {
                if (a.id < 1) {
                    return 1;
                } else if (b.id < 1) {
                    return -1;
                } else {
                    return 0;
                }
            });
        }
        return items.sort((a: any, b: any) => {
            if (!filter.isChild) {
                if (a.isArchived && !b.isArchived) {
                    return -1;
                } else if (!a.isArchived && b.isArchived) {
                    return 1;
                } else {
                    return 0;
                }
            }
            else {
                return 0;
            }
        });
    }

    static checkOperationalClass(item: FloorEquipment, fromProject: boolean, pinColorStatus: string) {
        return ((item.type === DeviceTypes.Device) && (item.status === EquipmentStatusForDevice.OPERATIONAL) && pinColorStatus === PinColorStatus.OPERATIONALSTATUS)
            || (!fromProject && (item.type === DeviceTypes.Device) && (item.status === EquipmentStatusForDevice.OPERATIONAL) && pinColorStatus === PinColorStatus.DEVICECOLOR)
            || (fromProject && (item.type === DeviceTypes.Device) && (item.installStatus === EquipmentStatusForDevice.INSTALLED) && pinColorStatus === PinColorStatus.INSTALLSTATUS)
            || (fromProject && (item.type === DeviceTypes.Device) && (item.installStatus === EquipmentStatusForDevice.INSTALLED) && pinColorStatus === PinColorStatus.DEVICECOLOR)
    }

    static checkOperationalWithIssueClass(item: FloorEquipment, fromProject: boolean, pinColorStatus: string) {
        return ((item.type === DeviceTypes.Device) && (item.status === EquipmentStatusForDevice.OPERATIONALWITHISSUE) && pinColorStatus === PinColorStatus.OPERATIONALSTATUS)
            || (!fromProject && (item.type === DeviceTypes.Device) && (item.status === EquipmentStatusForDevice.OPERATIONALWITHISSUE) && pinColorStatus === PinColorStatus.DEVICECOLOR)
            || (fromProject && (item.type === DeviceTypes.Device) && (item.installStatus === EquipmentStatusForDevice.INPROGRESS) && pinColorStatus === PinColorStatus.INSTALLSTATUS)
            || (fromProject && (item.type === DeviceTypes.Device) && (item.installStatus === EquipmentStatusForDevice.INPROGRESS) && pinColorStatus === PinColorStatus.DEVICECOLOR)
    }

    static checkNotWorkingClass(item: FloorEquipment, fromProject: boolean, pinColorStatus: string) {
        return ((item.type === DeviceTypes.Device) && (item.status === EquipmentStatusForDevice.NOTWORKING) && pinColorStatus === PinColorStatus.OPERATIONALSTATUS)
            || (!fromProject && (item.type === DeviceTypes.Device) && (item.status === EquipmentStatusForDevice.NOTWORKING) && pinColorStatus === PinColorStatus.DEVICECOLOR)
            || (fromProject && (item.type === DeviceTypes.Device) && (item.installStatus === EquipmentStatusForDevice.PLANNED) && pinColorStatus === PinColorStatus.INSTALLSTATUS)
            || (fromProject && (item.type === DeviceTypes.Device) && (item.installStatus === EquipmentStatusForDevice.PLANNED) && pinColorStatus === PinColorStatus.DEVICECOLOR)

    }

    static checkOperationalTask(item: FloorEquipment, fromProject: boolean) {
        return (fromProject && (item.type === DeviceTypes.Task) && (item.installStatus === EquipmentStatusForDevice.INSTALLED))
    }

    static checkOperationalWithIssueTask(item: FloorEquipment, fromProject: boolean) {
        return (fromProject && (item.type === DeviceTypes.Task) && (item.installStatus === EquipmentStatusForDevice.INPROGRESS))
    }

    static checkNotWorkingTask(item: FloorEquipment, fromProject: boolean) {
        return (fromProject && (item.type === DeviceTypes.Task) && (item.installStatus === EquipmentStatusForDevice.PLANNED))
    }

    static showTransparentPrimaryPin(item: FloorEquipment, fromProject: boolean, assignedToFilter: any, filterData: any) {
        if (fromProject && item.projectId === null) {
            return filterData && (filterData.filter((e: any) => e.category.name === item.categoryName && e.isChecked).length === 0
                || filterData.filter((e: any) => e.category.name === item.status && e.isChecked).length === 0)
                && (item.childFloorEquipments.filter(childItem => { return filterData.filter((x: any) => x.isChecked).some((y: any) => y.category.name === childItem.categoryName) }).length > 0 || filterData.filter((x: any) => x.isChecked).some((y: any) => y.category.name === item.status || (item.type === EquipmentType.TASK) || item.childFloorEquipments.filter((e: any) => e.status === y.category.name).length > 0).length > 0)
        } else {
            return filterData && (filterData.filter((e: any) => e.category.name === item.categoryName && e.isChecked).length === 0
                || (item.type !== DeviceTypes.Task && (filterData.filter((e: any) => e.category.name === item.status && e.isChecked).length === 0))
                || (fromProject && filterData.filter((e: any) => e.category.name === item.installStatus && e.isChecked).length === 0))
                && (item.childFloorEquipments.filter(childItem => { return filterData.filter((x: any) => x.isChecked).some((y: any) => y.category.name === childItem.categoryName) }).length > 0
                    || (item.type !== DeviceTypes.Task && (filterData.filter((x: any) => x.isChecked).some((y: any) => y.category.name === item.status || (item.type === EquipmentType.TASK && fromProject) || item.childFloorEquipments.filter((e: any) => e.status === y.category.name).length > 0).length > 0))
                    || (fromProject && filterData.filter((x: any) => x.isChecked).some((y: any) => y.category.name === item.installStatus || item.projectId === null || item.childFloorEquipments.filter((e: any) => e.installStatus === y.category.name).length > 0).length > 0))
                || (fromProject && assignedToFilter && assignedToFilter == 'Unassigned' && item.assignedToUser !== null && item.childFloorEquipments && item.childFloorEquipments.filter((e: any) => e.assignedToUser === null).length > 0)
                || (fromProject && assignedToFilter && assignedToFilter != 'Unassigned' && (item.assignedToUser == null || (item.assignedToUser && item.assignedToUser != assignedToFilter)) && item.childFloorEquipments && item.childFloorEquipments.filter((e: any) => e.assignedToUser && e.assignedToUser == assignedToFilter).length > 0)
        }
    }

    static checkArchived(item: FloorEquipment, fromProject: boolean) {
        const filter = item && ((item.isArchived && !item.isPublishedToAMT) || item.archivedStatus === this.PLAN_ARCHIVED);
        if (fromProject) {
            return filter && (!item.isPublishedToAMT && item.isArchived);
        }
        return filter;
    }

    static checkTaskArchived(item: FloorEquipment, fromProject: boolean) {
        return (fromProject && (item.type === DeviceTypes.Task) && item.isArchived);
    }


    static checkSiteDevice(item: FloorEquipment, fromProject: boolean) {
        return item.projectId === null && fromProject;
    }

    static applyClass(item: FloorEquipment, fromProject: boolean, PinColorStatus: string, assignedToFilter: any, filter: any) {
        const css = {
            'archive': this.checkArchived(item, fromProject),
            'site-device': this.checkSiteDevice(item, fromProject),
            'show-primary-pin': this.showTransparentPrimaryPin(item, fromProject, assignedToFilter, filter),
            'Operational': this.checkOperationalClass(item, fromProject, PinColorStatus),
            'OperationalWithIssue': this.checkOperationalWithIssueClass(item, fromProject, PinColorStatus),
            'NotWorking': this.checkNotWorkingClass(item, fromProject, PinColorStatus),
            'Operation-task': this.checkOperationalTask(item, fromProject),
            'OperationalWithIssue-task': this.checkOperationalWithIssueTask(item, fromProject),
            'NotWorking-task': this.checkNotWorkingTask(item, fromProject),
            'Archive-task': this.checkTaskArchived(item, fromProject)
        }

        if (css['Archive-task'] || css['archive']) {
            css['show-primary-pin'] = true;
        }
        return css;
    }

    static setTicketCustomClass(item: FloorEquipment) {
        if (item.serviceTickets && item.serviceTickets.length > 0) {
            if (item.serviceTickets.some((x: any) => x.status === TicketStatuses.OPEN)) {
                return { 'NotWorking': true };
            } else if (item.serviceTickets.some((x: any) => x.status === TicketStatuses.INPROGRESS)) {
                return { 'OperationalWithIssue': true };
            } else if (item.serviceTickets.some((x: any) => x.status === TicketStatuses.CLOSED)) {
                return { 'closed': true };
            }
        }
        return { 'NotWorking': true };
    }

    static getScreenWidthHeight() {
        const width = screen.width - (309 + 57)
        const height = screen.height - 120;
        return { width: width, height: height }

    }


    static checkSecondaryDevicePosition(item: FloorEquipment, pageX: any, pageY: any) {
        const devicePos = this.positionSecondaryPopup(pageX);
        const devicePosTop = this.positionSecondaryPopupTop(pageY);
        return {
            'secondary-devices-right': this.getSecondaryPopupShowRightSide(item.imageLeft, true, devicePos),
            'secondary-devices-left': !this.getSecondaryPopupShowRightSide(item.imageLeft, true, devicePos),
            'secondary-devices-bottom': !this.getSecondaryPopupShowBottomSide(item.imageTop, true, devicePosTop)
        };
    }

    static getSecondaryPopupShowRightSide(side: any, deviceDropped: any, secondaryDevicePopupPosition: any) {
        const checkScreen = window.screen.width > 1366 ? 60 : 50
        if (deviceDropped) {
            return secondaryDevicePopupPosition > checkScreen ? false : true
        }
        return parseFloat(side) > 60 ? false : true
    }

    static getSecondaryPopupShowBottomSide(side: any, deviceDropped: any, secondaryDevicePopupPosition: any) {
        const checkScreen = window.screen.height > 768 ? 60 : 42
        if (deviceDropped) {
            return parseFloat(secondaryDevicePopupPosition) > checkScreen ? false : true;
        }
        return parseFloat(side) > 60 ? false : true;
    }

    // open secondary device popUp left or right
    static positionSecondaryPopup(pageX: any) {
        return (Math.abs(pageX - 366) / this.getScreenWidthHeight().width) * 100;

    }

    static positionSecondaryPopupTop(pageY: any) {
        return (Math.abs(pageY - 100) / this.getScreenWidthHeight().height) * 100;
    }

    static setClassForToolTipStatusForFloorSecondary(item: any, fromProject: any, isSelectedDevice: boolean = false, showPinColor: any, assignedToFilter: any, filterData: any) {
        let showPrimary = false;
        let archive = false;
        if (filterData && (filterData.filter((e: any) => e.category.name === item.categoryName && e.isChecked).length === 0
            || (item.type !== DeviceTypes.Task && (filterData.filter((e: any) => e.category.name === item.status && e.isChecked).length === 0))
            || (fromProject && filterData.filter((e: any) => e.category.name === item.installStatus && e.isChecked).length === 0))
            && (item.childFloorEquipments.filter((childItem: any) => { return filterData.filter((x: any) => x.isChecked).some((y: any) => y.category.name === childItem.categoryName) }).length > 0
                || (item.type !== DeviceTypes.Task && (filterData.filter((x: any) => x.isChecked).some((y: any) => y.category.name === item.status || (item.type === 'task' && fromProject) || item.childFloorEquipments.filter((e: any) => e.status === y.category.name).length > 0).length > 0))
                || (fromProject && filterData.filter((x: any) => x.isChecked).some((y: any) => y.category.name === item.installStatus || item.projectId === null || item.childFloorEquipments.filter((e: any) => e.installStatus === y.category.name).length > 0).length > 0))
            || (fromProject && assignedToFilter && assignedToFilter == 'Unassigned' && item.assignedToUser !== null && item.childFloorEquipments && item.childFloorEquipments.filter((e: any) => e.assignedToUser === null).length > 0)
            || (fromProject && assignedToFilter && assignedToFilter != 'Unassigned' && (item.assignedToUser == null || (item.assignedToUser && item.assignedToUser != assignedToFilter)) && item.childFloorEquipments && item.childFloorEquipments.filter((e: any) => e.assignedToUser && e.assignedToUser == assignedToFilter).length > 0)) {
            showPrimary = true;
        } else if (filterData && (filterData.filter((e: any) => e.category.name === item.categoryName && e.isChecked).length === 0
            || filterData.filter((e: any) => e.category.name === item.status && e.isChecked).length === 0)
            && (item.childFloorEquipments.filter((childItem: any) => { return filterData.filter((x: any) => x.isChecked).some((y: any) => y.category.name === childItem.categoryName) }).length > 0
                || filterData.filter((x: any) => x.isChecked).some((y: any) => y.category.name === item.status || (item.type === 'task' && fromProject) || item.childFloorEquipments.filter((e: any) => e.status === y.category.name).length > 0).length > 0)) {
            showPrimary = true;
        }
        if (fromProject && item.type === DeviceTypes.Task) {
            archive = item && !item.isPublishedToAMT && (item.isArchived || item.archivedStatus === "Plan Archived");
            showPrimary = archive;
        }
        return {
            'active': (isSelectedDevice ? true : false),
            'tooltip-archive': ((item && (item.parentId === '' || item.parentId === null || item.parentId === undefined)) && (item && item.isArchived) || archive || (item.archivedStatus === "Plan Archived" && !item.isPublishedToAMT)),
            'tooltip-operational': ((!fromProject && (item.status === EquipmentStatusForDevice.OPERATIONAL)) ||
                (fromProject && ((item.installStatus === 'Installed' && (showPinColor === PinColorStatus.INSTALLSTATUS || showPinColor === PinColorStatus.DEVICECOLOR)) || (((item.status === EquipmentStatusForDevice.OPERATIONAL && item.type !== 'task') || (item.installStatus === EquipmentStatusForDevice.INSTALLED && item.type === 'task')) && (showPinColor === PinColorStatus.OPERATIONALSTATUS || showPinColor === PinColorStatus.DEVICECOLOR))))),
            'tooltip-operationalwith': ((!fromProject && (item.status === EquipmentStatusForDevice.OPERATIONALWITHISSUE)) ||
                (fromProject && ((item.installStatus === EquipmentStatusForDevice.INPROGRESS && (showPinColor === PinColorStatus.INSTALLSTATUS || showPinColor === PinColorStatus.DEVICECOLOR)) || (((item.status === EquipmentStatusForDevice.OPERATIONALWITHISSUE && item.type !== 'task') || (item.installStatus === EquipmentStatusForDevice.INPROGRESS && item.type === 'task')) && (showPinColor === PinColorStatus.OPERATIONALSTATUS || showPinColor === PinColorStatus.DEVICECOLOR))))),
            'tooltip-notworking': (!fromProject && (item.status === EquipmentStatusForDevice.NOTWORKING) ||
                (fromProject && ((item.installStatus === EquipmentStatusForDevice.PLANNED && (showPinColor === PinColorStatus.INSTALLSTATUS || showPinColor === PinColorStatus.DEVICECOLOR)) || (((item.status === EquipmentStatusForDevice.NOTWORKING && item.type !== 'task') || (item.installStatus === EquipmentStatusForDevice.PLANNED && item.type === 'task')) && (showPinColor === PinColorStatus.OPERATIONALSTATUS || showPinColor === PinColorStatus.DEVICECOLOR))))),
            'show-primary': showPrimary || (item.archivedStatus === "Plan Archived" && !item.isPublishedToAMT),
            'tooltip-site-device': fromProject && item.projectId === null,
        }
    }

    static checkDeviceNames(baseNameArray: any, device: any) {
        if (baseNameArray && baseNameArray.length > 0) {
            const deviceName = this.getBaseName(device.name).baseName;
            const deviceCount = this.getBaseName(device.name).count;
            const exsitingDevice = baseNameArray.filter(
                (x: any) => x.name === deviceName
            )[0];
            if (exsitingDevice) {
                if (deviceCount > exsitingDevice.count) {
                    exsitingDevice.count = deviceCount;
                }
            } else {
                baseNameArray.push({
                    name: this.getBaseName(device.name).baseName,
                    count: deviceCount,
                });
            }
            // this.childDeviceBaseName(parent);
        } else {
            const nameAndCount = this.getBaseName(device.name);
            baseNameArray.push({
                name: nameAndCount.baseName,
                count: nameAndCount.count,
            });
            // this.childDeviceBaseName(parent);
        }
        return baseNameArray;
    }
    static getBaseName(name: any) {
        if (name) {
            const nameArr: any[] = name.split(" ");
            if (nameArr.length > 1 && !isNaN(nameArr[nameArr.length - 1])) {
                let baseName: string;
                let count = 0;
                if (this.isNumeric(nameArr[nameArr.length - 1])) {
                    baseName = name
                        .substr(0, name.length - 1 - nameArr[nameArr.length - 1].length)
                        .trim();
                    count = Number(nameArr[nameArr.length - 1]);
                } else {
                    baseName = name;
                    count = 0;
                }
                return { baseName: baseName, count: count };
            } else {
                return { baseName: name, count: 0 };
            }
        } else {
            return { baseName: "", count: 0 };
        }
    }
    static isNumeric(value: any) {
        return /^\d+$/.test(value);
    }
    static checkCountDevice(baseNameCount: any, name: any, isDuplicate: any) {
        let cnt = 0;
        const deviceName = this.getBaseName(name).baseName;
        const existingDeviceCnt = baseNameCount.filter(
            (x: any) => x.name === deviceName
        )[0];
        if (existingDeviceCnt) {
            cnt = existingDeviceCnt.count + 1;
        } else {

            name = name.trim();
            const splitName = name.split(/(\s+)/);
            const checkLastDigit = splitName[splitName.length - 1];
            if (isNaN(checkLastDigit)) {
                cnt = 0;
            } else {
                cnt = parseInt(checkLastDigit);
            }
            cnt = cnt + 1;
        }

        return deviceName + " " + cnt;
    }
    static setRolesForFloorPlan(role: any, permissions: any, userDetails: any, isProject: boolean = false): any {
        if (userDetails.isAdmin || userDetails.userRole === ProviderRole.Administrator) {
            role.isAdmin = true;
            permissions.canEdit = permissions.canManage = permissions.canUpdate = permissions.canDeleteOrArchive = true;
        } else if (isProject) {
            if (userDetails.projectCustomerRoleName === projectRoles.ViewAll) {
                role.isViewOnly = true
                permissions.canViewOnly = true;
            } else if (userDetails.projectCustomerRoleName === projectRoles.Technician) {
                role.isTechnician = true;
                permissions.canUpdate = true;
            } else if (userDetails.projectCustomerRoleName === projectRoles.LeadTechnician) {
                role.isLeadTechnician = true;
                permissions.canManage = permissions.canUpdate = permissions.canDeleteOrArchive = true;
            } else if (userDetails.projectCustomerRoleName === projectRoles.Manager) {
                role.isManger = true;
                permissions.canEdit = permissions.canManage = permissions.canUpdate = permissions.canDeleteOrArchive = true;
            }
        } else {
            if (userDetails.customerRoleName === liveSystemRoles.ViewAll) {
                role.isViewOnly = true;
                permissions.canViewOnly = true;
            } else if (userDetails.customerRoleName === liveSystemRoles.Technician) {
                role.isTechnician = true;
                permissions.canUpdate = true;
            } else if (userDetails.customerRoleName === liveSystemRoles.Agent) {
                role.isAgent = true;
                permissions.canManage = permissions.canUpdate = permissions.canDeleteOrArchive = true;
            } else if (userDetails.customerRoleName === liveSystemRoles.Manager) {
                role.isManger = true;
                permissions.canEdit = permissions.canManage = permissions.canUpdate = permissions.canDeleteOrArchive = true;
            }
        }
        return { role: role, permissions: permissions };
    }


    static getEquipment(floorDetails: any, id: any) {
        let equipment: any = undefined;
        if (floorDetails.floorEquipments.filter((x: any) => x.id === id).length > 0) {
            equipment = floorDetails.floorEquipments.filter((x: any) => x.id === id)[0];
        } else if (equipment === undefined) {
            floorDetails.floorEquipments.forEach((element: any) => {
                if (element.childFloorEquipments.filter((x: any) => x.id === id).length > 0) {
                    equipment = element.childFloorEquipments.filter((x: any) => x.id === id)[0];
                }
            });
        }
        return equipment
    }
    static coverageConeLeftDragAngles(item: any, coneArcLength: any, radius: any) {
        const angleValues = { device: null, coneArcLength: null, pos: { start: 0, end: 0 } };
        if (item.coverageAngle < 343) {
            coneArcLength = (item.coverageAngle * Math.PI / 360) * radius
            if (coneArcLength < 58) {
                item.coverageAngle = (45 / radius) * 180
            }
        } else {
            coneArcLength = ((360 - item.coverageAngle) * Math.PI / 360) * radius
            if (coneArcLength < 58) {
                item.coverageAngle = 360 - ((15 / radius) * 180)
            }
        }
        if ((item.coverageDirection > 8 && item.coverageDirection < 352) || item.coverageAngle < 343) {
            angleValues.pos.start = (item.coverageDirection - item.coverageAngle / 2)
            angleValues.pos.end = (item.coverageDirection + item.coverageAngle / 2)
        } else {
            angleValues.pos.end = angleValues.pos.start = 188
        }
        angleValues.device = item;
        angleValues.coneArcLength = coneArcLength;
        return angleValues;
    }
    static updateLeftDom(item: any, xR: any, yR: any, endAngle: any) {
        const gL2 = document.getElementById('arc_g2_div_LeftSide_' + item.id + "_" + item.name);
        if (gL2) {
            gL2.style.transform = "rotate(" + endAngle + "deg)"
        }

        const textL = document.getElementById('arc_text_div_LeftSide_' + item.id + "_" + item.name);
        if (textL) {
            textL.setAttribute("x", (xR).toString());
            textL.setAttribute("y", (yR).toString());
        }


        const circleL = document.getElementById('arc_div_circle_LeftSide_' + item.id + "_" + item.name);
        if (circleL) {
            circleL.setAttribute("cx", (xR).toString());
            circleL.setAttribute("cy", (yR).toString());
        }
    }

    static addUnCertainThumbURLS(uploadDeviceimages: any) {
        uploadDeviceimages.forEach((item: any) => {
            let uncertainThumbnails = [];
            const thumb: any = sessionStorage.getItem('uncertainThumbnails');
            if (thumb) {
                uncertainThumbnails = JSON.parse(thumb);
                uncertainThumbnails.push(item.split('/')[item.split('/').length - 1]);
                sessionStorage.setItem('uncertainThumbnails', JSON.stringify(uncertainThumbnails));
            } else {
                uncertainThumbnails.push(item.split('/')[item.split('/').length - 1]);
                sessionStorage.setItem('uncertainThumbnails', JSON.stringify(uncertainThumbnails));
            }
        })

    }

    static checkTicketsStatus(item: FloorEquipment) {
        if (item.serviceTickets && item.serviceTickets.length > 0 && !item.serviceTickets.every((ticket: any) => ticket.status == 'Closed')) {
            if (!item.isArchived && item.serviceTickets.some((t: any) => !t.isArchived)) {
                return true;
            } else if (item.isArchived) {
                return true;
            }
        }
        return false;
    }

    static setFilterCategory(reportType: any, isProject: any, categoryData: any) {
        const filterCategory: any = [];
        ViewingReportPreferences.forEach((element: any) => {
            const category = new Category();
            category.id = element.id;
            category.name = element.name;
            category.displayName = element.displayName;
            category.key = element.key;
            if ((reportType === PrintOutType.NUMBERED || reportType === PrintOutType.MULTIPLAN) && (element.id === 3 || element.id === 4 || element.id === 5)) {
                filterCategory.push({ isChecked: true, category: category, type: 'vp' })
            } else if (reportType === PrintOutType.ICON && element.id !== 5 && element.id !== 8) {
                filterCategory.push({ isChecked: true, category: category, type: 'vp' })
            }
        })
        if (categoryData && categoryData.length > 0) {
            categoryData.forEach((category: Category) => {
                if (category.name !== 'Favorites' && category.name !== 'Tasks' && !isProject) {
                    filterCategory.push({ isChecked: true, category: category, type: 'st' })
                } else if (category.name !== 'Favorites' && isProject) {
                    filterCategory.push({ isChecked: true, category: category, type: 'st' })
                }
            });
        }
        OperationalStatusFilter.forEach((element: any) => {
            const category = new Category();
            category.id = element.id;
            category.name = element.name;
            category.displayName = element.displayName;
            filterCategory.push({ isChecked: true, category: category, type: 'op' })
        });
        if (isProject) {
            InstallStatusFilter.forEach((element: any) => {
                const category = new Category();
                category.id = element.id;
                category.name = element.name;
                category.displayName = element.displayName;
                filterCategory.push({ isChecked: true, category: category, type: 'is' })
            });
        }

        return filterCategory;
    }

    static setDownloadData(downloadData: any) {
        const systemTypeFilterArr: any = [];
        const deviceStatusFilterArr: any = [];
        const installStatusArr: any = [];
        const viewingPreference: any = [];
        if (downloadData) {
            downloadData.viewingPreference.forEach((element: any) => {
                viewingPreference.push(element.category.key);
            });
            downloadData.systemTypeFilter.forEach((element: any) => {
                if (element.category.name === "Fire Alarm" || element.category.name === "Audio Visual" || element.category.name === "Infrastructure" ||
                    element.category.name === "Video Surveillance" || element.category.name === "Access Control" || element.category.name === "Intrusion Detection" ||
                    element.category.name === "Communications" || element.category.name === "Tasks") {
                    systemTypeFilterArr.push(element.category.id);
                }
            })
            downloadData.deviceStatus.forEach((element: any) => {
                if (element.category.name === "OperationalWithIssue" || element.category.name === "NotWorking" || element.category.name === "Operational") {
                    deviceStatusFilterArr.push(element.category.name);
                }
            })
            downloadData.installStatus.forEach((element: any) => {
                if (element.category.name === "Planned" || element.category.name === "InProgress" || element.category.name === "Installed") {
                    installStatusArr.push(element.category.name);
                }
            })
        }
        return { viewingPreference: viewingPreference, systemTypeFilterArr: systemTypeFilterArr, deviceStatusFilterArr: deviceStatusFilterArr, installStatusArr: installStatusArr }
    }
    static rotateDomChanges(item: any, start: any, end: any, radius: any, scale: any, isParentTransparent: any) {
        const myNewSvgR: any = document.getElementById("coveragesvg_dev_R_" + item.id + "_" + item.name);
        const spacerXR = (22 * Math.sin(Math.PI * 2 * (start + 80) / 360));
        const spacerYR = (22 * Math.cos(Math.PI * 2 * (end + 80) / 360));
        const xR = (radius * Math.sin(Math.PI * 2 * start / 360) + spacerXR);
        const yR = (radius * Math.cos(Math.PI * 2 * end / 360) + spacerYR);
        const rotateG = document.getElementById('arc_g2_div_' + item.id + "_" + item.name)
        if (rotateG) {
            rotateG.style.transform = 'rotate(' + (item.coverageDirection - 50) + 'deg)'
        }

        const textR = document.getElementById('arc_text_div_R_' + item.id + "_" + item.name);
        if (textR) {
            textR.setAttribute("x", (xR).toString());
            textR.setAttribute("y", (-yR).toString());
        }

        const circleR = document.getElementById('arc_div_circle_R_' + item.id + "_" + item.name);
        if (circleR) {
            circleR.setAttribute("cx", (xR).toString());
            circleR.setAttribute("cy", (-yR).toString());
        }
        if (myNewSvgR) {
            const coverageRotate: any = document.getElementById('arc_div_R_' + item.id + '_' + item.name);
            coverageRotate.style.opacity = myNewSvgR.style.opacity = '1'
            if (isParentTransparent) {
                myNewSvgR.style.opacity = '0.5'
            }
        }
    }
    static leftSideDomChanges(item: any, radius: any, isParentTransparent: any) {
        radius = radius + 5
        const leftConeAngleValues = FloorUtils.coverageConeLeftDragAngles(item, 0, radius);
        item = leftConeAngleValues.device;
        const myNewSvgL: any = document.getElementById("coveragesvg_dev_R_LeftSide_" + item.id + "_" + item.name);
        const data = CoverageCone.getArc(0, 0, radius, (leftConeAngleValues.pos.start), (leftConeAngleValues.pos.end))
        const xR = data.end.x
        const yR = data.end.y
        let endAngle = item.coverageDirection - (item.coverageAngle / 2);
        if (endAngle < 0) {
            endAngle = 360 + endAngle
        }

        FloorUtils.updateLeftDom(item, xR, yR, endAngle);
        if (myNewSvgL) {
            const coverageLeftDrag: any = document.getElementById('arc_div_LeftSide_' + item.id + '_' + item.name);
            coverageLeftDrag.style.opacity = '1'
            myNewSvgL.style.opacity = '1'
            if (isParentTransparent) {
                myNewSvgL.style.opacity = '0.5'
            }
        }
    }
    static rightSideDomChanges(item: any, radius: any, isParentTransparent: any) {
        let start, end;
        radius = radius + 5;
        let coneArcLength = 0
        if (item.coverageAngle < 343) {
            coneArcLength = (item.coverageAngle * Math.PI / 360) * radius
            if (coneArcLength < 58) {
                item.coverageAngle = (45 / radius) * 180
            }
        } else {
            coneArcLength = ((360 - item.coverageAngle) * Math.PI / 360) * radius
            if (coneArcLength < 58) {
                item.coverageAngle = 360 - ((15 / radius) * 180)
            }
        }
        if ((item.coverageDirection > 8 && item.coverageDirection < 352) || item.coverageAngle < 343) {
            start = (item.coverageDirection + item.coverageAngle / 2);
            end = (item.coverageDirection - item.coverageAngle / 2);
        } else {
            end = start = 172
        }
        const myNewSvgR: any = document.getElementById("coveragesvg_dev_R_RightSide_" + item.id + "_" + item.name);
        const data = CoverageCone.getArc(0, 0, radius, (start), (end))
        // console.log(CoverageCone.getArc(0, 0, radius, (start), (end)), '====> from floor')
        const xR = data.end.x
        const yR = data.end.y
        let startAngle = item.coverageDirection + (item.coverageAngle / 2);
        if (startAngle > 360) {
            startAngle = startAngle - 360
        }

        const g2 = document.getElementById('arc_g2_div_RightSide_' + item.id + "_" + item.name);
        if (g2) {
            g2.style.transform = 'rotate(' + (startAngle) + 'deg)';
        }

        const textR = document.getElementById('arc_text_div_RightSide_' + item.id + "_" + item.name);
        if (textR) {
            textR.setAttribute("x", (xR).toString());
            textR.setAttribute("y", (yR).toString());
        }

        const circleR = document.getElementById('arc_div_circle_RightSide_' + item.id + "_" + item.name);
        if (circleR) {
            circleR.setAttribute("cx", (xR).toString());
            circleR.setAttribute("cy", (yR).toString());
        }
        if (myNewSvgR) {
            const coverageRightDrag: any = document.getElementById('arc_div_RightSide_' + item.id + '_' + item.name);
            coverageRightDrag.style.opacity = '1'
            myNewSvgR.style.opacity = '1'
            if (isParentTransparent) {
                myNewSvgR.style.opacity = '0.5'
            }
        }
    }
    static getOpacityForDevice(item: any, isProject: any, showPinColor: any, assignedToFilter: any, filter: any) {
        const checkOpacity = (filter && filter.filter((e: any) => e.category.name === item.categoryName && e.isChecked).length === 0
            || (item.type !== DeviceTypes.Task && (filter && filter.filter((e: any) => e.category.name === item.status && e.isChecked).length === 0))
            || (isProject && filter && filter.filter((e: any) => e.category.name === item.installStatus && e.isChecked).length === 0))
            && (item.childFloorEquipments.filter((childItem: any) => { return filter.filter((x: any) => x.isChecked).some((y: any) => y.category.name === childItem.categoryName) }).length > 0
                || (item.type !== DeviceTypes.Task && (filter.filter((x: any) => x.isChecked).some((y: any) => y.category.name === item.status || (item.type === 'task' && isProject) || item.childFloorEquipments.filter((e: any) => e.status === y.category.name).length > 0)))
                || (isProject && filter && filter.filter((x: any) => x.isChecked).some((y: any) => y.category.name === item.installStatus || item.projectId === null || item.childFloorEquipments.filter((e: any) => e.installStatus === y.category.name).length > 0)))
            || (isProject && assignedToFilter && assignedToFilter == 'Unassigned' && item.assignedTo !== null && item.childFloorEquipments && item.childFloorEquipments.filter((e: any) => e.assignedTo === null).length > 0)
            || (isProject && assignedToFilter && assignedToFilter != 'Unassigned' && (item.assignedTo == null || (item.assignedTo && item.assignedTo.id != assignedToFilter)) && item.childFloorEquipments && item.childFloorEquipments.filter((e: any) => e.assignedTo && e.assignedTo.id == assignedToFilter).length > 0);
        if (checkOpacity) {
            return '0.3';
        }
        return '0.5';
    }
}
