/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AjaxService, AuthService, BrowserKeys, BuildingAPIPath, CommonService, Environment, FloorAPIPath, PresignedAPIPath, ProjectAPIPath, SiteAPIPath, Utilities } from '@SiteOwl/core';

@Injectable({
  providedIn: 'root'
})
export class FloorPlanService {

  constructor(private http: HttpClient, private ajaxHttp: AjaxService, private readonly env: Environment, private commonService: CommonService, private authService: AuthService) {
  }

  getSiteFloorDetails(buildingId: any, floorId: any): any {
    return this.ajaxHttp.get(`${BuildingAPIPath.building}/${buildingId}/floors/${floorId}/equipments?isIncludeArchived=true`);
  }
  getProjectFloorDetails(projectId: any, buildingId: any, floorId: any): any {
    return this.ajaxHttp.get(`${ProjectAPIPath.project}/${projectId}/building/${buildingId}/floors/${floorId}/equipments?isIncludeArchived=true`);
  }

  getFloorConfiguration(floorId: number) {
    return this.ajaxHttp.get(`${FloorAPIPath.floor}/${floorId}/configuration`);
  }

  getCategories() {
    return this.ajaxHttp.get(`${FloorAPIPath.category}`);
  }
  getNetworkSwitchDevice(id: number, isSite: boolean, buildingId?: any) {
    if (isSite) {
      return this.ajaxHttp.get(BuildingAPIPath.newtWorkSwitches + '?buildingId=' + id);
    } else {
      return this.ajaxHttp.get(BuildingAPIPath.newtWorkSwitches + '?projectId=' + id + '&&buildingId=' + buildingId);
    }
  }

  getBuildingChangeData(id: number, isProject: boolean) {
    if (!isProject) {
      return this.ajaxHttp.get(BuildingAPIPath.buildings + '?siteId=' + id);
    } else {
      return this.ajaxHttp.get(BuildingAPIPath.buildings + '?projectId=' + id);
    }
  }

  //start sync for uploading files to S3 bucket
  startSynCall(id: any, isFromProject: any, floorId: any): any {
    if (!isFromProject) {
      return this.ajaxHttp.get(`${SiteAPIPath.site + '/' + id + "/floor/" + floorId + "/saveProcessStart"}`, null);
    } else {
      return this.ajaxHttp.get(`${ProjectAPIPath.project + '/' + id + "/floor/" + floorId + "/saveProcessStart"}`, null);
    }
  }

  // get presigned url for uploading json and images to s3 bucket.
  getPresignedURLs(reqData: any, images?: any): any {
    if (images && images.length > 0) {
      return this.ajaxHttp.post(`${PresignedAPIPath.presignedUrl + Utilities.objectToParams(reqData)}`, images);
    } else {
      return this.ajaxHttp.get(`${PresignedAPIPath.presignedUrl + Utilities.objectToParams(reqData)}`);
    }
  }
  //Upload image to temporary s3 bucket
  uploadImageToS3Bucket(url: string, formData: any, isStopCall?: boolean): any {
    if (!isStopCall) {
      return;
    } else {
      const uploadContentType = 'multipart/form-data';
      return this.ajaxHttp.put(url, formData, { headers: { 'Content-Type': uploadContentType } }, { removeAuthToken: true });
    }
  }

  //Upload json file to s3 bucket
  uploadJsonFileToServer(url: string, finalArr: any): any {
    const Form = JSON.stringify(finalArr);

    let uploadContentType = 'multipart/form-data';
    if (this.env.localstack) {
      uploadContentType = 'undefined';
    }
    return this.ajaxHttp.put(url, Form, { headers: { 'Content-Type': uploadContentType }, }, { removeAuthToken: true });
  }

  //end sync for saving changes in database
  endSynCall(id: any, isFromProject: any, floorId: any, folderName: any, data: any, stopCall: boolean): any {
    if (!stopCall) {
      return false;
    } else {
      if (!isFromProject) {
        return this.ajaxHttp.put(`${SiteAPIPath.site + '/' + id + "/floor/" + floorId + "/saveProcessEnd?folderName=" + folderName}`, data);
      } else {
        return this.ajaxHttp.put(`${ProjectAPIPath.project + '/' + id + "/floor/" + floorId + "/saveProcessEnd?folderName=" + folderName}`, data);
      }
    }
  }

  getAccessForProject(projectId: any, buildingId: any, floorId: any, deviceId: any, customerId: any) {
    return this.ajaxHttp.get(BuildingAPIPath.projectAccess + projectId + '/building/' + buildingId + '/floor/' + floorId + '/device/' + deviceId + '/access?customerId=' + customerId, {});
  }

  getAccessForSite(siteId: any, buildingId: any, floorId: any, deviceId: any, customerId: any) {
    return this.ajaxHttp.get(BuildingAPIPath.siteAccess + siteId + '/building/' + buildingId + '/floor/' + floorId + '/device/' + deviceId + '/access?customerId=' + customerId, {});
  }
  saveShowDeviceNameChoice(number: number): any {
    return this.ajaxHttp.put(`${FloorAPIPath.showDeviceNameOnEquipment}?showdevicename=${number}`, null);
  }
  saveArchiveDeviceVisiblity(number: number): any {
    return this.ajaxHttp.put(`${FloorAPIPath.showArchiveDevises}?showarchiveddevice=${number}`, null);
  }
  saveFieldOfView(showFieldOfView: number): any {
    return this.ajaxHttp.put(`${FloorAPIPath.showFieldOfView}?showfieldofview=${showFieldOfView}`, null);
  }
  saveShowPartNumberChoice(number: number): any {
    return this.ajaxHttp.put(`${FloorAPIPath.showPartNumberOnEquipment}?showpartnumber=${number}`, null);
  }
  saveAbbreviatedNameChoice(number: number): any {
    return this.ajaxHttp.put(`${FloorAPIPath.showAbbreviatedNameOnEquipment}?showabbreviatedname=${number}`, null);
  }
  saveConnectionLineChoice(number: number): any {
    return this.ajaxHttp.put(`${FloorAPIPath.showConnectionLineOnEquipment}?showconnectionline=${number}`, null);
  }
  saveShowPlanMarkup(actionStatus: number): any {
    return this.ajaxHttp.put(`${FloorAPIPath.showPlanMarkup}?showplanmarkup=${actionStatus}`, null);
  }
  setPinSize(floorId: number, size: any): any {
    return this.ajaxHttp.post(`${FloorAPIPath.floor}/${floorId}/showdevicesize/equipment?showdevicesize=${size}`, {});
  }

  getPrintOutConfigurationColumn(isProject: boolean): any {
    if (isProject) {
      return this.ajaxHttp.get(FloorAPIPath.projectReportConfigColumn);
    } else {
      return this.ajaxHttp.get(FloorAPIPath.siteReportConfigColumn);
    }
  }

  getConfigDeviceFields(): any {
    return this.ajaxHttp.get(`${FloorAPIPath.configReportFavoriteFields}/field`);
  }

  generatePdf(params: any, isBeta: boolean, isProject: boolean): any {
    const data = 'Bearer ' + this.commonService.getLocalStorageString(BrowserKeys.token);

    const httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': data,
        'customerId': this.authService.getCustomerId().customerId.toString()
      }),
      reportProgress: true,
      // responseType: 'text'
    };

    httpOptions['responseType'] = 'text'
    let url = this.env.apiPath;
    if (isBeta) {
      if (!isProject && params.siteId !== undefined && params.siteId > 0) {
        // site
        url += SiteAPIPath.site + '/' + params.siteId + '/floor/' + params.floorId + '/report/numbered'
      } else {
        // project
        url += ProjectAPIPath.project + '/' + params.projectId + '/floor/' + params.floorId + '/report/numbered'
      }
    } else {
      if (!isProject && params.siteId !== undefined && params.siteId > 0) {
        // site
        url += SiteAPIPath.site + '/' + params.siteId + '/floor/' + params.floorId + '/report/icon'
      } else {
        // project
        url += ProjectAPIPath.project + '/' + params.projectId + '/floor/' + params.floorId + '/report/icon'
      }
    }
    const req = new HttpRequest('POST', `${url}`, params, httpOptions);

    return this.http.request(req);
  }

  multiPlanPrintOut(id: any, params: any, isFromSite: any) {
    const data = 'Bearer ' + this.commonService.getLocalStorageString(BrowserKeys.token);
    const httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': data,
        'customerId': this.authService.getCustomerId().customerId.toString()
      }),
      reportProgress: true,
      // responseType: 'text'
    };

    httpOptions['responseType'] = 'text'
    let url = this.env.apiPath;
    if (this.env.envName === 'Staging' || this.env.envName === 'live') {
      url = this.env.reportApiPath;
    }
    if (isFromSite) {
      url += SiteAPIPath.site + '/' + id + '/multiPlanPrint';
    } else {
      url += ProjectAPIPath.project + '/' + id + '/multiPlanPrint';
    }

    const req = new HttpRequest('POST', `${url}`, params, httpOptions);

    return this.http.request(req);
  }

  getDeviceHistory(floorequipment: any) {
    return this.ajaxHttp.get(`${FloorAPIPath.history}/${floorequipment}`);
  }

  getDefaultCategory() {
    return this.ajaxHttp.get(`${FloorAPIPath.category}`);
  }
  getFavoriteEquipmentList(): any {
    return this.ajaxHttp.get(FloorAPIPath.customerFavoriteEquipment);
  }
  getLiveDevices(id: any) {
    return this.ajaxHttp.get(`${FloorAPIPath.liveDevicesOnProject}/${id}/equipments`);
  }
  uploadCsvFavorite(file: any, isPreview: boolean, isProjectImport: boolean): any {
    const formdata = new FormData();
    formdata.append("file", file);
    return this.ajaxHttp.post(`${FloorAPIPath.favouriteDeviceFields}?isPreview=${isPreview ? 'true' : 'false'}&isProjectImport=${isProjectImport ? 'true' : 'false'}`, formdata);
  }

  deleteAllFavoritesOfCustomer() {
    return this.ajaxHttp.delete(FloorAPIPath.deleteAllFavorites);
  }
}
