/* eslint-disable @typescript-eslint/no-explicit-any */
import { Subject, fromEvent, map, switchMap, take, takeUntil } from "rxjs";
import { PointStore } from "./ps"
import * as _ from 'lodash';
import { OnDestroy } from "@angular/core";
export class Point {
    static points = [];
    x: any = 0;
    y: any = 0;
    elm: any;
    metaPoint: Point[] = [];
    type = "M"
    container: any;
    guideContainer: any;
    elmHidden!: SVGCircleElement;
    id: any
    isDragDot = false;
    pathDive: HTMLElement | null;
    isDragEventsInitialize = false;
    public readonly destroy$ = new Subject<void>();
    dragEnd$: any;
    selectedDevice: any
    circleTargetID: any
    circleTargetDeviceID: any;
    constructor(x: any, y: any, id: any, index: any, selectedDevice: any, deSelectedDevice: any) {
        if (!deSelectedDevice) {
            if (selectedDevice !== undefined) {
                const text: any = document.getElementById('circle-text-path' + selectedDevice.id);
                const Hpoint = document.getElementById('circle-hover-path' + selectedDevice.id);
                if (Hpoint) {
                    Hpoint.remove()
                }
                if (text) {
                    text.remove()
                }
                this.isDragDot = false;
            }
            this.container = null;
            this.pathDive = null;
            this.guideContainer = null;
            this.id = null;
            this.selectedDevice = null;
            selectedDevice = null
        } else {
            this.container = document.getElementById("svg-cable-" + selectedDevice.id);
            this.pathDive = document.getElementById("cable-path-modify");
            this.guideContainer = document.getElementById("guideContainer-cable-" + selectedDevice.id)
            this.id = selectedDevice.id;
            this.selectedDevice = selectedDevice;
            // console.log(this.selectedDevice)
            this.x = x;
            this.y = y;
            if (selectedDevice.id === id) {
                if (document.getElementById('circle-hover-path' + this.selectedDevice.id) == null || document.getElementById('circle-hover-path' + this.selectedDevice.id) == undefined) {
                    this.createImageHiddenPoint()
                }
                this.elm = undefined;
                if (!this.selectedDevice.isArchived && !this.selectedDevice.isLiveDevice) {
                    this.elm = this.createPoint(selectedDevice.id, index);
                    this.elmHidden = this.createHiddenPoint(selectedDevice.id, index);
                    this.elm.onclick = (e: any) => {

                        if (!this.isDragDot) {
                            let circleTargetID: any = e.target.id.split('-')[e.target.id.split('-').length - 1];
                            circleTargetID = parseInt(circleTargetID);
                            if (PointStore.getAllPoints().length < circleTargetID) {
                                circleTargetID = PointStore.getAllPoints().length
                            }
                            if (circleTargetID > 1 && circleTargetID <= (PointStore.getAllPoints().length)) {

                                e.stopPropagation();
                                PointStore.points.forEach((i: any) => {
                                    i.getNode().classList.remove("selectedNode");
                                });
                                PointStore.selectedPoint = this;
                                this.elm.classList.add("selectedNode");
                                this.elmHidden.classList.add("selectedNode");
                                PointStore.removeSelectedNode();

                            }
                        }
                    };

                    this.elmHidden.onclick = e => {
                        e.stopPropagation();
                        PointStore.points.forEach((i: any) => {
                            i.getNode().classList.remove("selectedNode");
                        });
                        PointStore.selectedPoint = this;
                    };
                    this.initDrag()
                }

            }
            this.container.onmouseout = (e: any) => {
                this.hidePoint();
                this.isDragDot = false;
            }
            this.container.onmousemove = (e: any) => {
                const tmpPoint: any = PointStore.getAllPoints();
                let hoverId = parseInt(e.target.id.split('-')[e.target.id.split('-').length - 2]) === 0 ? parseFloat(e.target.id.split('-')[e.target.id.split('-').length - 2]) : parseInt(e.target.id.split('-')[e.target.id.split('-').length - 2]);
                if (isNaN(hoverId)) {
                    hoverId = parseInt(e.target.id.split('-')[e.target.id.split('-').length - 1]) === 0 ? parseFloat(e.target.id.split('-')[e.target.id.split('-').length - 1]) : parseInt(e.target.id.split('-')[e.target.id.split('-').length - 1]);
                }
                if (PointStore.getAllPoints().length < 22 && PointStore.getAllPoints().length > 0 && hoverId === this.selectedDevice.id && !this.selectedDevice.isArchived && !this.selectedDevice.isLiveDevice) {
                    const Hpoint: any = document.getElementById('circle-hover-path' + this.selectedDevice.id);
                    const text: any = document.getElementById('circle-text-path' + this.selectedDevice.id);
                    if (Hpoint !== null && e.target && e.target.id && !e.target.id.includes('circle-') && this.selectedDevice && tmpPoint[0]['id'] === this.selectedDevice.id) {
                        const offset = this.container.getBoundingClientRect();
                        let x: any = e.x - offset.left;
                        let y: any = e.y - offset.top;
                        x = (100 * x / this.container.clientWidth).toFixed(5) + '%';
                        y = (100 * y / this.container.clientHeight).toFixed(5) + '%';
                        Hpoint.setAttribute("style", "opacity:1");
                        Hpoint.setAttribute("cx", x);
                        Hpoint.setAttribute("cy", y);
                        text.setAttribute("style", "opacity:1");
                        text.setAttribute("x", x);
                        text.setAttribute("y", y);
                    }
                }
            }
        }
    }

    hidePoint() {
        const text: any = document.getElementById('circle-text-path' + this.selectedDevice.id);
        const Hpoint = document.getElementById('circle-hover-path' + this.selectedDevice.id);
        if (Hpoint !== null) {
            Hpoint.setAttribute("style", "opacity:0");
            text.setAttribute("style", "opacity:0");
        }
    }
    initDrag() {
        if (!this.isDragEventsInitialize) {
            this.isDragEventsInitialize = true;
            const dragStart$ = fromEvent<MouseEvent>(this.elm, "mousedown");
            this.dragEnd$ = fromEvent<MouseEvent>(document.body, "mouseup")
            dragStart$.pipe(switchMap((start) => {
                this.cableDragStart(start)
                const offset = this.container.getBoundingClientRect();
                const clientWidth = this.container.clientWidth;
                const clientHeight = this.container.clientHeight;
                const updatePreviousLine: any = start.isTrusted ? document.getElementById('line-cable-' + this.circleTargetDeviceID + '-' + (parseInt(this.circleTargetID) - 1)) : null;
                const updateNextLine: any = start.isTrusted ? document.getElementById('line-cable-' + this.circleTargetDeviceID + '-' + parseInt(this.circleTargetID)) : null;
                this.dragEnd$.pipe(take(1)).subscribe((event: MouseEvent) => {
                    this.cableDragEnd(event);
                });
                const drag$ = fromEvent<MouseEvent>(document.body, "mousemove").pipe(takeUntil(this.dragEnd$));
                return drag$.pipe(map(move => {
                    move.preventDefault();
                    this.cableDragging(move, offset, clientWidth, clientHeight, updatePreviousLine, updateNextLine);
                    return move;
                }), takeUntil(this.dragEnd$));
            })).subscribe();




        }
    }
    cableDragEnd(e: any) {
        if (this.isDragDot) {
            e.stopPropagation();
            e.preventDefault();
            this.circleTargetDeviceID = undefined
            PointStore.points.forEach((i: any) => {
                i.getNode().classList.remove("selectedNode");
            });
            if (document.querySelectorAll('.point-hide')) {
                const elems: any = document.querySelectorAll('.point-hide');
                const length = elems.length;
                let index = 0
                for (; index < length; index++) {
                    elems[index].setAttribute("r", "10");
                }
            }
            PointStore.notify(true);
            this.circleTargetID = null
            const timeOut = setTimeout(() => {
                this.isDragDot = false
                clearTimeout(timeOut);
            }, 100);
        }
    }
    cableDragging(e: any, offset: any, clientWidth: any, clientHeight: any, updatePreviousLine: any, updateNextLine: any) {
        if (e.which == 1 && this.circleTargetID > 1 && PointStore.points.length > this.circleTargetID && this.circleTargetDeviceID === this.selectedDevice.id) {
            this.hidePoint();
            this.isDragDot = false;
            e.stopPropagation();
            let x: any = e.x - offset.left;
            let y: any = e.y - offset.top;
            if (e.target.id && e.target.id !== 'parentContainer' && parseInt(this.circleTargetID) > 0 && PointStore.points.length > 0) {
                if (PointStore.points.filter((z: any) => z.id === parseInt(this.circleTargetID)).length > 0) {
                    this.isDragDot = true;
                    x = (100 * x / clientWidth).toFixed(5) + '%';
                    y = (100 * y / clientHeight).toFixed(5) + '%';
                    if (x !== 'Infinity%' && y !== 'Infinity%') {
                        this.update(x, y);
                        // console.log(PointStore.points, '====>dragging', this.selectedDevice.id, '===>', this.id)
                        PointStore.points.filter((z: any) => z.id === parseInt(this.circleTargetID))[0].x = x;
                        PointStore.points.filter((z: any) => z.id === parseInt(this.circleTargetID))[0].y = y;
                        const animationId = requestAnimationFrame(() => {
                            if (!updateNextLine) {
                                updateNextLine = document.getElementById('line-cable-' + this.circleTargetDeviceID + '-' + parseInt(this.circleTargetID));
                            }
                            if (!updatePreviousLine) {
                                updatePreviousLine = document.getElementById('line-cable-' + this.circleTargetDeviceID + '-' + (parseInt(this.circleTargetID) - 1));
                            }
                            if (updateNextLine) {
                                updateNextLine.setAttribute("x1", x);
                                updateNextLine.setAttribute("y1", y)
                            }
                            if (updatePreviousLine) {
                                updatePreviousLine.setAttribute("x2", x);
                                updatePreviousLine.setAttribute("y2", y)
                            }
                            cancelAnimationFrame(animationId)
                        });
                    } else {
                        this.isDragDot = false;
                        this.circleTargetID = null;
                    }
                    // PointStore.notify(false);
                }
            }
        }
    }
    cableDragStart(e: any) {

        this.circleTargetID = parseInt(e.target.id.split('-')[e.target.id.split('-').length - 1]);
        this.circleTargetDeviceID = parseInt(e.target.id.split('-')[e.target.id.split('-').length - 2]) === 0 ? parseFloat(e.target.id.split('-')[e.target.id.split('-').length - 2]) : parseInt(e.target.id.split('-')[e.target.id.split('-').length - 2]);
        const hide: any = document.getElementById("circle-hide-" + this.id + "-" + parseInt(this.circleTargetID))
        if (hide) {
            hide.setAttribute("r", "500");
        }
        e.stopPropagation();
        this.isDragDot = false
    }
    createPoint(id: any, index: any) {
        const point = document.createElementNS("http://www.w3.org/2000/svg", "circle");
        point.setAttribute("stroke", "#262626");
        point.setAttribute("class", "point");
        point.setAttribute("stroke-width", "2");
        point.setAttribute("fill", "#262626");
        point.setAttribute("r", "10");
        point.setAttribute("style", "pointer-events:visible");
        point.setAttribute("id", "circle-" + id + "-" + index);
        return point
    }

    createImageHiddenPoint() {
        const remPoint: any = document.querySelectorAll(`[id^="circle-hover-path"]`);
        if (remPoint && remPoint.length > 0) {
            for (const element of remPoint) {

                element.innerHTML = '';
                element.remove();
            }
        }
        const remTextPoint: any = document.querySelectorAll(`[id^="circle-text-path"]`);
        if (remTextPoint && remTextPoint.length > 0) {
            for (const element of remTextPoint) {

                element.innerHTML = '';
                element.remove();
            }
        }
        const Hpoint = document.createElementNS("http://www.w3.org/2000/svg", "circle");
        Hpoint.setAttribute("stroke", "#262626");
        Hpoint.setAttribute("class", "point-hide");
        Hpoint.setAttribute("stroke-width", "2");
        Hpoint.setAttribute("fill", "#FFFFFF");
        Hpoint.setAttribute("r", "10");
        Hpoint.style.pointerEvents = 'visible';
        Hpoint.style.opacity = '0';
        Hpoint.style.zIndex = '999';
        Hpoint.setAttribute("id", "circle-hover-path" + this.selectedDevice.id);
        Hpoint.setAttribute('cx', '0%');
        Hpoint.setAttribute('cy', '0%');
        const text = document.createElementNS("http://www.w3.org/2000/svg", "text");
        text.textContent = "+";
        text.setAttribute("class", "cable-plus");
        text.setAttribute("fill", "#00B2F8");
        text.setAttribute("x", "0%");
        text.setAttribute("y", "0%");
        text.style.opacity = '0';
        text.style.zIndex = '999';
        text.setAttribute("id", "circle-text-path" + this.selectedDevice.id);
        text.setAttribute("name", "circle-text-path");
        text.setAttribute("text-anchor", "middle");
        text.setAttribute("dominant-baseline", "middle");
        const dotContainerDom = document.getElementById("svg-cable-" + this.selectedDevice.id);
        if (dotContainerDom) {
            dotContainerDom.appendChild(Hpoint)
            dotContainerDom.appendChild(text);
        }
        return Hpoint
    }
    createHiddenPoint(id: any, index: any) {
        const Hpoint = document.createElementNS("http://www.w3.org/2000/svg", "circle");
        Hpoint.setAttribute("stroke", "#262626");
        Hpoint.setAttribute("class", "point-hide");
        Hpoint.setAttribute("stroke-width", "2");
        Hpoint.setAttribute("fill", "#262626");
        Hpoint.setAttribute("r", "10");
        Hpoint.setAttribute("style", "pointer-events:visible");
        Hpoint.setAttribute("style", "opacity:0");
        Hpoint.setAttribute("id", "circle-hide-" + id + "-" + index);

        const guide: any = document.getElementById('guideContainer-cable-' + id);
        guide.appendChild(Hpoint)
        return Hpoint
    }

    update(x: any, y: any) {
        this.x = x || this.x;
        this.y = y || this.y;
        try {
            if (this.x !== undefined && this.y !== undefined && this.x !== '' && this.y !== '') {
                this.elm.setAttribute("cx", this.x.toString());
                this.elm.setAttribute("cy", this.y.toString());

                this.elmHidden.setAttribute("cx", this.x.toString());
                this.elmHidden.setAttribute("cy", this.y.toString());
            }
        } catch (e) {
            console.log(e)
        }
    }

    getNode() {
        this.update(this.x, this.y);
        return this.elm;
    }

    getPath() {
        return { path: `M${this.x},${this.y}`, guide: '' };
    }
}


export class Factory {
    static createPath(x: any, y: any, id: any, index: any, selectedDevice: any, isDeSelectedDevice: any) {
        return new Point(x, y, id, index, selectedDevice, isDeSelectedDevice);
    }
} 