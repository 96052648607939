import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { modulesFloorPlanRoutes } from './lib.routes';
import { FloorDetailsComponent } from './floor-details/floor-details.component';
import { FloorComponent } from './floor/floor.component';
import { UiModule } from '@SiteOwl/UI';
import { FloorCategoryFilterPipe } from './pipe/floor-category-filter.pipe';
import { DraggableDeviceDirective } from './directive/draggable-device.directive';
import { EquipmentDetailsComponent } from './floor-details/equipment-details/equipment-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EquipmentListComponent } from './floor-details/equipment-list/equipment-list.component';
import { EquipmentDetailsLabelComponent } from './floor-details/equipment-details/equipment-details-label/equipment-details-label.component';
import { FloorHeaderComponent } from './floor-details/floor-header/floor-header.component';
import { CoverageRangeDirective } from './directive/coverage-range.directive';
import { CoverageRotateDirective } from './directive/coverage-rotate.directive';
import { CablePathComponent } from './floor-details/cable-path/cable-path.component';
import { CoverageLeftDirective } from './directive/coverage-left.directive';
import { CoverageRightDirective } from './directive/coverage-right.directive';
import { ContextMenuComponent } from './floor-details/context-menu/context-menu.component';
import { FloorCreateTicketComponent } from './floor-details/floor-create-ticket/floor-create-ticket.component';
import { FloorLinkTicketComponent } from './floor-details/floor-link-ticket/floor-link-ticket.component';
import { AddFlagComponent } from './floor-details/add-flag/add-flag.component';
import { ImageGroupByPipe } from './pipe/image-group-by.pipe';
import { RedirectionComponent } from './component/redirection/redirection.component';
import { EquipmentFilterCountComponent } from './floor-details/equipment-filter-count/equipment-filter-count.component';
import { EquipmentCountFilterPipe } from './pipe/equipment-count-filter.pipe';
import { PrintOutPopupComponent } from './component/print-out-popup/print-out-popup.component';
import { SyncPopupComponent } from './sync-popup/sync-popup.component';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { NumberedPlanReportComponent } from './component/numbered-plan-report/numbered-plan-report.component';
import { IconPlanReportComponent } from './component/icon-plan-report/icon-plan-report.component';
import { NgxSliderModule } from 'ngx-slider-v2';
import { AuditPipe } from './pipe/audit.pipe';
import { TicketPipe } from './pipe/ticket.pipe';
import { EquipmentHistoryComponent } from './component/equipment-history/equipment-history.component';
import { HistoryPipe } from './pipe/history.pipe';
import { SavePlanPopupComponent } from './component/save-plan-popup/save-plan-popup.component';
import { OrderbyPipe } from './pipe/orderby.pipe';
import { FilterFavDeletedDevicePipe } from './pipe/filter-fav-deleted-device.pipe';
import { CategoryFavoriteFilterPipe } from './pipe/category-favorite-filter.pipe';
import { DragulaModule } from 'ng2-dragula';
import { FavImportExportComponent } from './floor-details/fav-import-export/fav-import-export.component';
import { PreviewImportComponent } from './floor-details/preview-import/preview-import.component';
import { RoutingConfirmationComponent } from './floor-details/routing-confirmation/routing-confirmation.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(modulesFloorPlanRoutes),
    UiModule,
    ReactiveFormsModule,
    ProgressbarModule.forRoot(),
    FormsModule,
    NgxSliderModule,
    DragulaModule.forRoot(),
  ],
  declarations: [
    FloorDetailsComponent,
    FloorComponent,
    FloorCategoryFilterPipe,
    DraggableDeviceDirective,
    EquipmentDetailsComponent,
    EquipmentListComponent,
    EquipmentDetailsLabelComponent,
    FloorHeaderComponent,
    CoverageRangeDirective,
    CoverageRotateDirective,
    CablePathComponent,
    CoverageLeftDirective,
    CoverageRightDirective,
    ContextMenuComponent,
    FloorCreateTicketComponent,
    FloorLinkTicketComponent,
    AddFlagComponent,
    ImageGroupByPipe,
    RedirectionComponent,
    EquipmentFilterCountComponent,
    EquipmentCountFilterPipe,
    PrintOutPopupComponent,
    SyncPopupComponent,
    NumberedPlanReportComponent,
    IconPlanReportComponent,
    AuditPipe,
    TicketPipe,
    EquipmentHistoryComponent,
    HistoryPipe,
    SavePlanPopupComponent,
    OrderbyPipe,
    FilterFavDeletedDevicePipe,
    CategoryFavoriteFilterPipe,
    FavImportExportComponent,
    PreviewImportComponent,
    RoutingConfirmationComponent,
  ],
  bootstrap: [FloorComponent],
  exports: [
    FloorCategoryFilterPipe,
    FloorHeaderComponent,
    CablePathComponent,
    RedirectionComponent,
    PrintOutPopupComponent,
    SyncPopupComponent,
    NumberedPlanReportComponent,
    IconPlanReportComponent,
    EquipmentHistoryComponent,
    SavePlanPopupComponent,
  ],
})
export class ModulesFloorPlanModule {}
