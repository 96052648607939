/* eslint-disable @typescript-eslint/no-explicit-any */
import { Point } from "./point"
import * as _ from 'lodash';

class PS {
    points: Point[] = [];
    guidePoints = [];
    selectedPoint: any = Point;
    notify(isEmit: any) {
        //Empty for triggering event of cable path 
    }

    addNewPoint(point: any, index: any, id: any) {
        point.id = index;
        point.equipmentId = id
        if (this.points && this.points.length > 0 && this.points.filter((x: any) => x.equipmentId === id && x.index === index).length > 0) {
            this.points.filter((x: any) => x.equipmentId === id && x.index === index)[0] = point;
        } else {
            this.points.push(point);
        }
        this.points = _.cloneDeep(_.uniqWith(this.points, _.isEqual));
    }


    getPath() {
        const path: any = this.points.reduce((a: any, b: any) => {
            return [...a, b.getPath().path];
        }, []);

        const guide = this.points.reduce((a: any, b: any) => {
            return [...a, b.getPath().guide];
        }, []);
        return { path: path.join(" "), guide: guide.join(" ") };
    }

    removeSelectedNode() {

        const i = this.points.findIndex((e: any) => e.id === this.selectedPoint.id);
        const removedNodes: SVGCircleElement[] = [];
        if (i > 0 && i < this.points.length - 1) {
            for (const sn of this.selectedPoint.metaPoint) {
                removedNodes.push(sn.getNode());
            }
            removedNodes.push(this.selectedPoint.getNode());
            removedNodes.push(this.selectedPoint.elmHidden);
            this.selectedPoint.metaPoint.length = 0;

            const boxes = document.querySelectorAll('.selectedNode');
            boxes.forEach(box => {
                box.remove();
            });
            this.points.splice(i, 1);
            const timeOut = setTimeout(() => {
                PointStore.notify(true);
                clearTimeout(timeOut)
            }, 0);
        }
        return removedNodes;
    }

    removeAllNodes() {
        return new Promise((resolve: any, reject: any) => {
            const removedNodes: SVGCircleElement[] = [];
            for (let i = this.points.length - 1; i >= 0; i--) {
                const cr = this.points[i];
                if (cr.getNode()) {
                    removedNodes.push(cr.getNode());
                    for (const sn of cr.metaPoint) {
                        removedNodes.push(sn.getNode());
                    }
                    cr.metaPoint.length = 0;
                    if (document.getElementById(this.points[i].elm.id)) {
                        document.getElementById(this.points[i].elm.id)?.removeEventListener('mousemove', () => { })
                        const removeCircle: any = document.getElementById(this.points[i].elm.id)
                        removeCircle.innerHTML = '';
                        removeCircle.remove();
                    }
                    if (document.getElementById(this.points[i].elmHidden.id)) {
                        document.getElementById(this.points[i].elmHidden.id)?.removeEventListener('mousemove', () => { })
                        const removeHCircle: any = document.getElementById(this.points[i].elmHidden.id)
                        removeHCircle.innerHTML = '';
                        removeHCircle.remove();
                    }
                    this.points.splice(i, 1);
                }
            }
            this.points.length = 0;
            resolve(true);
        })
    }

    getPreciousPoint(point: Point) {
        const index = this.points.indexOf(point);
        return this.points[index - 1];
    }

    getLastPoint() {
        const index = this.points.length - 1;
        return this.points[index];
    }

    getAllPoints() {
        const allPoints: any = [];
        this.points.forEach((element: any, index) => {
            allPoints.push({ x: element.x, y: element.y, index: index + 1, id: element.equipmentId });
        });
        return allPoints;
    }

    removeAllPoints() {
        return new Promise((resolve: any, reject: any) => {
            if (this.points && this.points.length > 0) {
                this.removeAllNodes().then((r: any) => {
                    this.points = [];
                    resolve(true);
                });
            } else {
                resolve(true);
            }
        });
    }
}

const PointStore = new PS();

export { PointStore }