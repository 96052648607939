/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'so-save-plan-popup',
  templateUrl: './save-plan-popup.component.html',
  styleUrls: ['./save-plan-popup.component.scss'],
})
export class SavePlanPopupComponent {

  constructor(public bsModalRef: BsModalRef) { }
  totalDiscardCnt!: any;
  public callSyncEvent: EventEmitter<any> = new EventEmitter();
  public callDiscardEvent: EventEmitter<any> = new EventEmitter();
  closeModal() {
    this.callDiscardEvent.emit();
  }

  saveChanges() {
    this.callSyncEvent.emit();
  }
}
