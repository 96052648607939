export const Discard = {
    dialogId: 'Discard-Changes',
    header: 'Discard Changes',
    content: 'Are you sure want to discard your changes to {{#count}}? Once discarded, you will not be able to recover these changes.',
    btnSuccessText: 'Discard Change',
    btnCancelText: 'Cancel',
    btnSuccessClass: 'btn-danger',
    btnCancelClass: 'btn-secondary',
    btnSuccessStyle: '',
    bodyClass: '',
    bodyStyle: '',
    dialogClass: '',
    dialogStyle: '',
}