export const DevicePinSizeValue: any = {
    "0.3": 18,
    "0.4": 24,
    "0.5": 30,
    "0.6": 36,
    "0.7": 42,
    "0.8": 48,
    "0.9": 54,
    "1": 60,
}

export const DraggingPinSize: any = {
    "18": 8,
    "24": 7,
    "30": 6,
    "36": 5,
    "42": 4,
    "48": 3,
    "54": 2,
    "60": 1
}
export const NewDraggingPinSize: any = {
    "18": 2,
    "24": 2,
    "30": 3,
    "36": 1,
    "42": 0,
    "48": -1,
    "54": -3,
    "60": -4
}

export const DBDevicePinSizeValue: any = {
    18: "0.3",
    24: "0.4",
    30: "0.5",
    36: "0.6",
    42: "0.7",
    48: "0.8",
    54: "0.9",
    60: "1",
}

export const floorPlanFavDeviceUsedKeys = [
    "isDeleted", "name", "pinColor", "partNumber", "manufacturer", "installHours", "price",
    "dataType", "deviceLocation", "description", "orderNumber", "childFavouriteEquipments", "id", "equipment", "type", "abbreviatedName","budgetedCost"
  ];