import { Utilities } from '@SiteOwl/core';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'categoryFavoriteFilter'
})
export class CategoryFavoriteFilterPipe implements PipeTransform {

  defaultArr = []
  checkchildren(parent: any, searchText: any) {
    if (!parent.childFavouriteEquipments) {
      parent.childFavouriteEquipments = []
    }
    return parent.childFavouriteEquipments.some((child: any) =>
      (child.name && child.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
      || (child.typeName && child.typeName.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
      || (child.partNumber && child.partNumber.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
    )
  }

  checkFullName(items: any, searchText: any) {
    return items.some((x: any) => (x.name && x.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
      || (x.typeName && x.typeName.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
      || (x.partNumber && x.partNumber.toLowerCase().indexOf(searchText.toLowerCase()) > -1) ||
      this.checkchildren(x, searchText)
    );
  }

  transform(items: any, ...args: any[]): any {
    if (!items) {
      return items;
    }
    if (this.defaultArr.length === 0 || items.length !== this.defaultArr.length) {
      this.defaultArr = Object.assign([], items)
    }

    const searchText = args[0] ? args[0].trim() : ''
    const searchKeys = searchText.split(' ')

    if (!Utilities.isEmpty(searchText)) {
      if (searchKeys.length === 1 || this.checkFullName(items, searchText)) {
        items = items.filter((x: any) => (x.name && x.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
          || (x.typeName && x.typeName.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
          || (x.partNumber && x.partNumber.toLowerCase().indexOf(searchText.toLowerCase()) > -1) ||
          this.checkchildren(x, searchText)
        );
      } else {

        searchKeys.forEach((searchText: any) => {
          if (!Utilities.isEmpty(searchText)) {
            items = this.defaultArr.filter((x: any) => (x.name && x.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
              || (x.typeName && x.typeName.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
              || (x.partNumber && x.partNumber.toLowerCase().indexOf(searchText.toLowerCase()) > -1) ||
              this.checkchildren(x, searchText)
            )
            this.defaultArr = items
          }

        });

      }

    }

    return items;
  }
  ngOnDestroy() {
    this.defaultArr = []
  }

}
