import { Directive, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';
import { map } from 'rxjs/internal/operators/map';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import * as _ from 'lodash';
import { CoverageCone } from '../utils/coverage-cone';
import { take } from 'rxjs';
import { EventEmitterType, EventService } from '@SiteOwl/core';

@Directive({
  selector: '[soCoverageRight]'
})
export class CoverageRightDirective implements OnInit, OnDestroy, OnChanges {

  @Input() isEdit: any = true;
  @Input() device: any;
  @Output() updateCoverage = new EventEmitter();
  item: any;
  newAngle!: number;
  newDirection!: number;
  dragActive = false;
  prevX: any;
  prevY: any;
  center!: {
    x: 0;
    y: 0;
  };
  timer: any;
  dragEventAdded = false;
  private readonly destroy$ = new Subject<void>();
  constructor(private elementRef: ElementRef,
    private eventService: EventService
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['device']) {
      this.device = changes['device'].currentValue;
    }
  }
  ngOnInit(): void {
    this.elementRef.nativeElement.style.cursor = 'grab'
    this.initDragEvents();
  }
  initDragEvents() {
    this.dragEventAdded = true;
    const dragStart$ = fromEvent<MouseEvent>(this.elementRef.nativeElement, "mousedown");
    // if (!this.device.parentId && !this.isNew) {
    const dragEnd$ = fromEvent<MouseEvent>(window, "mouseup")
    const dragElementEnd$ = fromEvent<MouseEvent>(this.elementRef.nativeElement, "mouseup")
    // }
    const drag$ = fromEvent<MouseEvent>(document.body, "mousemove").pipe(takeUntil(dragEnd$), takeUntil(dragElementEnd$), takeUntil(this.destroy$));


    dragStart$.pipe(takeUntil(this.destroy$), switchMap((start) => {
      dragEnd$.pipe(take(1))
        .subscribe(($event: any) => {
          this.dragEnd($event);
        });
      this.dragStart(start)
      return drag$.pipe(map(move => {
        move.preventDefault();
        this.dragging(move);
        return move;
      }), takeUntil(dragEnd$), takeUntil(dragElementEnd$));
    })).subscribe();

    dragElementEnd$.pipe(takeUntil(this.destroy$))
      .subscribe(($event: any) => {
        this.dragEnd($event);
      });
  }
  dragStart(event: any) {
    this.eventService.broadcast(EventEmitterType.floorEquipmentSelection, true);
    event.stopPropagation();
    this.prevX = event.clientX;
    this.prevY = event.pageY;
    this.dragActive = true;
  }
  dragging(event: any) {
    if (this.dragActive) {
      event.stopPropagation();
      this.elementRef.nativeElement.style.cursor = 'grabbing'
      const myNewSvg: any = document.getElementById("coveragesvg1_" + this.device.id + '_' + this.device.name)?.getBoundingClientRect();
      let startAngle = this.device.coverageDirection + (this.device.coverageAngle / 2);
      if (startAngle > 360) {
        startAngle = startAngle - 360
      }
      let d;
      const y2 = event.clientY,
        x2 = event.clientX,
        y1 = myNewSvg.top,
        x1 = myNewSvg.left;
      d = Math.atan2(y2 - y1, x2 - x1);
      d = Math.round(d * 180 / Math.PI)
      if (d < 0) {
        d = 360 + d
      }
      d = d + 90
      d = d % 360

      const diffAngle = d - startAngle
      this.newAngle = Math.round(this.device.coverageAngle + diffAngle);
      this.checkAngle(event);
      this.newDirection = Math.round(this.device.coverageDirection - ((this.device.coverageAngle - this.newAngle) / 2));
      this.checkDirection(event);
      if (this.newDirection >= 0 && this.newDirection <= 360 && this.newAngle >= 0 && this.newAngle <= 360) {
        const d_str = CoverageCone.describeArc(0, 0, 100, this.newDirection - 1 / 2 * this.newAngle,
          this.newDirection + 1 / 2 * this.newAngle);
        if (document.getElementById('arc1_' + this.device.id + '_' + this.device.name)) {
          document.getElementById('arc1_' + this.device.id + '_' + this.device.name)?.setAttribute("d", d_str)
        }
        const styleEffect = document.getElementById('arc_g_div_RightSide_' + this.device.id + '_' + this.device.name);
        if (styleEffect) {
          const animationId = requestAnimationFrame(() => {
            styleEffect.style.transform = "rotate(" + diffAngle + "deg)";
            cancelAnimationFrame(animationId)
          });
        }
      }
    }
  }
  checkDirection(e: any) {
    if (this.newDirection < 0) {
      this.newDirection = 360 + this.newDirection
    } else if (this.newDirection > 360) {
      this.newDirection = this.newDirection - 360
    }

    if (this.newDirection > 360) {
      this.checkDirection(this.newDirection)
    }
  }
  checkAngle(e: any) {
    if (this.newAngle > 360) {
      this.newAngle = this.newAngle - 360
    } else if (this.newAngle < 0) {
      this.newAngle = 360 + this.newAngle
    } else if (this.newAngle === 0) {
      this.newAngle = 360
    }
    if (this.newAngle > 360) {
      this.checkAngle(this.newAngle)
    }
  }
  dragEnd(event: any) {
    if (this.dragActive) {
      this.eventService.broadcast(EventEmitterType.floorEquipmentSelection, false);
      event.stopPropagation();
      event.preventDefault();
      this.elementRef.nativeElement.style.cursor = 'grab'
      this.dragActive = false;
      if (!isNaN(this.newAngle) && !isNaN(this.newDirection)) {
        this.device.coverageAngle = this.newAngle
        this.device.coverageDirection = this.newDirection;
        const styleEffect = document.getElementById('arc_g_div_RightSide_' + this.device.id + '_' + this.device.name);
        if (styleEffect) {
          styleEffect.style.transform = "";
        }
        this.updateCoverage.emit(this.device);
      }
      console.log('coverage right end', Date.now())
    }
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
