/* eslint-disable @typescript-eslint/no-explicit-any */
import { OptimizedReport } from '@SiteOwl/core';
import { Options } from 'ngx-slider-v2';
import { ChangeDetectionStrategy, Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'so-numbered-plan-report',
  templateUrl: './numbered-plan-report.component.html',
  styleUrls: ['./numbered-plan-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberedPlanReportComponent {
  pinSizeValue: any;
  pinSizeOptions: Options = {
    floor: 18,
    ceil: 60,
    step: 6,
    showTicks: true
  };
  floorId!: any;
  pinColor!: any;
  assignedToFilter!: any
  assignedTo: any = null;
  installByUserItems!: any;
  filterCategory!: any;
  projectId!: any;
  isFromSite!: any;
  optimizedReportOptions = OptimizedReport
  optimizedFor: any = OptimizedReport[0].value;
  user!: any
  public event: EventEmitter<any> = new EventEmitter();

  constructor(public bsModalRef: BsModalRef) { }
  closeModal() {
    this.bsModalRef.hide();
  }

  openColumnConfig() {
    const reportViewPrefrence: any = this.filterCategory.filter((x: any) => x.isChecked && x.type === 'vp');
    const systemTypeFilter: any = this.filterCategory.filter((x: any) => x.isChecked && x.type === 'st');
    const deviceStatus: any = this.filterCategory.filter((x: any) => x.isChecked && x.type === 'op');
    const installStatus: any = this.filterCategory.filter((x: any) => x.isChecked && x.type === 'is');
    const downloadConfig: any = {
      floorId: this.floorId,
      viewingPreference: reportViewPrefrence,
      systemTypeFilter: systemTypeFilter,
      deviceStatus: deviceStatus,
      installStatus: installStatus,
      equipmentSize: this.pinSizeValue,
      pinColor: this.pinColor,
      assignedTo: this.assignedTo !== null && this.assignedTo !== undefined ? parseInt(this.assignedTo) : null,
      optimizedFor: this.optimizedFor,
      liveSiteReportField: ""
    }
    this.event.emit(downloadConfig);

  }
  pinSizeChange(e: any) {
    this.pinSizeValue = e;
  }
  optimizedChanged(item: any) {
    this.optimizedFor = item.value
  }
  filterCategoryChanged(item: any) {
    item.isChecked = !item.isChecked;
  }
  filterLabel(filter: any) {
    return {
      'show-part-number': filter.id === 2,
      'show-device-status': filter.id === 8,
      'show-install-status': filter.id === 11
    }
  }


}
