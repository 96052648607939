export const RemoveHub = {
    dialogId: 'Remove Hub',
    header: 'Remove Hub',
    content: 'There are currently <strong>{{#showHubConnectedCnt}}</strong> Devices associated with <strong class="text-break">{{#name}}</strong>. Are you sure you want to remove all Hub Connections/Pathways?',
    btnSuccessText: 'Remove Hub',
    btnCancelText: 'Cancel',
    btnSuccessClass: 'btn-danger',
    btnCancelClass: 'btn-secondary',
    btnSuccessStyle: '',
    bodyClass: '',
    bodyStyle: '',
    dialogClass: '',
    dialogStyle: '',
}