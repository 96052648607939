import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Building, Utilities } from '@SiteOwl/core';
import { FloorPlanService } from '../service/floor-plan.service';



export const floorPlanResolver: ResolveFn<Building> = (route: ActivatedRouteSnapshot) => {

  const buildingId = route.paramMap.get('buildingId');
  const floorId = route.paramMap.get('floorId');
  const projectId = route.paramMap.get('projectId');
  
  if (Utilities.isNull(projectId)) {
    return inject(FloorPlanService).getSiteFloorDetails(buildingId, floorId);
  } else {
    return inject(FloorPlanService).getProjectFloorDetails(projectId, buildingId, floorId)
  }
};
