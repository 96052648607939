export const ImgDelete = {
    dialogId: 'Img-Delete',
    header: 'Delete Image',
    content: 'Are you sure you want to delete <strong>{{#imgName}}</strong> ?',
    btnSuccessText: 'Delete',
    btnCancelText: 'Cancel',
    btnSuccessClass: 'btn-danger',
    btnCancelClass: 'btn-secondary',
    btnSuccessStyle: '',
    bodyClass: '',
    bodyStyle: '',
    dialogClass: '',
    dialogStyle: '',
}