import { DevicePriority, EquipmentStatusForDevice, Utilities, ValidationService } from '@SiteOwl/core';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'so-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextMenuComponent implements OnInit, OnChanges {
  @Input() createTicketShow: any;
  @Input() linkTicketShow: any;
  @Input() createFavoriteShow: any;
  @Input() deleteFavoriteShow: any;
  @Input() viewSnapshotShow: any;
  @Input() duplicateShow: any;
  @Input() makeHubShow: any;
  @Input() removeHubShow: any;
  @Input() addFlagShow: any;
  @Input() removeFlagShow: any;
  @Input() archiveShow: any;
  @Input() unArchiveShow: any;
  @Input() deleteShow: any;
  @Input() viewHistoryShow: any;
  @Input() contextmenuX: any;
  @Input() contextmenuY: any;
  @Input() fromDevice: any;
  @Input() DeviceData: any;
  @Input() equipment: any;
  @Input() isNew: any;
  @Input() showFirstDragPopup: any;
  @Input() isEdit: any;
  @Input() isFromFav = false;
  @Output() closeDeviceDropContext = new EventEmitter();
  @Output() isFormDirty = new EventEmitter();
  @Output() saveEquipmentDetail = new EventEmitter();
  @Output() contextMenuClicked = new EventEmitter();
  @Input() selectedItem!:any;

  position!: { left: string; top: string; };
  percentPos!: number;
  containerWidth!: number;
  percentTopPos!: number;
  containerHeight!: number;
  screenHeight!: number;
  isContextVisible!: boolean;
  monitoredLiveFeed: any;
  monitoredStatus: any;
  equipmentDetailsForm: any;
  defDeviceData: any;

  constructor(private fb: FormBuilder) {
    this.containerWidth = document.body.clientWidth - (309 + 57);
    this.containerHeight = document.body.clientHeight - (60 + 50 + 50 + 45)
  }
  ngOnInit(): void {
    if (this.showFirstDragPopup) {
      this.defDeviceData = _.cloneDeep(this.DeviceData);
      if (this.DeviceData.type === 'device') {
        this.equipmentDetailsForm = this.fb.group({
          id: [{ value: this.DeviceData.id, disabled: true }],
          name: [this.DeviceData.name, [Validators.required, Validators.maxLength(120), ValidationService.onlyWhiteSpaceValidator]],
          abbreviatedName: [this.DeviceData.abbreviatedName, Validators.maxLength(120)],
          partNumber: [this.DeviceData.partNumber],
        });
      } else if (this.DeviceData.type === 'task') {
        this.equipmentDetailsForm = this.fb.group({
          id: [{ value: this.DeviceData.id, disabled: true }],
          name: [this.DeviceData.name, [Validators.required, Validators.maxLength(120)]],
          abbreviatedName: [this.DeviceData.abbreviatedName, Validators.maxLength(120)],
        });
      }


      const timeOut = setTimeout(() => {
        if (document.getElementById('deviceName')) {
          document.getElementById('deviceName')?.focus();
        }
        clearTimeout(timeOut)
      }, 0);
    }
    if (this.equipment) {
      this.monitoredLiveFeed = this.equipment.monitoredLiveFeed;
      this.monitoredStatus = this.equipment.monitoredStatus;
    }
    this.isContextVisible = this.createTicketShow || this.linkTicketShow || this.createFavoriteShow || this.deleteFavoriteShow || this.viewSnapshotShow || this.duplicateShow || this.makeHubShow || this.removeHubShow || this.addFlagShow || this.removeFlagShow || this.archiveShow || this.unArchiveShow || this.deleteShow || this.viewHistoryShow;
    this.screenHeight = document.body.clientHeight;
    this.contextPosition()
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.screenHeight = document.body.clientHeight;
    this.contextPosition()
  }
  contextPosition() {
    const devicePosX = parseFloat(this.contextmenuX) - 366;
    const devicePosY = parseFloat(this.contextmenuY) - 100;
    this.position = {
      left: this.contextmenuX + 'px',
      top: this.contextmenuY + 'px'
    }
    this.percentPos = (devicePosX / this.containerWidth) * 100
    this.percentTopPos = Math.floor((devicePosY / this.containerHeight) * 100);

  }
  contextAction(event: any, action: any) {
    event.stopPropagation();
    this.contextMenuClicked.emit(action);
  }
  onFocus(event: any) {
    // document.getElementById('idOf_' + this.DeviceData.id).setAttribute('draggable', 'false');
    localStorage.setItem('stopDrag', 'true');
  }
  saveDetails(data: any, event?: any, form?: any) {
    this.equipmentDetailsForm.controls['name'].setValue(_.cloneDeep(Utilities.removeWhiteSpace(this.equipmentDetailsForm.controls['name'].value)));
    if (Utilities.isEmpty(this.equipmentDetailsForm.controls['name'].value)) {
      this.equipmentDetailsForm.controls['name'].setValue(this.DeviceData.name);
    }
    this.equipmentDetailsForm.controls['abbreviatedName'].setValue(_.cloneDeep(Utilities.removeWhiteSpace(this.equipmentDetailsForm.controls['abbreviatedName'].value)));
    if (this.equipmentDetailsForm) {
      if (!data) {
        localStorage.removeItem('stopDrag');
      }
      if (event !== undefined) {
        const keyToIgnore = [37, 38, 39, 40, 16, 17, 35, 36]
        const e: any = window.event;
        const key = e.which || e.keyCode; // keyCode detection
        const ctrl = e.ctrlKey ? e.ctrlKey : ((key === 17) ? true : false); // ctrl detection

        if (!Utilities.isNull(event) && (keyToIgnore.indexOf(event.keyCode) >= 0 || ((key == 67 || key == 65) && ctrl))) {
          return;
        }
      }
      const equipmentDetails = this.equipmentDetailsForm.value as any;
      equipmentDetails.id = this.DeviceData.id;
      this.DeviceData.name = Utilities.removeWhiteSpace(equipmentDetails.name);
      this.DeviceData.abbreviatedName = Utilities.removeWhiteSpace(equipmentDetails.abbreviatedName);
      this.equipmentDetailsForm.controls['name'].setValue(Utilities.removeWhiteSpace(equipmentDetails.name));
      this.equipmentDetailsForm.controls['abbreviatedName'].setValue(equipmentDetails.abbreviatedName);
      if (this.DeviceData.type !== 'task') {
        this.equipmentDetailsForm.controls['partNumber'].setValue(equipmentDetails.partNumber)
        this.DeviceData.partNumber = equipmentDetails.partNumber;
      }
      this.saveEquipmentDetail.emit({ equipmentDetails: equipmentDetails });
      if (data === false) {
        this.closeDeviceDropContext.emit();
      }
    } else {
      this.closeDeviceDropContext.emit();
    }
  }
}


