/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { FloorPlanService } from '../../service/floor-plan.service';

@Component({
  selector: 'so-floor-header',
  templateUrl: './floor-header.component.html',
  styleUrls: ['./floor-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FloorHeaderComponent implements OnChanges {
  @Input() action: any;
  @Input() stage: any;
  @Input() totalDiscardCount: any;
  @Input() buildingId: any
  @Input() buildingName: any;
  @Input() isProject: any;
  @Input() id: any;
  @Input() floorName: any;
  @Input() floorId: any;
  @Input() floorList: any;
  @Output() redirectPreviousPage = new EventEmitter();
  @Output() buildingFloorDropdownData = new EventEmitter();
  @Output() buildingChangeFromHeader = new EventEmitter();
  @Output() planChangedFromHeader = new EventEmitter();


  backButtonName!: string;
  buildingDropdownData: any;
  constructor(private router: Router, private floorPlanService: FloorPlanService) {

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['id']) {
      this.getBtnName();
      this.getBuildingDropdownData();
    }
  }
  getBtnName() {
    if (this.router.url.includes('secure/project')) {
      if (this.stage === 'Installation') {
        this.backButtonName = 'Installation Projects'
      } else {
        this.backButtonName = 'Design Projects'
      }
    } else {
      this.backButtonName = 'Live Sites'
    }
  }
  gotoLastVisitedPage() {
    if (this.isProject) {
      this.router.navigate([`/secure/project/edit/${this.id}`]);
    } else {
      this.router.navigate([`/secure/live-site/edit/${this.id}`], { queryParams: { isFromSite: true } })
    }
  }

  getBuildingDropdownData() {
    if (this.id && this.id > 0) {
      this.floorPlanService.getBuildingChangeData(this.id, this.isProject).subscribe((res: any) => {
        this.buildingDropdownData = res;
        this.buildingFloorDropdownData.emit(res);
      });
    }
  }

  buildingChange(event: any) {
    this.buildingChangeFromHeader.emit({id:this.id,buildingId:this.buildingDropdownData[event.target.selectedIndex].id,floorId:this.buildingDropdownData[event.target.selectedIndex].floors[0].id});
  }

  planChanged(event: any) {
    this.planChangedFromHeader.emit({id:this.id,buildingId:this.buildingId,floorId:this.floorList[event.target.selectedIndex].id});
  }

  backClicked() {
    this.redirectPreviousPage.emit();
  }
}
