import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'audit'
})
export class AuditPipe implements PipeTransform {

  transform(items: any[], filter: any): any[] {
    if (filter) {
      if (filter.hideCompletedAudit) {
        items = items.filter(x => x.status === 'active');
      }
    }
    return items;
  }

}
