/* eslint-disable @typescript-eslint/no-explicit-any */
import { LoaderService, MessageService } from '@SiteOwl/core';
import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Subject, takeUntil } from 'rxjs';
import moment from 'moment/moment';
import * as _ from "lodash";
import * as jsonpack from "jsonpack";

@Injectable({
  providedIn: 'root'
})
export class FloorIndexDbService {
  floorId: any;
  isImageStoreCreation = false;
  componentDestroyed$: Subject<boolean> = new Subject()
  constructor(private dbService: NgxIndexedDBService, private messageService: MessageService,
    public loaderService: LoaderService) { }

  getSpecificDeviceKeyFromIndexDB(key: any, id: number, commonData?: any, isFavData?: any, uploadFavJson?: any, updatedFloorEquipmentValues?: any) {
    this.floorId = _.cloneDeep(id);
    localStorage.setItem('isSaveChangeRemains', 'true');
    this.commonDataIndexDB(key, commonData, isFavData, uploadFavJson, updatedFloorEquipmentValues);
  }

  commonDataIndexDB(key: any, data: any, isFavData?: any, uploadFavJson?: any, updatedFloorEquipmentValues?: any) {
    this.dbService.update(key, { id: this.floorId, data: data, dateTime: moment.now(), customerName: data.customerName, userId: data.userId }).subscribe({
      next: () => {
        this.saveIndexDB(isFavData, uploadFavJson, updatedFloorEquipmentValues)
      },
      error: (e: any) => {
        this.checkDBError(e);
        this.dbService.createObjectStore({
          store: key,
          storeConfig: { keyPath: 'id', autoIncrement: false },
          storeSchema: [],
        });
        this.commonDataIndexDB(key, data, isFavData, uploadFavJson, updatedFloorEquipmentValues);
      },
    })
  }

  saveIndexDB(isFavData: any, uploadFavJson?: any, updatedFloorEquipmentValues?: any) {
    let DBkey: any = "floorDevices"
    let data: any = _.cloneDeep(updatedFloorEquipmentValues);
    if (isFavData) {
      DBkey = "favDevices"
      data = _.cloneDeep(uploadFavJson)
    }
    this.deviceStoreInIndexDB(DBkey, data)
  }

  checkDBError(e: any) {
    if (e && e.target && e.target.error && e.target.error.message.includes("The serialized keys and/or value are too large")) {
      this.messageService.warningMessage("Local Backup Full", "Local backup storage limit reached. Further changes will not be backed up locally in case of a system crash. You can continue working normally.");
    }
  }

  deviceStoreInIndexDB(key: string, devices: any) {
    let deviceStore: any;
    const deviceIndexStore: any = [];
    devices.forEach((element: any) => {
      if (element.images) {
        element.images.forEach((elementImg: any) => {
          elementImg.awsImageURL = '';
          elementImg.thumbnailAwsImageURL = '';
        });
      }
      deviceStore = jsonpack.pack(element);
      const deviceData: any = {
        id: element.id,
        device: deviceStore,
      }
      deviceIndexStore.push(deviceData);
    });
    const openDatabase = window.indexedDB.open('SiteOwl');

    openDatabase.onsuccess = () => {
      const database = openDatabase.result;

      if (database.objectStoreNames.contains(key)) {
        this.dbService.bulkPut(key, deviceIndexStore).subscribe({
          next: () => { },
          error: (e: any) => {
            this.checkDBError(e);
          },
        })
      } else {
        this.dbService.createObjectStore({
          store: key,
          storeConfig: { keyPath: 'id', autoIncrement: false },
          storeSchema: [],
        })
        this.deviceStoreInIndexDB(key, devices);
      }
    };
    openDatabase.onerror = (e: any) => {
        console.log(e);
    }
  }

  deleteAllRecordsFromIndexDB() {
    this.deleteFavorites();
  }

  deleteCommonDetails() {
    this.dbService.clear("commonDetails").subscribe(() => {
      this.deleteSyncDetails();
      localStorage.setItem('isSaveChangeRemains', 'false');
    })
  }

  deleteFavorites() {
    this.dbService.clear("favDevices").subscribe(() => {
      this.deleteDevices();
    })
  }

  deleteDevices() {
    this.dbService.clear("floorDevices").subscribe(() => {
      this.deleteCommonDetails();
      this.deleteDeviceImageIndexdDb();
    })
  }
  getCountOfIndexDB(key: any) {
    return new Promise((resolve, reject) => {
      this.dbService.count(key).subscribe((details: any) => {
        resolve(details);
      })
    })
  }
  getDetailsOfIndexDB(key: any) {
    return new Promise((resolve, reject) => {
      this.dbService.getAll(key).subscribe((details: any) => {
        if (details && details !== undefined && details.length > 0) {
          resolve(details);
        }
        resolve(false);
      })
    })
  }

  syncDataIndexDB(key: any, floorId: any, syncId: any, folderName: any, deviceCnt: any, imgCnt: any, isUpdate: boolean, filesToUploaded: any) {
    if (!isUpdate) {
      this.dbService.add(key, { id: floorId, syncId: syncId, folderName: folderName, deviceCnt: deviceCnt, imgCnt: imgCnt, presignedUrl: filesToUploaded, dateTime: moment.now() }).subscribe({
        next: () => { },
        error: (e: any) => {
          this.checkDBError(e);
          this.dbService.createObjectStore({
            store: key,
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [],
          });
          this.syncDataIndexDB(key, floorId, syncId, folderName, deviceCnt, imgCnt, isUpdate, filesToUploaded);
        },
      })
    } else {
      this.dbService.update(key, { id: floorId, syncId: syncId, folderName: folderName, deviceCnt: deviceCnt, imgCnt: imgCnt, presignedUrl: filesToUploaded, dateTime: moment.now() }).subscribe({
        next: () => { },
        error: (e: any) => {
          this.checkDBError(e);
          this.dbService.createObjectStore({
            store: key,
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [],
          });
          this.syncDataIndexDB(key, floorId, syncId, folderName, deviceCnt, imgCnt, isUpdate, filesToUploaded);
        },
      })
    }
  }
  deleteSyncDetails() {
    this.dbService.clear("syncDetails").subscribe(() => { }, (e: any) => {
      if (e.includes('objectStore does not exists: syncDetails')) {
        this.dbService.createObjectStore({
          store: 'syncDetails',
          storeConfig: { keyPath: 'id', autoIncrement: false },
          storeSchema: [],
        });
      }
    })
  }
  syncDeviceImageIndexdDb(floorId: any, deviceId: any, imageId: any, image: any) {
    this.dbService.add('deviceImage', { floorId: floorId, deviceId: deviceId, imageId: imageId, image: image }).subscribe({
      next: () => { },
      error: (e: any) => {
        console.log(e.message);
        if (e.message.includes('One of the specified object stores was not found')) {
          if (!this.isImageStoreCreation) {
            this.isImageStoreCreation = true;
            this.dbService.createObjectStore({
              store: 'deviceImage',
              storeConfig: { keyPath: 'imageId', autoIncrement: false },
              storeSchema: [],
            });
          }
          this.syncDeviceImageIndexdDb(floorId, deviceId, imageId, image);
        } else {
          this.checkDBError(e);
        }
      },
    })
  }
  deleteDeviceImageIndexdDb() {
    this.dbService.clear("deviceImage").subscribe(() => { }, (e: any) => {
      if ((e && e.includes('One of the specified object stores was not found')) || (e && e.message !== undefined && e.message.includes('One of the specified object stores was not found'))) {
        this.dbService.createObjectStore({
          store: 'deviceImage',
          storeConfig: { keyPath: 'imageId', autoIncrement: false },
          storeSchema: [],
        });
      }
    })
  }
  deleteSpecificDeviceImageIndexdDb(imageId: any) {
    this.dbService.deleteByKey('deviceImage', imageId).subscribe((status) => {
      console.log('Deleted?:', status);
    });
  }

  deleteAllFavorites() {
    this.dbService.clear("favDevices").subscribe(() => { })
  }
}


