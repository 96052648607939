import { ValidationService } from '@SiteOwl/core';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'so-add-flag',
  templateUrl: './add-flag.component.html',
  styleUrls: ['./add-flag.component.scss'],
})
export class AddFlagComponent implements OnInit {
  deviceDetails: any;
  addFlagForm!: FormGroup;
  resolveFlag!: any;
  isShowNofiy!: any;
  public event: EventEmitter<any> = new EventEmitter();

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
    if (this.resolveFlag) {
      this.addFlagForm = new FormGroup({
        flagResolution: new FormControl('', {validators:[Validators.required, ValidationService.onlyWhiteSpaceValidator as ValidatorFn]})
      })
    } else {
      this.addFlagForm = new FormGroup({
        flagNote: new FormControl('', [Validators.required, ValidationService.onlyWhiteSpaceValidator as ValidatorFn])
      })
    }
  }
  submitFlag() {
    if (!this.addFlagForm.invalid) {
      if (this.resolveFlag) {
        this.event.emit({ flagNote: this.addFlagForm.controls['flagResolution'].value, deviceDetails: this.deviceDetails });
      } else {
        this.event.emit({ flagNote: this.addFlagForm.controls['flagNote'].value, deviceDetails: this.deviceDetails });
      }
      this.bsModalRef.hide();
    } else {
      this.addFlagForm.controls['flagNote'].markAsTouched();
      this.addFlagForm.controls['flagResolution'].markAsTouched();
      const flagControlDom: any = document.getElementById('addflagControl')
      if (flagControlDom) {
        flagControlDom.focus();
      }
    }
  }
  closeModal() {
    this.bsModalRef.hide();
  }
}
