import { AuthService, ButtonEnableDisableService, CommonService, CustomerService, DevicePriority, MessageService, StorageKey, TicketPriorities, TicketService, TicketStatuses, Utilities, ValidationService } from '@SiteOwl/core';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';
import moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'so-floor-create-ticket',
  templateUrl: './floor-create-ticket.component.html',
  styleUrls: ['./floor-create-ticket.component.scss'],
})
export class FloorCreateTicketComponent implements OnInit {
  equipmentId: any;
  equipmentDetails: any;
  priority: any;
  buildingDetails: any;
  selectedFloor: any;
  categoryData: any;
  requesterUsers: any;
  createTicketForm: any;
  isRecordRemainForSave!: boolean;
  floorEquipmentId: any;
  floorEquipment: any;
  LastRequester: any;
  LastAssignee: any;
  isLastAssignee: any;
  priorities: any = [];
  defaultAssignee: any;
  users: any;
  tempUsers: any;
  tempActiveUsers: any;
  siteUsers: any;
  userDetail: any;
  ticketId: any;
  tempLastAssignee: any;
  tempRequester: any;
  customerUsers: any;
  isFormSubmitted = false;
  public event: EventEmitter<any> = new EventEmitter();
  constructor(private commonService: CommonService,
    private ticketService: TicketService,
    private sanitizer: DomSanitizer,
    private customerSpecificService: CustomerService,
    private fb: FormBuilder,
    public btnService: ButtonEnableDisableService,
    private messageService: MessageService,
    public bsModalRef: BsModalRef,
    private authService: AuthService) {

  }
  ngOnInit(): void {

    this.floorEquipment = _.cloneDeep(this.equipmentDetails);
    this.floorEquipmentId = this.equipmentId;
    this.getCurrentUserDetail();
    this.getPriorities();
    this.ticketId = this.equipmentId;
    if (!this.isLastAssignee) {
      this.createForm();
    }
    this.getActiveUsers();
  }

  closeModal() {
    this.event.emit();
    this.bsModalRef.hide();
  }
  createForm() {
    this.isRecordRemainForSave = true;

    this.createTicketForm = this.fb.group({
      subject: ['Device Down: ' + this.floorEquipment.name, [Validators.required, Validators.maxLength(255), ValidationService.onlyWhiteSpaceValidator]],
      description: ['', [Validators.maxLength(4000)]],
      priority: [TicketPriorities.MEDIUM, [Validators.required]],
      assignedToId: [null],
      requesterById: [this.LastRequester],
      watchers: [[]],
      status: [TicketStatuses.OPEN],
      cost: [''],
      serviceTicketFloorEquipment: [],
      ticketEquipmentStatus: ['NotWorking'],
      dueDate: [],
      floorId: [],
      floorEquipmentIds: []
    });
    this.setDueDate();

    if (this.floorEquipment.priority === DevicePriority.Critical) {
      this.createTicketForm.controls['priority'].setValue(TicketPriorities.HIGH)
    } else if (this.floorEquipment.priority === DevicePriority.Low) {
      this.createTicketForm.controls['priority'].setValue(TicketPriorities.LOW)
    } else {
      this.createTicketForm.controls['priority'].setValue(TicketPriorities.MEDIUM)

    }
  }
  getLastAssignee(users: any) {
    this.LastAssignee = +this.commonService.getLocalStorageString(StorageKey.LastAssignee);
    if (Utilities.isEmpty(this.LastAssignee) || this.LastAssignee === 0) {
      this.LastAssignee = this.commonService.getDefaultTicketAssignee();
    }
    this.LastAssignee = users.some((user: any) => user.id === this.LastAssignee) ? this.LastAssignee : null;
    if (this.LastAssignee !== null) {
      this.createForm();
      this.isLastAssignee = true;
    }
  }
  private setDueDate() {
    const dueDate: string = moment().add(1, 'w').format('MM/DD/YYYY');
    if (!Utilities.isEmpty(dueDate)) {
      this.createTicketForm.controls['dueDate'].setValue(dueDate)
    }
  }
  private getPriorities() {
    for (const key in TicketPriorities) {
      this.priorities.push({ key: TicketPriorities[key], value: TicketPriorities[key] });
    }
  }
  private getActiveUsers() {
    // this.customerService.getActiveUsers()
    this.ticketService.getAssignedSitesUser(this.buildingDetails.siteId)
      .subscribe({
        next: (result) => {
          this.ticketService.getSiteDetail(this.buildingDetails.siteId).subscribe((r: any) => {
            this.defaultAssignee = (r.assignedToId > 0 && r.assignedToId != '') ? r.assignedToId : null;
            this.createTicketForm.controls['assignedToId'].setValue(this.defaultAssignee);
            this.users = result;
            this.tempUsers = Object.assign([], this.users);
            this.tempActiveUsers = Object.assign([], this.users);
            // this.getServiceTicketDetails();
            this.siteUsers = result;
            this.getUsers();
          })
        }
      })
  }
  private getUsers() {
    this.customerUsers = [];
    this.customerSpecificService.getUsers().subscribe({
      next: (res: any) => {
        const customerUsers = res;
        this.requesterUsers = [];
        this.requesterUsers = customerUsers.filter((x: any) => ((x.role.name === 'Administrator' || x.role.name === 'Manager' || x.role.name === 'Agent') && x.user.id !== this.userDetail.id));
        const requesterUsers = customerUsers.filter((x: any) => ((x.role.name === 'Administrator' || x.role.name === 'Manager' || x.role.name === 'Agent') && x.user.id !== this.userDetail.id));

        requesterUsers.forEach((removeUser: any, index: any) => {
          if (this.siteUsers.findIndex((siteUser: any) => siteUser.id === removeUser.user.id) === -1) {
            this.requesterUsers.splice(this.requesterUsers.findIndex((e: any) => e.user.id === removeUser.user.id), 1);
          }
        });
        this.requesterUsers.push({
          canSubscribeEmail: true,
          customer: {},
          customerId: null,
          deviceAlert: null,
          email: this.userDetail.email,
          emailReport: null,
          emails: [this.userDetail.email],
          employer: null,
          id: this.userDetail.id,
          lastActive: null,
          lastLoggedIn: null,
          name: this.userDetail.name,
          permissionLevelDTO: null,
          projectId: null,
          projectRole: {},
          role: {},
          user: this.userDetail
        })

        this.requesterUsers = _.orderBy(this.requesterUsers, ['name'], ['asc']); // Use Lodash to sort array by 'name'
        this.LastRequester = this.requesterUsers.filter((x: any) => x.user.id === this.userDetail.id)[0];
        if (this.LastRequester) {
          this.createTicketForm.controls['requesterById'].setValue(this.LastRequester.id);
        }

      }
    })
  }
  getCurrentUserDetail() {
    if (!this.userDetail) {
      this.userDetail = this.authService.getUserData();
    }
    return this.userDetail;
  }
  userChanged() {
    this.tempLastAssignee = this.createTicketForm.controls['assignedToId'].value;
    if (this.createTicketForm.controls['assignedToId'].value !== null) {
      this.users = Object.assign([], this.tempActiveUsers);
    }
  }

  getValue(title: any) {
    return this.sanitizer.bypassSecurityTrustHtml(title);
  }

  requesterChanged() {
    this.tempRequester = this.createTicketForm.controls['requesterById'].value;
  }
  createTicket() {
    this.isFormSubmitted = true;
    const tempTicket: any = this.createTicketForm.value;
    if (this.createTicketForm.valid) {
      // For Setting ReuestId code Start
      this.tempRequester = this.createTicketForm.controls['requesterById'].value;
      if (this.createTicketForm.controls['requesterById'].value !== null) {
        const selectedRequestedUser = this.requesterUsers.filter((x: any) => x.id === this.tempRequester);
        if (!Utilities.isEmpty(selectedRequestedUser)) {
          tempTicket.requesterById = selectedRequestedUser[0].user.id;
        } else {
          this.requesterUsers.forEach((element: any) => {
            if (element && element.user && element.user.id === this.tempRequester) {
              tempTicket.requesterById = element.user.id;
            }
          });
        }
      } else {
        tempTicket.requesterById = null;
      }
      // For Setting ReuestId code End
      this.serviceCallTicket(tempTicket);
      if (this.tempLastAssignee !== undefined) {
        this.commonService.setLocalStorageString(StorageKey.LastAssignee, this.tempLastAssignee);
      }
    }
  }

  private serviceCallTicket(tmpTicket: any, status?: any) {
    let ticket = this.createTicketForm.value;
    this.btnService.disable()
    if (this.floorEquipment.images && this.floorEquipment.images.length > 0) {
      this.floorEquipment.images.forEach((element: any) => {
        delete element['createdDateTime']
      });
    }
    const obj: any = {
      floorEquipmentId: this.floorEquipment.id
    }
    ticket.serviceTicketFloorEquipment = [];
    ticket.serviceTicketFloorEquipment.push(obj);
    ticket.floorId = this.equipmentDetails.floorId;
    ticket.floorEquipmentIds = [];
    ticket.floorEquipmentIds.push(obj.floorEquipmentId)
    this.ticketService.createServiceTickets(ticket.floorId, ticket)
      .subscribe({
        next: (result: any) => {
          const timeOut = setTimeout(() => {
            this.btnService.enable()
            clearTimeout(timeOut);
          }, 1000);

          this.isRecordRemainForSave = false;
          ticket = [];
          ticket = result;
          this.messageService.successMessage(`Service Ticket: ${result.subject}`, 'Saved Successfully');

          this.event.emit({ ticket: ticket });
          this.bsModalRef.hide();
        }, error: (error: any) => {
          const timeOut = setTimeout(() => {
            this.btnService.enable()
            clearTimeout(timeOut);
          }, 1000);
          if (error.status === 422) {
            this.messageService.errorMessage('Service Ticket', error.error.message);
          } else {
            this.messageService.errorMessage('Service Ticket', 'Error while saving');
          }
          this.event.emit();
          this.bsModalRef.hide();
        }
      });
    if (this.tempLastAssignee !== undefined) {
      this.commonService.setLocalStorageString(StorageKey.LastAssignee, this.tempLastAssignee);
    }
  }
}
