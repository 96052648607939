/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthService, DeviceTypes, LoaderService, MessageService, TicketService, Utilities, deviceBelongTo, deviceFields } from '@SiteOwl/core';
import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FloorPlanService } from '../../service/floor-plan.service';

@Component({
  selector: 'so-equipment-history',
  templateUrl: './equipment-history.component.html',
  styleUrls: ['./equipment-history.component.scss'],
})
export class EquipmentHistoryComponent {


  showClosedTicked = true;
  unLinkedTickets!: any[];
  equipmentId!: number;
  domotzUserId!: number;
  opentickets!: any[];
  newValue = {};
  oldValues = {};
  lastObject: any;
  equipmentDetails: any;
  projectId: any;
  checkLabel = true;
  dviceHistory: any = [];
  floorEquipments: any;
  deviceFields: any = []
  isLoaderShow!: boolean;
  isSystemUpdate = false;

  @ViewChild('accordionGroup', { static: true }) accordionGroup!: ElementRef;
  @ViewChild('deviceHistoryHeader') deviceHistoryHeader!: ElementRef;
  user: any;
  displaySystemData!: boolean;
  showHeader = 'Device'
  defaultCategory: any;
  constructor(
    public bsModalRef: BsModalRef,
    public loaderService: LoaderService,
    private messageService: MessageService,
    private ticketService: TicketService,
    private floorPlanService: FloorPlanService,
    private fb: FormBuilder,
    private authService: AuthService,
    private renderer: Renderer2, private elRef: ElementRef) {

  }
  changeStatus() {
    this.checkLabel = true;
  }
  clicked(e: any, event?: any, element?: any, field?: any) {
    if (!e || (e && e.keys.length <= 1)) {
      this.checkLabel = true;
      return;
    }
    if (element === 'toggle') {
      if (e.keys[0].key === 'imageLeft' && e.keys.length - 1 <= 1) {
        this.checkLabel = true;
        return '';
      }
      this.checkLabel = false;
      e.isCollapsed = !e.isCollapsed;
      this.accordionGroupHeightChange();
      return ''
    } else {
      this.checkLabel = true;
      return ''
    }
  }

  ngOnInit() {
    const InstalledByIndex: number = this.projectId ? 8 : 7;
    this.isLoaderShow = true;
    this.dviceHistory = []
    this.floorEquipments = []
    this.deviceFields = _.cloneDeep(deviceFields)
    if (this.deviceFields.filter((x: any) => x.key === 'networkSwitchLinkName').length > 0) {
      this.deviceFields.filter((x: any) => x.key === 'networkSwitchLinkName')[0].label = 'Hub'
    }
    this.user = this.authService.getUserKeyData();
    this.getDefaultCategory();
    if (Utilities.isNull(this.user.user.triggeredByMonitoring)) {
      this.user.user.triggeredByMonitoring = true;
    }
    if (this.equipmentDetails && this.equipmentDetails.type === 'task') {
      this.showHeader = 'Task'
    }
    this.displaySystemData = this.user.user.triggeredByMonitoring;
    this.deviceFields.push(
      {
        key: 'imageLeft',
        label: 'Location',
        sortName: 'images',
        isEditable: true,
        deviceBelongTo: deviceBelongTo.all
      }, {
      key: 'imageTop',
      label: 'top',
      sortName: 'images',
      isEditable: true,
      deviceBelongTo: deviceBelongTo.all
    }, {
      key: 'pinColor',
      label: 'Device Color',
      sortName: 'pinColor',
      isEditable: true,
      deviceBelongTo: deviceBelongTo.all
    }, {
      key: 'isFlag',
      label: 'Flag',
      sortName: 'isFlag',
      isEditable: true,
      deviceBelongTo: deviceBelongTo.project
    }, {
      key: 'isHub',
      label: 'Is Hub',
      sortName: 'isHub',
      isEditable: true,
      deviceBelongTo: deviceBelongTo.all
    });

    this.deviceFields.splice(InstalledByIndex, 0, {
      key: 'installedByUserName',
      label: 'Installed By',
      sortName: 'installedBy',
      isEditable: true,
      deviceBelongTo: deviceBelongTo.all
    })
    this.deviceFields.splice(InstalledByIndex, 0, {
      key: 'assignedToUserName',
      label: 'Assigned To',
      sortName: 'assignedTo',
      isEditable: true,
      deviceBelongTo: deviceBelongTo.all
    })
    if (this.projectId) {
      this.deviceFields.splice(1, 0, {
        key: 'type',
        label: 'Device / Task',
        sortName: 'type',
        isEditable: true,
        deviceBelongTo: deviceBelongTo.project
      })
    }
    if (this.deviceFields.filter((x: any) => x.key === 'customDeviceId').length > 0 && this.showHeader === 'Task') {
      this.deviceFields.splice(this.deviceFields.findIndex((x: any) => x.key === 'customDeviceId'), 1)
    }
    if (this.deviceFields.filter((x: any) => x.key === 'status').length > 0 && this.showHeader === 'Task') {
      this.deviceFields.splice(this.deviceFields.findIndex((x: any) => x.key === 'status'), 1)
    }
    if (this.deviceFields.filter((x: any) => x.key === 'isHub').length > 0 && this.showHeader === 'Task') {
      this.deviceFields.splice(this.deviceFields.findIndex((x: any) => x.key === 'isHub'), 1)
    }
    if (this.deviceFields.filter((x: any) => x.key === 'coverageArea').length > 0 && this.showHeader === 'Task') {
      this.deviceFields.splice(this.deviceFields.findIndex((x: any) => x.key === 'coverageArea'), 1)
    }
    if ((this.projectId || Utilities.isNull(this.domotzUserId)) && this.deviceFields.filter((x: any) => x.key === 'autoUpdateDeviceStatus')) {
      this.deviceFields.splice(this.deviceFields.findIndex((x: any) => x.key === 'autoUpdateDeviceStatus'), 1)
    }
    this.floorPlanService.getDeviceHistory(this.equipmentDetails.id).subscribe((res: any) => {
      this.accordionGroupHeightChange();

      this.floorEquipments = res;
      if (this.floorEquipments.some((x: any) => x.isSystemUpdate)) {
        this.isSystemUpdate = true;
      }
      this.isLoaderShow = false;
      this.floorEquipments.map((equipment: any) => {
        equipment.imageLeft = parseFloat(equipment.imageLeft).toFixed(2) + '%';
        equipment.imageTop = parseFloat(equipment.imageTop).toFixed(2) + '%';
        equipment.isArchived = equipment.isArchived ? 'Archived' : 'Active';
        if (!Utilities.isNull(equipment.installedBy)) {
          equipment.installedByUserName = equipment.installedBy.name || equipment.installedBy.email;
        }
        if (!Utilities.isNull(equipment.assignedToUser)) {
          equipment.assignedToUserName = equipment.assignedTo.name || equipment.assignedTo.email;
        }
        equipment.isHub = (equipment.isHub === true) ? 'Yes' : 'No';
        equipment.coverageArea = (equipment.coverageArea === true) ? 'Yes' : 'No';
        equipment.isFlag = (equipment.isFlag === true) ? 'Yes' : 'No';
        if (equipment.coverageArea === 'Yes' && this.floorEquipments.length === 1) {
          if (equipment.coverageDirection === null || equipment.coverageDirection === undefined) {
            equipment.coverageDirection = ``;
          } else {
            equipment.coverageDirection = `${equipment.coverageDirection}°`;
          }
          if (equipment.coverageRange === null || equipment.coverageRange === undefined) {
            equipment.coverageRange = ``;
          } else {
            equipment.coverageRange = `${equipment.coverageRange}`;
          }
          if (equipment.coverageAngle === null || equipment.coverageAngle === undefined) {
            equipment.coverageAngle = ``;
          } else {
            equipment.coverageAngle = `${equipment.coverageAngle}°`;
          }
        }
        if (equipment.status === 'NotWorking') {
          equipment.status = 'Non-Operational'
        } else if (equipment.status === 'OperationalWithIssue') {
          equipment.status = 'Semi-Operational'
        }

        if (equipment.installStatus === 'InProgress') {
          equipment.installStatus = 'In Progress'
        }
        if (equipment.installStatus === 'Installed' && this.showHeader === 'Task') {
          equipment.installStatus = 'Done'
        }
        if (equipment.type === 'task' || equipment.type === 'device') {
          equipment.type = equipment.type.charAt(0).toUpperCase() + equipment.type.substr(1).toLowerCase()
        }
        if (Utilities.isNull(this.domotzUserId)) {
          delete equipment.autoUpdateDeviceStatus;
        }
      })
      if (this.floorEquipments.length > 0) {
        this.lastObject = [];
        const object = this.floorEquipments[this.floorEquipments.length - 1]
        const newObj: any = {};
        const keys: any = [];
        let keysObj: any = {};
        const modifiedDateTime: any = object.modifiedDateTime;
        const updatedBy: any = object.updatedBy;
        for (const key in object) {
          if (object.hasOwnProperty(key) && !Utilities.isNull(object[key]) &&
            (this.deviceFields.some((x: any) => x.key === key && x.isEditable)) && ((this.projectId || key !== 'installStatus') ||
              (key === 'installStatus' && !Utilities.isNull(object['projectName'])))) {
            {
              if (key === 'pinColor') {
                newObj[key] = object[key];
                newObj['categoryName'] = object['categoryName'];
              } else {
                newObj[key] = object[key];
              }
              keysObj = {
                key: key,
                label: this.deviceFields.find((x: any) => x.key === key).label
              }
              if (key === 'imagesCount' && object['imagesCount'] > 0) {
                keys.push(keysObj);
              } else if (key !== 'imagesCount') {
                keys.push(keysObj);
              }
            }
          }
        }
        this.lastObject = {
          'old': {}, 'new': newObj, 'keys': keys,
          'modifiedDateTime': modifiedDateTime, 'updatedBy': updatedBy, 'isCollapsed': true
        }

        if (this.floorEquipments.length === 1) {
          this.dviceHistory.push(this.lastObject)
          this.dviceHistory.forEach((x: any) => {
            const tempKeys = _.cloneDeep(x.keys)
            x.keys = [];
            this.deviceFields.forEach((sortedField: any) => {
              if (tempKeys.find((key: any) => key.key === sortedField.key && sortedField.isEditable)) {
                x.keys.push(tempKeys.find((key: any) => key.key === sortedField.key))
              }
            })
          })
        }
      }
      if (this.floorEquipments.length > 0 && this.floorEquipments.length !== 1) {
        for (let i = 0; i < (this.floorEquipments.length - 1); i++) {
          this.dviceHistory.push(this.changes(this.floorEquipments[i], this.floorEquipments[i + 1]))
        }
        this.dviceHistory.forEach((x: any, index: any) => {
          if (Utilities.isNull(x.modifiedDateTime) && Utilities.isNull(x.updatedBy)) {
            this.dviceHistory.splice(index, 1)
          }
        })
        this.dviceHistory.push(this.lastObject)
        this.dviceHistory.forEach((y: any) => {
          const tempKeys = _.cloneDeep(y.keys)
          y.keys = [];
          this.deviceFields.forEach((sortedDeviceField: any) => {
            if (tempKeys.find((key: any) => key.key === sortedDeviceField.key && sortedDeviceField.isEditable)) {
              y.keys.push(tempKeys.find((key: any) => key.key === sortedDeviceField.key))
            }
          })
        })
      }
    })

  }
  setDeviceFieldLabel(deviceData: any) {
    if (deviceData.keys.length > 1 &&
      !(deviceData.keys.some((x: any) => x.key === 'imageTop') && deviceData.keys.length === 2)) {
      if (this.showHeader === 'Task') {
        deviceData.keys.forEach((element: any) => {
          if (element.key === 'name' && element.label === 'Device Name') {
            element.label = 'Task Name'
          } else if (element.key === 'installedBy' && element.label === 'Installed By') {
            element.label = 'Assignee'
          } else if (element.key === 'installStatus' && element.label === 'Install Status') {
            element.label = 'Status'
          } else if (element.key === 'installationDate' && element.label === 'Date Installed') {
            element.label = 'Date Completed'
          }
        });
      }
      if (deviceData.keys.some((x: any) => x.key === 'imageTop')) {
        return `${deviceData.keys.length - 1} fields changed`
      } else if (!deviceData.keys.some((x: any) => x.key === 'imageTop')) {
        return `${deviceData.keys.length} fields changed`
      }
    } else {
      try {
        if (this.showHeader === 'Device') {
          return deviceData.keys[0].label
        }
        else {

          if (deviceData.keys[0].label && deviceData.keys[0].label === 'Device Name') {
            return deviceData.keys[0].label = 'Task Name'
          } else if (deviceData.keys[0].label && deviceData.keys[0].label === 'Installed By') {
            return deviceData.keys[0].label = 'Assignee'
          } else if (deviceData.keys[0].label && deviceData.keys[0].label === 'Install Status') {
            return deviceData.keys[0].label = 'Status'
          } else if (deviceData.keys[0].label && deviceData.keys[0].label === 'Date Installed') {
            return deviceData.keys[0].label = 'Date Completed'
          }
          return deviceData.keys[0].label
        }
      } catch (e) {
        return ''
      }
    }

  }
  setDeviceValue(deviceData: any, newDevice: any) {
    if (!newDevice) {

      if (deviceData.isCollapsed && deviceData.keys.length > 1 &&
        !(deviceData.keys.some((x: any) => x.key === 'imageTop') && deviceData.keys.length === 2)) {
        return 'Expand ...'
      } else if (!deviceData.isCollapsed && deviceData.keys.length > 1 &&
        !(deviceData.keys.some((x: any) => x.key === 'imageTop') && deviceData.keys.length === 2)) {
        return 'Collapse ...'
      }
      else {
        if (deviceData.old && (deviceData.old[deviceData.keys[0].key] || deviceData.old[deviceData.keys[0].key] === 0)) {
          return deviceData.keys[0].key === 'imagesCount' ? (deviceData.old[deviceData.keys[0].key] !== 1 ? deviceData.old[deviceData.keys[0].key] + ' Images' : deviceData.old[deviceData.keys[0].key] + ' Image') : deviceData.old[deviceData.keys[0].key]
        } else {
          return ''
        }
      }
    } else {
      if (deviceData.isCollapsed && deviceData.keys.length > 1 &&
        !(deviceData.keys.some((x: any) => x.key === 'imageTop') && deviceData.keys.length === 2)) {
        return 'Expand ...'
      } else if (!deviceData.isCollapsed && deviceData.keys.length > 1 &&
        !(deviceData.keys.some((x: any) => x.key === 'imageTop') && deviceData.keys.length === 2)) {
        return 'Collapse ...'

      } else {
        if (deviceData.new && (deviceData.new[deviceData.keys[0].key] || deviceData.new[deviceData.keys[0].key] === 0)) {
          return deviceData.keys[0].key === 'imagesCount' ? (deviceData.new[deviceData.keys[0].key] !== 1 ? deviceData.new[deviceData.keys[0].key] + ' Images' : deviceData.new[deviceData.keys[0].key] + ' Image') : deviceData.new[deviceData.keys[0].key]
        } else {
          return ''
        }
      }
    }
  }
  setDeviceValueLocation(deviceData: any, newDevice: any) {
    if (!newDevice) {
      if (deviceData.isCollapsed && deviceData.keys.length > 1 &&
        !(deviceData.keys.some((x: any) => x.key === 'imageTop') && deviceData.keys.length === 2)) {
        return 'Expand ...'
      } else if (!deviceData.isCollapsed && deviceData.keys.length > 1 &&
        !(deviceData.keys.some((x: any) => x.key === 'imageTop') && deviceData.keys.length === 2)) {
        return 'Collapse ...'
      }
      else {
        if (deviceData.old['imageLeft'] && deviceData.old['imageTop']) {
          return `(${deviceData.old['imageLeft']} , ${deviceData.old['imageTop']})`
        } else {
          return ''
        }
      }
    } else {
      if ((deviceData.isCollapsed && deviceData.keys.length > 1) &&
        !(deviceData.keys.some((x: any) => x.key === 'imageTop') && deviceData.keys.length === 2)) {
        return 'Expand ...'
      } else if ((!deviceData.isCollapsed && deviceData.keys.length > 1) &&
        !(deviceData.keys.some((x: any) => x.key === 'imageTop') && deviceData.keys.length === 2)) {
        return 'Collapse ...'
      } else {
        if (deviceData.new && deviceData.new['imageLeft'] && deviceData.new['imageTop']) {
          return `(${deviceData.new['imageLeft']} , ${deviceData.new['imageTop']})`
        } else {
          if (deviceData.old['imageLeft'] && deviceData.old['imageTop']) {
            return `(${deviceData.old['imageLeft']} , ${deviceData.old['imageTop']})`
          } else {
            return ''
          }
        }
      }
    }
  }
  changes(object: any, base: any) {
    const oldObj: any = {};
    const newObj: any = {};
    const keys: any = [];
    let keysObj: any = {};
    let modifiedDateTime: any = null
    let updatedBy = null;
    for (const key in object) {
      if (object.hasOwnProperty(key) && (this.deviceFields.some((x: any) => x.key === key && x.isEditable) &&
        (this.projectId || key !== 'installStatus'))) {
        if (!_.isEqual(object[key], base[key]) || (_.isEqual(object[key], base[key]) &&
          ((key === 'imageTop' || key === 'imageLeft') && ((object['imageTop'] === base['imageTop']) &&
            (object['imageLeft'] !== base['imageLeft'])) ||
            ((object['imageTop'] !== base['imageTop']) && (object['imageLeft'] === base['imageLeft']))))) {
          if ((_.isEqual(object[key], base[key]) &&
            (key === 'imageTop' || key === 'imageLeft'))) {
            oldObj[key] = base[key];
            newObj[key] = base[key];
          } else {
            oldObj[key] = base[key];
            newObj[key] = object[key];
          }

          keysObj = {
            key: key,
            label: this.deviceFields.find((x: any) => x.key === key).label
          }


          keys.push(keysObj);

        }
      }
    }
    if (!Utilities.isEmpty(newObj) && !_.isEmpty(newObj)) {
      modifiedDateTime = object.modifiedDateTime;
      updatedBy = !object.isSystemUpdate ? object.updatedBy : 'System';
    }
    return {
      'old': oldObj, 'new': newObj, 'keys': keys,
      'modifiedDateTime': modifiedDateTime, 'updatedBy': updatedBy, 'isCollapsed': true
    }
  }
  toggletatus(event: any) {
    this.displaySystemData = !this.displaySystemData;
    this.ticketService.triggeredByMonitoring(this.displaySystemData).subscribe((res: any) => {
      this.user = this.authService.getUserKeyData();
      this.user.user.triggeredByMonitoring = this.displaySystemData;
      this.authService.saveUserData(this.user);
    })
  }
  closeModal() {
    this.bsModalRef.hide();
  }

  private accordionGroupHeightChange() {
    const timeOut = setTimeout(() => {
      if (this.accordionGroup.nativeElement.clientHeight > 299) {
        this.renderer.addClass(this.deviceHistoryHeader.nativeElement, 'accordion-height-change');
      } else {
        this.renderer.removeClass(this.deviceHistoryHeader.nativeElement, 'accordion-height-change');
      }
      clearTimeout(timeOut)
    }, 500);
  }
  checkEmptyObjects(deviceData: any) {
    if (!(deviceData.modifiedDateTime && deviceData.keys && deviceData.keys[0].key !== 'imageTop' && deviceData.updatedBy)) {
      return 'none';
    }
    return 'block';
  }
  getDefaultCategory() {
    this.floorPlanService.getDefaultCategory().subscribe((r: any) => {
      this.defaultCategory = r;
    })
  }

  checkLabelForPinColorForOld(deviceData: any) {
    if (this.defaultCategory && this.defaultCategory !== undefined && this.defaultCategory !== null) {
      let x: any = this.defaultCategory.filter((x: any) => x.pinColor === deviceData.new.pinColor)
      if (x.length === 0 || x === null || x === undefined) {
        x = this.defaultCategory.filter((x: any) => x.pinColor === deviceData.old.pinColor)
        if (x && x.length > 0) {
          return 'System Type'
        } else if (deviceData.old.pinColor) {
          return 'Custom Color'
        } else {
          return ''
        }
      } else {
        if (deviceData.old.pinColor) {
          return 'Custom Color'
        } else {
          return '';
        }
      }
    } else {
      return '';
    }
  }
  checkLabelForPinColorForNew(deviceData: any) {
    if (this.defaultCategory && this.defaultCategory !== undefined && this.defaultCategory !== null) {
      let x: any = this.defaultCategory.filter((x: any) => x.pinColor === deviceData.old.pinColor)
      if (x.length === 0 || x === null || x === undefined) {
        x = this.defaultCategory.filter((x: any) => x.pinColor === deviceData.new.pinColor)
        if (x && x.length > 0) {
          return 'System Type'
        } else {
          return 'Custom Color'
        }
      } else {
        return 'Custom Color'
      }
    } else {
      return '';
    }
  }
  checkLabelForPinColor(deviceData: any) {
    if (this.defaultCategory && this.defaultCategory !== undefined && this.defaultCategory !== null) {

      const x: any = this.defaultCategory.filter((x: any) => x.name === deviceData.new.categoryName)
      if (x && x.length > 0) {
        if (x[0]['pinColor'] === deviceData.new.pinColor) {
          return 'System Type'
        } else {
          return 'Custom Color'
        }
      } else {
        return 'Custom Color'
      }
    } else {
      return '';
    }
  }
}
