import { DeviceTypes, Utilities } from '@SiteOwl/core';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterFavDeletedDevice'
})
export class FilterFavDeletedDevicePipe implements PipeTransform {

  transform(value: any, filter: any): any {
    if (!value) {
      return value;
    } 
    // return !Utilities.isNull(filter) ? value.filter(x => !x.isDeleted && x.childFavouriteEquipments && x.childFavouriteEquipments.filter(y => !y.isDeleted)) : value.filter(x => (!x.isDeleted && x.type === DeviceTypes.Device) && x.childFavouriteEquipments && x.childFavouriteEquipments.filter(y => !y.isDeleted && y.type === DeviceTypes.Device));
    value = !Utilities.isNull(filter) ? value.filter((x: any) => !x.isDeleted) : value.filter((x: any) => !x.isDeleted && x.type === DeviceTypes.Device);
    
    return value.sort(function (item1: any, item2: any) {
      return item1.orderNumber - item2.orderNumber;
    });
  }

}
