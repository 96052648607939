import { FloorEquipment } from '@SiteOwl/core';
import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { FloorUtils } from '../service/floor-utils';

@Pipe({
  name: 'floorCategoryFilter',
  pure: false
})
export class FloorCategoryFilterPipe implements PipeTransform {


  transform(items: FloorEquipment[], filter: any): any[] {
    return FloorUtils.filterDevices(items, filter);
  }

}
