<div class="modal-header" (click)="$event.stopPropagation()">
    <h1 class="modal-title pull-left">
        Create New Ticket</h1>
    <button data-dismiss="modal" (click)="closeModal()" aria-label="Close" class="close pull-right" type="button">

        <i class="fas fa-close"></i>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="createTicketForm" (ngSubmit)="createTicket()" novalidate (click)="$event.stopPropagation()">
        <div class="row">
            <div class="col-sm-3">
                <div class="form-group subject-error">
                    <label>Subject<sup>*</sup></label>
                    <input maxlength="255" removeWhiteSpace type="text" formControlName="subject" class="form-control"
                        placeholder="Enter Ticket subject">
                    <so-control-messages [name]="'Subject '" [isFormSubmitted]="isFormSubmitted"
                        [control]="createTicketForm.controls.subject"></so-control-messages>
                </div>
                <div class="form-group">
                    <label>Requester<sup>*</sup></label>
                    <select class="form-control" formControlName="requesterById" (change)="requesterChanged()">

                        <option *ngFor="let item of requesterUsers" [ngValue]="item.user.id">{{item.user.name === ''
                            || item.user.name === null ? item.user.email
                            : item.user.name }}</option>
                    </select>
                    <so-control-messages [name]="'Requester'" [isFormSubmitted]="isFormSubmitted"
                        [control]="createTicketForm.controls.requesterById"></so-control-messages>
                </div>
                <div class="form-group">
                    <label>Assignee</label>
                    <select class="form-control" formControlName="assignedToId" (change)="userChanged()">
                        <option [ngValue]="null">Unassigned</option>
                        <option *ngFor="let item of users" [selected]="item.id===defaultAssignee" [ngValue]="item.id">
                            {{item.name === '' ||
                            item.name === null
                            ? item.email
                            : item.name }}</option>
                    </select>
                    <so-control-messages [name]="'Assigned To'" [isFormSubmitted]="isFormSubmitted"
                        [control]="createTicketForm.controls.assignedToId"></so-control-messages>
                </div>
                <div class="form-group subject-error">
                    <label>Priority
                        <sup>*</sup>
                    </label>
                    <select class="form-control" formControlName="priority">
                        <option value="">Select Priority</option>
                        <option *ngFor="let item of priorities" [ngValue]="item.key">{{item.value}}</option>
                    </select>
                    <so-control-messages [name]="'Priority'" [isFormSubmitted]="isFormSubmitted"
                        [control]="createTicketForm.controls.priority"></so-control-messages>
                </div>
            </div>
            <div class="col-sm-9 ticket-info-container">
                <div class="form-group">
                    <label> Description </label>
                    <textarea maxlength="4000" class="form-control" formControlName="description"
                        placeholder="Enter Ticket description"></textarea>
                </div>
            </div>

        </div>

    </form>
</div>
<div class="modal-footer d-flex align-items-center justify-content-end border-none" (click)="$event.stopPropagation()">
    <button type="button" class="btn btn-secondary" (click)="closeModal()" data-dismiss="modal">Cancel</button>
    <button [disabled]="btnService.getStatus()" [ngStyle]="{'cursor':btnService.getStatus()?'not-allowed':'pointer'}"
         type="button" class="btn btn-primary mrg-0"
        (click)="createTicket()">Create Ticket</button>
</div>