import { Directive, OnDestroy, OnChanges, SimpleChanges, Input, ElementRef, Output, EventEmitter, OnInit } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';
import { map } from 'rxjs/internal/operators/map';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import * as _ from 'lodash';
import { EventEmitterType, EventService, Utilities } from '@SiteOwl/core';
import { FloorUtils } from '../service/floor-utils';
import moment from 'moment';
import { take } from 'rxjs';

@Directive({
  selector: '[soCoverageRange]'
})
export class CoverageRangeDirective implements OnInit, OnDestroy, OnChanges {

  @Input() isEdit: any = true;
  @Input() device: any;
  @Input() zoomFactor: any;
  @Output() updateCoverage = new EventEmitter();
  private readonly destroy$ = new Subject<void>();
  dragEventsStart = false;
  dragEventAdded = false;
  prevX: any;
  prevY: any;
  newSVGWidth: any;
  calNewCoverageRangeForData: any;
  rangeChanged = false;
  constructor(private elementRef: ElementRef,
    private eventService: EventService
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['device']) {
      this.device = changes['device'].currentValue;
    }
  }
  ngOnInit(): void {
    this.elementRef.nativeElement.style.cursor = 'grab'
    this.initDragEvents();
  }
  initDragEvents() {
    this.dragEventAdded = true;
    const dragStart$ = fromEvent<MouseEvent>(this.elementRef.nativeElement, "mousedown");
    // if (!this.device.parentId && !this.isNew) {
    const dragEnd$ = fromEvent<MouseEvent>(window, "mouseup")
    const dragElementEnd$ = fromEvent<MouseEvent>(this.elementRef.nativeElement, "mouseup")
    // }
    const drag$ = fromEvent<MouseEvent>(document.body, "mousemove").pipe(takeUntil(dragEnd$), takeUntil(dragElementEnd$), takeUntil(this.destroy$));


    dragStart$.pipe(takeUntil(this.destroy$), switchMap((start) => {
      dragEnd$.pipe(take(1))
        .subscribe(($event: any) => {
          this.dragEnd($event);
        });
      this.dragStart(start)
      return drag$.pipe(map(move => {
        move.preventDefault();
        this.dragging(move);
        return move;
      }), takeUntil(dragEnd$), takeUntil(dragElementEnd$));
    })).subscribe();

    dragElementEnd$.pipe(takeUntil(this.destroy$))
      .subscribe(($event: any) => {
        this.dragEnd($event);
      });
  }
  dragStart(event: any) {
    this.eventService.broadcast(EventEmitterType.floorEquipmentSelection, true);
    event.stopPropagation();
    this.prevX = event.clientX;
    this.prevY = event.pageY;
    this.dragEventsStart = true;
  }
  dragging(event: any) {
    if (event.clientX > 0 && event.clientY > 0 && this.dragEventsStart && (Math.abs(this.prevX - event.screenX) > 2 || Math.abs(this.prevY - event.screenY) > 2)) {
      this.dragCoveragerange(event);
      this.rangeChanged = true;
    }
  }
  dragEnd(event: any) {
    if (this.dragEventsStart && this.rangeChanged) {
      this.eventService.broadcast(EventEmitterType.floorEquipmentSelection, false);
      event.stopPropagation();
      event.preventDefault();
      this.dragEventsStart = false;
      this.rangeChanged = false;
      this.elementRef.nativeElement.style.cursor = 'grab'
      if (!Utilities.isNull(this.newSVGWidth) && Math.abs(this.calNewCoverageRangeForData) <= 100) {
        this.device.dimensionWidth = this.newSVGWidth + 'px';
      } else {
        this.device.dimensionWidth = _.cloneDeep(this.device.dimensionWidth)
      }
      const myNewSvg: any = document.getElementById("coveragesvg_dev_" + this.device.id + "_" + this.device.name);
      if (myNewSvg) {
        myNewSvg.style.opacity = '1';
      }
      if (!Utilities.isNull(this.calNewCoverageRangeForData)) {
        this.device.coverageRange = Math.abs(this.calNewCoverageRangeForData);
      }
      this.updateCoverage.emit(this.device)
    }
  }
  dragCoveragerange(event: any) {
    if (this.dragEventsStart) {
      event.stopPropagation();
      this.elementRef.nativeElement.style.cursor = 'grabbing'
      const SvgWidth = document.getElementById('coveragesvg1_' + this.device.id + '_' + this.device.name)
      let dimensionWidth = _.cloneDeep(this.device.dimensionWidth);
      dimensionWidth = parseInt(dimensionWidth) * this.zoomFactor;
      if (SvgWidth) {
        let newWidth: any;
        if (this.device.coverageDirection === 0 || this.device.coverageDirection === 360) {
          if ((parseInt(dimensionWidth) - event.clientY + this.prevY) > 0) {
            newWidth = (parseInt(dimensionWidth) - event.clientY + this.prevY);//dragged 
          } else {
            newWidth = 5
          }
        } else if (this.device.coverageDirection === 180) {
          newWidth = (parseInt(dimensionWidth) + event.clientY - this.prevY);//dragged 
        } else if (this.device.coverageDirection >= 45 && this.device.coverageDirection < 135) {
          if ((Math.abs(event.clientX - this.prevX) > 0) && (Math.abs(event.clientY - this.prevY) < Math.abs(event.clientX - this.prevX))) {
            newWidth = (parseInt(dimensionWidth) + event.clientX - this.prevX);//dragged 
          }
          //  else if (event.clientY - this.prevY > 0) {
          //   newWidth = (parseInt(dimensionWidth) + event.clientY + this.prevY);//dragged 
          //   console.log('=======2')
          // }
           else {
            newWidth = (parseInt(dimensionWidth) + event.clientX - this.prevX);//dragged 
          }
        } else if (this.device.coverageDirection >= 225 && this.device.coverageDirection < 315) {
          if ((Math.abs(event.clientX - this.prevX) > 0) && (Math.abs(event.clientY - this.prevY) < Math.abs(event.clientX - this.prevX))) {
            newWidth = (parseInt(dimensionWidth) - event.clientX + this.prevX);//dragged 
          } else if (event.clientY - this.prevY > 0) {
            newWidth = (parseInt(dimensionWidth) + event.clientY - this.prevY);//dragged 
          } else {
            newWidth = (parseInt(dimensionWidth) - event.clientX + this.prevX);//dragged 
          }
        } else if (this.device.coverageDirection >= 135 && this.device.coverageDirection < 180) {
          if ((Math.abs(event.clientX - this.prevX) > 0) && (Math.abs(event.clientY - this.prevY) < Math.abs(event.clientX - this.prevX))) {
            newWidth = (parseInt(dimensionWidth) + event.clientY - this.prevY);//dragged 
          } else if (event.clientY - this.prevY > 0) {
            newWidth = (parseInt(dimensionWidth) + event.clientY - this.prevY);//dragged 
          } else {
            newWidth = (parseInt(dimensionWidth) + event.clientY - this.prevY);//dragged 
          }
        }
        else if (this.device.coverageDirection >= 315 && this.device.coverageDirection <= 322) {
          if ((Math.abs(event.clientX - this.prevX) > 0) && (Math.abs(event.clientY - this.prevY) < Math.abs(event.clientX - this.prevX))) {
            newWidth = (parseInt(dimensionWidth) - event.clientX + this.prevX);//dragged 
          } else if (event.clientY - this.prevY > 0) {
            newWidth = (parseInt(dimensionWidth) - event.clientY + this.prevY);//dragged 
          } else {
            newWidth = (parseInt(dimensionWidth) - event.clientX + this.prevX);//dragged 
          }
        }
        else if (this.device.coverageDirection >= 323 || this.device.coverageDirection < 45) {
          if ((Math.abs(event.clientX - this.prevX) > 0) && (Math.abs(event.clientY - this.prevY) < Math.abs(event.clientX - this.prevX))) {
            newWidth = (parseInt(dimensionWidth) - event.clientY + this.prevY);//dragged 
          } else if (event.clientY - this.prevY > 0) {
            newWidth = (parseInt(dimensionWidth) - event.clientY + this.prevY);//dragged 
          } else {
            newWidth = (parseInt(dimensionWidth) - event.clientY + this.prevY);//dragged 
          }
        } else if (this.device.coverageDirection >= 181 && this.device.coverageDirection <= 225) {
          if ((Math.abs(event.clientX - this.prevX) > 0) && (Math.abs(event.clientY - this.prevY) < Math.abs(event.clientX - this.prevX))) {
            newWidth = (parseInt(dimensionWidth) + event.clientY - this.prevY);//dragged 
          } else if (event.clientY - this.prevY > 0) {
            newWidth = (parseInt(dimensionWidth) + event.clientY - this.prevY);//dragged 
          } else {
            newWidth = (parseInt(dimensionWidth) + event.clientY - this.prevY);//dragged 
          }
        } else {
          if ((Math.abs(event.clientY - this.prevY) > 0) && (Math.abs(event.clientX - this.prevX) < Math.abs(event.clientY - this.prevY))) {
            newWidth = (parseInt(dimensionWidth) - event.clientY + this.prevY);//dragged 
          } else if (event.clientX - this.prevX > 0) {
            newWidth = (parseInt(dimensionWidth) + event.clientX - this.prevX);//dragged 
          } else {
            newWidth = (parseInt(dimensionWidth) - event.clientX + this.prevX);//dragged 
          }
        }
        if (newWidth > 0) {
          this.newSVGWidth = newWidth;
          let calNewCoverageRange: any = Math.abs(Math.round((this.device.coverageRange * newWidth) / parseInt(dimensionWidth)))
          if (calNewCoverageRange > 100) {
            calNewCoverageRange = 100
          }
          const animationId = requestAnimationFrame(() => {
            if (calNewCoverageRange <= 100 && calNewCoverageRange >= 1) {
              this.calNewCoverageRangeForData = calNewCoverageRange;
              SvgWidth.style.width = newWidth / this.zoomFactor + 'px';
            } else if (calNewCoverageRange < 1) {
              this.calNewCoverageRangeForData = 1;
              SvgWidth.style.width = newWidth / this.zoomFactor + 'px';
            }
            this.translateDiv();
            cancelAnimationFrame(animationId)
          });
        }
      }
    }
  }
  private translateDiv() {
    const zoomfactor = this.zoomFactor;

    if (this.device && document.getElementById("arc1_" + this.device.id + "_" + this.device.name)) {
      const myarc: any = document.getElementById("arc1_" + this.device.id + "_" + this.device.name);
      if (myarc !== undefined && myarc !== null) {
        const widthRadius = parseInt(this.newSVGWidth) / this.zoomFactor;
        let radius;
        if (((zoomfactor * widthRadius) + 30) > 100) {
          radius = ((widthRadius * zoomfactor) + 30)
        } else {
          radius = 100;
        }
        const transformX = -7
        const transformY = 7
        const start = this.device.coverageDirection;
        const end = this.device.coverageDirection;
        const spacerX = (22 * Math.sin(Math.PI * 2 * (start - 80) / 360));
        const spacerY = (22 * Math.cos(Math.PI * 2 * (end - 80) / 360));
        const x = (radius * Math.sin(Math.PI * 2 * start / 360) + spacerX);
        const y = (radius * Math.cos(Math.PI * 2 * end / 360) + spacerY);

        let scale: any = 1
        scale = zoomfactor > 0.7 ? zoomfactor : 0.7;

        const svgElement = document.getElementById('arc_g1_div_' + this.device.id + "_" + this.device.name);
        if (svgElement) {
          svgElement.style.transform = 'rotate(' + (this.device.coverageDirection - 50) + 'deg)';
        }
        if (document.getElementById('arc_text_div_' + this.device.id + "_" + this.device.name)) {
          document.getElementById('arc_text_div_' + this.device.id + "_" + this.device.name)?.setAttribute("x", (x).toString());
          document.getElementById('arc_text_div_' + this.device.id + "_" + this.device.name)?.setAttribute("y", (-y).toString());
        }
        if (document.getElementById('arc_div_circle_' + this.device.id + "_" + this.device.name)) {
          document.getElementById('arc_div_circle_' + this.device.id + "_" + this.device.name)?.setAttribute("cx", (x).toString());
          document.getElementById('arc_div_circle_' + this.device.id + "_" + this.device.name)?.setAttribute("cy", (-y).toString());
        }
        FloorUtils.rotateDomChanges(_.cloneDeep(this.device), start, end, radius, scale, false)
        FloorUtils.leftSideDomChanges(_.cloneDeep(this.device), radius, false);
        FloorUtils.rightSideDomChanges(_.cloneDeep(this.device), radius, false);
      }

    }
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
