/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'so-sync-popup',
  templateUrl: './sync-popup.component.html',
  styleUrls: ['./sync-popup.component.scss'],
})
export class SyncPopupComponent {
  deviceCnt: any;
  taskCnt: any;
  imageCnt: any;
  imgUploadedCnt: any;
  totalDiscardCount: any;
  endSyncCallStarted: any = false;
  closeEvent: any = new EventEmitter();
  constructor(public modalRef: BsModalRef) { }

  getSyncImageCal() {
    return (this.imgUploadedCnt / this.imageCnt) * 100;
  }

  closeModal() {
    this.closeEvent.emit('cancel')
  }
}
