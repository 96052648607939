import { Component, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'so-routing-confirmation',
  templateUrl: './routing-confirmation.component.html',
  styleUrls: ['./routing-confirmation.component.scss'],
})
export class RoutingConfirmationComponent {
  markUpChanged: any;
  discardCnt: any;
  totalDiscardCnt: any;
  public saveFloorPlan: EventEmitter<any> = new EventEmitter();
  constructor(public bsModelRef: BsModalRef<any>) {}
  saveChanges() {
    this.saveFloorPlan.emit(true)
  }
  closeModal() {
    this.saveFloorPlan.emit(false);
  }
}
