export const DeleteFavorites = {
    dialogId: 'Delete Favorites',
    header: 'Confirm Delete of All Favorites',
    content: 'This will delete all Favorites in <strong class="text-break">{{#customerName}}</strong>. <br/>An export file of all existing Favorites will be downloaded as part of this action.',
    btnSuccessText: 'Delete',
    btnCancelText: 'Cancel',
    btnSuccessClass: 'btn-danger',
    btnCancelClass: 'btn-secondary',
    btnSuccessStyle: '',
    bodyClass: '',
    bodyStyle: '',
    dialogClass: '',
    dialogStyle: '',
}