import { Route } from '@angular/router';
import { FloorDetailsComponent } from './floor-details/floor-details.component';
import { FloorComponent } from './floor/floor.component';
import { floorPlanResolver } from './resolver/floor-plan.resolver';
import { RedirectionComponent } from './component/redirection/redirection.component';
import { FloorDetailsGuard } from './guard/floor-details.guard';

export const modulesFloorPlanRoutes: Route[] = [
  {
    path: '', component: FloorComponent, children: [
      {
        path: 'project/:projectId/building/:buildingId/floor/:floorId/details', component: FloorDetailsComponent, resolve: {
          buildings: floorPlanResolver
        },canDeactivate: [FloorDetailsGuard]
      },
      {
        path: 'site/:siteId/building/:buildingId/floor/:floorId/details', component: FloorDetailsComponent, resolve: {
          buildings: floorPlanResolver
        },canDeactivate: [FloorDetailsGuard]
      },
      {
        path: 'site/:siteId/building/:buildingId/floor/:floorId/sharable', component: RedirectionComponent,
      },
      {
        path: 'project/:projectId/building/:buildingId/floor/:floorId/sharable', component: RedirectionComponent,
      },
    ]
  }
];
